import { useEffect, ReactElement } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Image } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import cns from 'classnames';
import apiBus from '@/serve/api';
import Winner from '@/components/Winner';
import Judge from '@/components/Judge';
import Swiper from '@/components/Swiper';
import News from '@/components/News/pc';
import { newsItemType } from '@/components/News/common/news';
import tagData from '../../common/summitDetail/summit-tag.json';
import pageData from '../../common/summitDetail/summit-detail.json';
import winnerData from '../../common/summitDetail/summit-winner.json';
import judgesData from '../../common/summitDetail/summit-judges.json';
import specimensData from '../../common/summitDetail/summit-specimens.json';
import structureData from '../../common/summitDetail/summit-structure.json';
import styles from './index.module.less';

function SummitDetailPC() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [newsList, setNewsList] = useSafeState<Array<newsItemType>>([]);
  const currentYear = Number(searchParams.get('year')) || new Date().getFullYear();
  const [video, setVideo] = useSafeState({
    show: false,
    url: '#',
  });
  const [specimensPreview, setSpecimensPreview] = useSafeState({
    visible: false,
    current: 0,
  });

  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = () => {
    apiBus
      .getNewsList({
        pageNo: 1,
        pageSize: 18,
        tag: `fh${currentYear}`,
      })
      .then((res: any) => {
        setNewsList(res);
      });
  };

  const handlePlayVideo = () => {
    setVideo({
      show: true,
      url: pageData[currentYear].pv.videoUrl,
    });
  };

  const handleHideVideo = () => {
    setVideo({
      show: false,
      url: '#',
    });
  };

  const handlePlayWinner = (winner) => {
    setVideo({
      show: true,
      url: winner.videoUrl,
    });
  };

  const handleClickNews = (id: number) => {
    navigate(`/news/detail?id=${id}&isNews=true`);
  };

  const winnerTemplate = (data, even) => {
    const judgeRenderWinner = (isImg) =>
      isImg ? (
        <div className={styles.winner__item__img}>
          {data.winner.map((item) => (
            <div key={item.name} className={styles.winner__item__ins}>
              <Winner winner={item} hasVideo onClick={handlePlayWinner} />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.winner__item__words}>
          <div className={styles.subtitle}>{data.title}</div>
          {data.words.map((paragraph, index) => (
            <div key={index} className={styles.winner__item__words__para}>
              {paragraph.map((word) => (
                <div>{word}</div>
              ))}
            </div>
          ))}
        </div>
      );

    return (
      <div className={cns(styles.winner__item, { [styles['winner__item--even']]: even })}>
        <div
          className={cns(styles.winner__item__container, {
            [styles['winner__item__container--even']]: even,
          })}
        >
          <div className={styles.winner__item__left}>{judgeRenderWinner(even)}</div>
          {judgeRenderWinner(!even)}
        </div>
      </div>
    );
  };

  const renderPV = () => (
    <div className={styles.pv}>
      <img src={pageData[currentYear].pv.imgUrl} alt="" className={styles.pv__img} />
      <img
        src={require('@/assets/images/play.png').default}
        alt="start-icon"
        className={styles.pv__icon}
        onClick={handlePlayVideo}
      />
      {pageData[currentYear].pv.detail && (
        <>
          <div className={styles.pv__detail}>{pageData[currentYear].pv.detail}</div>
        </>
      )}
    </div>
  );

  const renderTag = () => (
    <div className={styles.tag}>
      {tagData[currentYear].map((item) => (
        <div key={item.label} className={styles.tag__card}>
          <div>
            <div className={styles.tag__label}>{item.label}</div>
            <div>{item.tips.join('、')}</div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderCup = () => (
    <div className={styles.cup}>
      <img src={pageData[currentYear].cup.bg} alt="" className={styles.cup__bg} />
      <div className={styles.cup__container}>
        <div className={styles.cup__info}>
          <div className={styles.subtitle}>{pageData[currentYear].cup.label}</div>
          <div className={styles.cup__info__text}>{pageData[currentYear].cup.content}</div>
        </div>
      </div>
    </div>
  );

  const renderTheme = () => (
    <div className={styles.theme}>
      <img
        src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/2022/theme-up.png"
        alt=""
        className={styles.theme__up}
      />
      <div className={styles.theme__cover}>
        <img
          src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/2022/theme.png"
          alt=""
          className={styles.theme__ins}
        />
        <img
          src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/2022/lb-border.png"
          alt=""
          className={styles.theme__border__lb}
        />
        <img
          src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/2022/rt-border.png"
          alt=""
          className={styles.theme__border__rt}
        />
      </div>
      <img
        src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/2022/theme-down.png"
        alt=""
        className={styles.theme__down}
      />
      <div className={styles.theme__content}>{pageData[currentYear].theme}</div>
    </div>
  );

  const renderMoment = () => (
    <div className={styles.moment}>
      <div className={styles.subtitle}>{pageData[currentYear].moment.label}</div>
      <div className={styles.moment__info}>{pageData[currentYear].moment.content}</div>
    </div>
  );

  const renderWinner = () => (
    <div className={styles.winner}>
      {winnerData[currentYear].map((item, index) => winnerTemplate(item, index % 2 === 0))}
    </div>
  );

  const renderJudges = () => (
    <div className={styles.judges}>
      <div className={styles.judges__container}>
        <div className={styles.subtitle}>评委会成员</div>
        <div className={styles.judges__subtitle}>评委会主席</div>
        <Judge judge={judgesData[currentYear].president} position="horizontal" />
        <div className={styles.judges__subtitle}>评委会成员</div>
        <div className={styles.judges__list}>
          {judgesData[currentYear].member.map((member) => (
            <div key={member.name} className={styles.judges__list__item}>
              <Judge judge={member} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderSpecimens = () => (
    <div className={styles.specimens}>
      <div className={styles.subtitle}>图片集锦</div>
      <Swiper
        spaceBetween={12}
        slidesPerView={3}
        slidesPerGroup={1}
        arrowColor="yellow"
        isLoop
        hasNavigation
        className={styles.specimens__swiper}
        insClassName={styles.specimens__swiper__ins}
      >
        {specimensData[currentYear].map((imgUrl, index) => (
          <Image
            key={index}
            src={imgUrl}
            preview={{ visible: false, mask: false }}
            className={styles.specimens__img}
            onClick={() =>
              setSpecimensPreview((prev) => ({ ...prev, visible: true, current: index }))
            }
          />
        ))}
      </Swiper>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible: specimensPreview.visible,
            current: specimensPreview.current,
            onVisibleChange: (vis) => setSpecimensPreview((prev) => ({ ...prev, visible: vis })),
          }}
        >
          {specimensData[currentYear].map((imgUrl, index) => (
            <Image key={index} src={imgUrl} />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );

  const renderNewsData = (newsData) => {
    const res: Array<Array<ReactElement>> = [];
    for (let i = 0; i < Math.ceil(newsData.length / 6); i++) {
      res.push([]);
      for (let j = 0; j < 6; j++) {
        const index = i * 6 + j;
        if (index > newsData.length - 1) {
          break;
        }
        res[i].push(
          <News
            newsData={newsData[i * 6 + j]}
            className={styles.news__item}
            onClick={(id) => handleClickNews(id)}
          />,
        );
      }
    }

    return res.map((item, index) => (
      <div key={index} className={styles.news__page}>
        {item}
      </div>
    ));
  };

  const renderNews = () => (
    <div className={styles.news}>
      <div className={styles.subtitle}>峰会新闻</div>
      <Swiper
        spaceBetween={40}
        slidesPerView={1}
        slidesPerGroup={1}
        arrowColor="yellow"
        isLoop
        hasNavigation
        className={styles.news__swiper}
        insClassName={styles.news__swiper__ins}
      >
        {renderNewsData(newsList)}
      </Swiper>
    </div>
  );

  const renderStructure = () => (
    <div className={styles.structure}>
      <div className={styles.subtitle}>组织架构</div>
      <div className={styles.structure__box}>
        <div className={styles.structure__content}>
          {structureData[currentYear].map((item, index) => (
            <>
              <div className={styles.structure__item}>
                <div>{item.label}</div>
                <div className={styles.structure__item__list}>
                  {item.value.map((subItem) => (
                    <div key={subItem} className={styles.structure__item__organ}>
                      {subItem}
                    </div>
                  ))}
                </div>
              </div>
              {index !== structureData[currentYear].length - 1 && (
                <div className={styles.structure__item__split} />
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );

  const renderCooperator = () => (
    <div className={styles.cooperator}>
      <div className={styles.subtitle}>{pageData[currentYear].cooperator.label}</div>
      <div className={styles.cooperator__img}>
        <img src={pageData[currentYear].cooperator.imgUrl} alt="" />
      </div>
    </div>
  );

  const renderVideo = () => (
    <div className={styles.video} style={video.show ? {} : { zIndex: -9999, opacity: 0 }}>
      <div className={styles.video__container}>
        <video src={video.url} controls autoPlay preload="auto" className={styles.video__ins} />
        <CloseCircleOutlined className={styles.video__cancel} onClick={handleHideVideo} />
      </div>
    </div>
  );

  return (
    <section className={`${styles.summit} ${styles[`year-${currentYear}`]}`}>
      {renderPV()}
      {currentYear === 2021 && renderTag()}
      {currentYear === 2021 && renderCup()}
      {currentYear === 2022 && renderTheme()}
      {renderMoment()}
      {renderWinner()}
      {(currentYear === 2022 || currentYear === 2021) && renderJudges()}
      {renderSpecimens()}
      {newsList.length > 0 && renderNews()}
      {renderStructure()}
      {(currentYear === 2022 || currentYear === 2021) && renderCooperator()}
      {renderVideo()}
    </section>
  );
}

export default SummitDetailPC;
