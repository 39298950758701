export const contactData = [
  {
    label: 'E-mail',
    value: 'digitaleconomy@163.com',
  },
  {
    label: 'Tel',
    value: '+86 0571 8853 5382',
  },
  {
    label: 'Mobile',
    value: '+86 152 6850 6742',
  },
  {
    label: 'Address',
    value: '浙江省杭州市文一西路998号海创园18号楼702A',
  },
];

export const location = [
  {
    name: '',
    address: '',
    latlng: {
      lat: 30.274232,
      lng: 119.979537,
    },
    imgList:
      'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/about/contact/weilaikeji.png',
  },
];
