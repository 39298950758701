import { useSafeState } from 'ahooks';
import { Image } from 'antd';
import Swiper from '@/components/Swiper';
import pageData from '../common/data.json';
import styles from './index.module.less';

function CityPC() {
  const [parkVisible, setParkVisible] = useSafeState({
    env: false,
    activity: false,
    current: 0,
  });

  const renderProfile = () => (
    <div className={styles.profile}>
      <div className={styles.profile__info}>
        <div className={styles.profile__title}>{pageData.profile.label}</div>
        <div className={styles.profile__text}>{pageData.profile.content}</div>
      </div>
      <img src={pageData.profile.bgUrl} alt="" className={styles.profile__bg} />
    </div>
  );

  const renderBrief = () => (
    <div className={styles.brief}>
      <div className={styles.brief__logo}>
        {pageData.brief.logo.map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" className={styles.brief__logo__item} />
        ))}
      </div>
      <div className={styles.brief__img}>
        {pageData.brief.imgList.map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" className={styles.brief__img__item} />
        ))}
      </div>
      <div className={styles.article}>{pageData.brief.content}</div>
    </div>
  );

  const renderProcess = () => (
    <div className={styles.process}>
      <div className={styles.subtitle}>{pageData.process.label}</div>
      <div className={styles.process__list}>
        {pageData.process.value.map((item) => (
          <div key={item.time} className={styles.process__item}>
            <div className={styles.process__item__label}>{item.time}</div>
            <div>{item.result}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderEnv = () => (
    <div className={styles.env}>
      <div className={styles.subtitle}>{pageData.env.label}</div>
      <Swiper
        spaceBetween={8}
        slidesPerView={2.2}
        slidesPerGroup={1}
        arrowColor="grey"
        isLoop
        hasNavigation
        hasArrowShadow
        className={styles.swiper}
        insClassName={styles.swiper__ins}
      >
        {pageData.env.imgList.map((imgUrl, index) => (
          <Image
            key={index}
            src={imgUrl}
            preview={{ visible: false, mask: false }}
            className={styles.swiper__img}
            onClick={() => setParkVisible((prev) => ({ ...prev, env: true, current: index }))}
          />
        ))}
      </Swiper>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible: parkVisible.env,
            current: parkVisible.current,
            onVisibleChange: (vis) => setParkVisible((prev) => ({ ...prev, env: vis })),
          }}
        >
          {pageData.env.imgList.map((imgUrl, index) => (
            <Image key={index} src={imgUrl} />
          ))}
        </Image.PreviewGroup>
      </div>
      <div className={styles.article}>{pageData.env.content}</div>
    </div>
  );

  const renderActivity = () => (
    <div className={styles.activity}>
      <div className={styles.subtitle}>{pageData.activity.label}</div>
      <Swiper
        spaceBetween={8}
        slidesPerView={2.2}
        slidesPerGroup={1}
        arrowColor="grey"
        isLoop
        hasNavigation
        hasArrowShadow
        className={styles.swiper}
        insClassName={styles.swiper__ins}
      >
        {pageData.activity.imgList.map((imgUrl, index) => (
          <Image
            key={index}
            src={imgUrl}
            preview={{ visible: false, mask: false }}
            className={styles.swiper__img}
            onClick={() => setParkVisible((prev) => ({ ...prev, activity: true, current: index }))}
          />
        ))}
      </Swiper>
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible: parkVisible.activity,
            current: parkVisible.current,
            onVisibleChange: (vis) => setParkVisible((prev) => ({ ...prev, activity: vis })),
          }}
        >
          {pageData.activity.imgList.map((imgUrl, index) => (
            <Image key={index} src={imgUrl} />
          ))}
        </Image.PreviewGroup>
      </div>
      <div className={styles.article}>{pageData.activity.content}</div>
    </div>
  );

  const renderPolicy = () => (
    <div className={styles.policy}>
      <div className={styles.subtitle}>{pageData.policy.label}</div>
      <div className={styles.policy__list}>
        {pageData.policy.value.map((item) => (
          <div key={item.label} className={styles.policy__item}>
            <div className={styles.policy__item__label}>{item.label}</div>
            <div>
              {Array.isArray(item.result)
                ? item.result.map((policy) => <div>{policy}</div>)
                : item.result}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <section className={styles.city}>
      {renderProfile()}
      <div className={styles.city__content}>
        {renderBrief()}
        {renderProcess()}
        {renderEnv()}
        {renderActivity()}
        {renderPolicy()}
      </div>
    </section>
  );
}

export default CityPC;
