export const institutionTitle = '浙江省数字经济学会制度';

export const institutionList = [
  {
    label: '浙江省数字经济学会会员代表大会制度',
    value: 'daibiao',
  },
  {
    label: '浙江省数字经济学会理事会制度',
    value: 'lishi',
  },
  {
    label: '浙江省数字经济学会监事制度',
    value: 'jianshi',
  },
  {
    label: '浙江省数字经济学会财务管理制度',
    value: 'caiwu',
  },
  {
    label: '浙江省数字经济学会人事管理制度',
    value: 'renshi',
  },
  {
    label: '浙江省数字经济学会会员管理制度',
    value: 'huiyuan',
  },
  {
    label: '浙江省数字经济学会分支机构管理办法',
    value: 'fenzhi',
  },
  {
    label: '浙江省数字经济学会证书管理制度',
    value: 'zhengshu',
  },
  {
    label: '浙江省数字经济学会印章管理制度',
    value: 'yinzhang',
  },
  {
    label: '浙江省数字经济学会信息公开制度',
    value: 'xinxi',
  },
  {
    label: '浙江省数字经济学会学术自律制度',
    value: 'xueshu',
  },
  {
    label: '浙江省数字经济学会档案管理制度',
    value: 'dangan',
  },
  {
    label: '浙江省数字经济学会档案名录编号规则',
    value: 'minglu',
  },
];
