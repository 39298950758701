import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useLocation } from 'react-router-dom';
import Tab from '@/components/Tab/pc';
import { tabList, profile } from '../common/work';
import GroupLogo from './components/GroupLogo';
import BlockChain from './components/BlockChain';
import styles from './index.module.less';

function WorkPC() {
  const navigate = useNavigate();
  const location = useLocation();
  const initCurrent = Number(location.hash.substring(1));
  const [currentTab, setCurrentTab] = useSafeState(initCurrent || 0);

  useEffect(() => {
    setCurrentTab(initCurrent);
  }, [initCurrent]);

  const handleTab = (current: number) => {
    setCurrentTab(current);
    navigate(`#${current}`);
  };

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={profile.bg} alt="" className={styles.profile__bg} />
      <div className={styles.profile__container}>
        <div className={styles.profile__title}>
          <div className={styles.profile__title__text}>{profile.label}</div>
          <div>{profile.subLabel}</div>
        </div>
        <div className={styles.profile__text}>{profile.content}</div>
      </div>
    </div>
  );

  return (
    <section className={styles.work}>
      {renderProfile()}
      <Tab
        tabList={tabList}
        current={currentTab}
        isScrollToTop
        onClick={handleTab}
        className={styles.work__tab}
      />
      {currentTab === 0 && <GroupLogo />}
      {currentTab === 1 && <BlockChain />}
    </section>
  );
}

export default WorkPC;
