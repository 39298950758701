import React, { useEffect } from 'react';
import News from '@/components/News/pc';
import Icon from '@/components/Icon';
import pageData from '../../../common/trip/data.json';
import { useTrip } from '../../../hooks/useTrip';
import styles from './index.module.less';

function TripPC() {
  const { pageSize, pagination, setPagination, newsList, setNewsList, getNewsList, handleNews } =
    useTrip('pc');

  useEffect(() => {
    setNewsList([]);
    getNewsList();
  }, []);

  const handleMore = () => {
    if (pageSize * (pagination.page + 1) < pagination.totalNumber) {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
      getNewsList();
    }
  };

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.bgUrl} alt="" className={styles.profile__bg} />
      <img src={pageData.profile.logoUrl} alt="logo" className={styles.profile__logo} />
      <div className={styles.profile__text}>{pageData.profile.content}</div>
    </div>
  );

  const renderOther = () => (
    <div className={styles.other}>
      <div className={styles.subtitle}>{pageData.other.label}</div>
      <div className={styles.other__img}>
        <div className={styles.other__img__line1}>
          {pageData.other.img_line1.map((imgUrl, index) => (
            <img key={index} src={imgUrl} alt="" className={styles.other__img__line1__item} />
          ))}
        </div>
        <div className={styles.other__img__line2}>
          <img
            src={pageData.other.img_line2.large}
            alt=""
            className={styles.other__img__line2__large}
          />
          {pageData.other.img_line2.small.map((imgUrl, index) => (
            <img key={index} src={imgUrl} alt="" className={styles.other__img__line2__small} />
          ))}
        </div>
      </div>
      <div className={styles.subtext}>{pageData.other.content[0]}</div>
      <div className={styles.other__img2}>
        {pageData.other.img_line3.map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" className={styles.other__img2__item} />
        ))}
      </div>
      <div className={styles.subtext}>{pageData.other.content[1]}</div>
    </div>
  );

  const renderLocal = () => (
    <div className={styles.local}>
      <div className={styles.subtitle}>{pageData.local.label}</div>
      <div className={styles.local__img}>
        {pageData.local.imgList.map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" className={styles.local__img__item} />
        ))}
      </div>
      <div className={styles.subtext}>{pageData.local.content}</div>
    </div>
  );

  const renderNews = () => (
    <div className={styles.news}>
      <div className={styles.subtitle}>相关新闻</div>
      <div className={styles.news__list}>
        {newsList.map((item) => (
          <News
            key={item.id}
            newsData={item}
            onClick={handleNews}
            className={styles.news__list__item}
          />
        ))}
      </div>
      {pageSize * (pagination.page + 1) < pagination.totalNumber && (
        <div className={styles.news__more} onClick={handleMore}>
          加载更多
          <Icon name="arrow-down" size={20} color="rgba(207, 156, 110, 1)" />
        </div>
      )}
    </div>
  );

  return (
    <section className={styles.trip}>
      <div className={styles.trip__content}>
        {renderProfile()}
        {renderOther()}
        {renderLocal()}
        {newsList.length > 0 && renderNews()}
      </div>
    </section>
  );
}

export default TripPC;
