import { Fragment, useEffect } from 'react';
import { useSafeState } from 'ahooks';
import cns from 'classnames';
import { HeaderType } from '../../../common/header';
import styles from './index.module.less';

type HeaderPropsType = {
  menu: HeaderType;
  selectedKeys: Array<string>;
  className?: string;
  onClick?: Function;
};

const Menu = (props: HeaderPropsType) => {
  const { selectedKeys, className, onClick } = props;
  const [menu, setMenu] = useSafeState(formatMenu(props.menu));

  useEffect(() => {
    setMenu(formatMenu(props.menu));
  }, [props.menu]);

  function formatMenu(menu) {
    const newMenu = JSON.parse(JSON.stringify(menu));

    return newMenu.map((menu) => {
      if (menu.children) {
        menu.open = false;
      }

      return menu;
    });
  }

  const handleClick = (type: 0 | 1, item, subItem) => {
    // type 0: 点击第一层menu， 1： 点击第二层menu

    if (type === 0) {
      if (!item.children) {
        // 如果当前menu为active，则不处理
        // if (selectedKeys.includes(item.key)) {
        //   return;
        // }

        onClick &&
          onClick({
            key: item.key,
            keyPath: [item.key],
          });
      } else {
        setMenu((prev) =>
          prev.map((menu) =>
            menu.key === item.key ? { ...menu, open: !menu.open } : { ...menu, open: false },
          ),
        );
      }
    } else if (type === 1) {
      // 如果当前menu为active，则不处理
      // if (selectedKeys.includes(subItem.key)) {
      //   return;
      // }

      onClick &&
        onClick({
          key: subItem.key,
          keyPath: [subItem.key, item.key],
        });
    }
  };

  return (
    <Fragment>
      <div className={cns(styles.menu, className)}>
        {menu.map((item) => (
          <div
            key={item.key}
            className={cns(
              styles.menu__item,
              // {
              // [styles['menu__item--active']]: selectedKeys.includes(item.key) ? true : false,
              // }
            )}
            onClick={() => handleClick(0, item, null)}
          >
            <span className={styles.menu__item__content}>{item.label}</span>
            {item.children && item.children.length > 0 ? (
              <div
                className={cns(styles.menu__subItem, {
                  [styles['menu__subItem--hidden']]: !item.open,
                })}
              >
                {item.children?.map((subItem) => (
                  <div
                    key={subItem.key}
                    className={cns(
                      styles.menu__subItem__ins,
                      // {
                      //   [styles['menu__subItem__ins--active']]: selectedKeys.includes(subItem.key)
                      //     ? true
                      //     : false,
                      // }
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClick(1, item, subItem);
                    }}
                  >
                    {subItem.label}
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default Menu;
