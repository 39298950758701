import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import cns from 'classnames';
import apiBus from '@/serve/api';
import { throttle } from '@/libs';
import { getDeviceRatio } from '@/libs/utils/ratio';
import Icon from '@/components/Icon';
import InterviewCardMD from '@/components/Interview/mobile/card';
import { InterviewRegisterUrl } from '@/constants';
import { getRemainTop } from '@/libs/utils/getScroll';
import { InterviewType } from '../../../common/interview/interview';
import pageData from '../../../common/merchant/data.json';
import styles from './index.module.less';

function MerchantMD() {
  const navigate = useNavigate();
  const interviewsCache = useSelector((state: any) => state.interview.list);
  const [showBtn, setShowBtn] = useSafeState(true);
  const [interviewData, setInterviewData] = useSafeState<Array<InterviewType>>(
    interviewsCache.slice(0, 3),
  );
  const bookData = [
    {
      label: '作序',
      value: [
        {
          name: '陈浩',
          rank: ['中共湖州市委书记', '时任中共浙江省委统战部副部长、省工商联党组书记'],
        },
      ],
    },
    {
      label: '倾力推荐',
      value: [
        {
          name: '吴晓波',
          rank: ['著名财经作家'],
        },
        {
          name: '胡宏伟',
          rank: ['浙商研究会执行会长', '澎湃新闻副总编辑'],
        },
        {
          name: '陈   刚',
          rank: ['浙江大学计算机学院院长'],
        },
        {
          name: '张建锋',
          rank: ['阿里云智能总裁', '达摩院院长'],
        },
      ],
    },
  ];

  useEffect(() => {
    const btnHideHeight = getDeviceRatio() * 10.72;
    const handleScroll = throttle(() => {
      if (getRemainTop() <= btnHideHeight) {
        setShowBtn(false);
      } else {
        setShowBtn(true);
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  useEffect(() => {
    getInterviewData();
  }, []);

  const getInterviewData = () => {
    if (!interviewsCache?.length) {
      apiBus
        .getInterviewList({
          page: 1,
          limit: 3,
        })
        .then((res: any) => {
          setInterviewData(res);
        });
    }
  };

  const handleInterview = (id) => {
    navigate(`/phone/project/digit/interview?id=${id}`);
  };

  const handleMore = () => {
    navigate('list');
  };

  const handleApply = () => {
    window.open(InterviewRegisterUrl);
  };

  const renderBook = () => (
    <>
      <img src={pageData.bookUrlMD} alt="" className={styles.book__img} />
      {bookData.map((item, index) => (
        <>
          <div className={styles.book__label}>{item.label}</div>
          <div className={styles.book__person}>
            {item.value.map((person) => (
              <div
                style={{ display: index === 0 ? 'block' : 'flex' }}
                className={styles.book__person__item}
              >
                <div className={styles.book__person__name}>{person.name}</div>
                <div>
                  {person.rank.map((rank) => (
                    <div>{rank}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      ))}
    </>
  );

  const renderPrevInterview = () => (
    <div className={styles.interview}>
      <div className={styles.interview__title}>往期访谈</div>
      <div className={styles.interview__list}>
        {interviewData.map((item) => (
          <InterviewCardMD
            key={item.id}
            interviewData={item}
            onClick={handleInterview}
            className={styles.interview__list__item}
          />
        ))}
      </div>
      <div className={styles.interview__more} onClick={handleMore}>
        查看更多 <Icon name="arrow-right" size={28} color="rgba(151, 151, 151, 1)" />
      </div>
    </div>
  );

  return (
    <section className={styles.merchant}>
      <div className={styles.merchant__article}>
        <img src={pageData.logo} alt="" className={styles.merchant__article__logo} />
        <div className={styles.merchant__article__content}>{pageData.content[0]}</div>
        <img src={pageData.scene} alt="" className={styles.merchant__article__scene} />
        <div className={styles.merchant__article__content}>{pageData.content[1]}</div>
        <img src={pageData.splitIcon} alt="" className={styles.merchant__article__split} />
        {renderBook()}
      </div>
      {renderPrevInterview()}
      <div
        className={cns(
          styles.merchant__button,
          styles[`merchant__button--${showBtn ? 'fixed' : 'absolute'}`],
        )}
      >
        <Button className={styles.merchant__button__ins} onClick={handleApply}>
          专访报名
        </Button>
      </div>
    </section>
  );
}

export default MerchantMD;
