import { useSafeState } from 'ahooks';
import pageData from '../common/industry/data.json';

export function useIndustry() {
  const [modal, setModal] = useSafeState({
    isVisible: false,
    title: '请选择申请人类型',
    confirm: '确定',
    hasCancelIcon: true,
    onCancel: () => setModal((prev) => ({ ...prev, isVisible: false })),
  });
  const [applyType, setApplyType] = useSafeState('company');

  const handleChangeApplyType = (type) => {
    setApplyType(type);
  };

  const handleConfirm = () => {
    const applyInfo = pageData.apply.find((item) => item.value === applyType);

    if (applyInfo?.url) {
      window.open(applyInfo.url);
    }
  };

  return {
    modal,
    setModal,
    applyType,
    handleChangeApplyType,
    handleConfirm,
  };
}
