import pageData from '../../../common/organization/data.json';
import styles from './index.module.less';

function DetailOrganizationMD() {
  const renderPresident = (item) => (
    <div key={item.label} className={styles.president}>
      <div className={styles.title}>{item.label}</div>
      {item.value.map((president) => (
        <div key={president.name} className={styles.president__item}>
          <div className={styles.president__item__info}>
            <img src={president.avatarUrl} alt="" className={styles.president__item__avatar} />
            <div>
              <div className={styles.president__item__name}>{president.name}</div>
              <div className={styles.president__item__rank}>{president.rank}</div>
            </div>
          </div>
          <div className={styles.president__item__profile}>{president.profile}</div>
        </div>
      ))}
    </div>
  );

  const renderImg = (item) => (
    <div key={item.label} className={styles.portion}>
      <div className={styles.title}>{item.label}</div>
      <img src={item.imgUrlMD} alt="" />
    </div>
  );

  return (
    <section className={styles.organization}>
      {pageData.map((item) => (item.type === 'img' ? renderImg(item) : renderPresident(item)))}
    </section>
  );
}

export default DetailOrganizationMD;
