// 设备宽度（判断pc端/h5端）
export const DEVICE_WIDTH = 750;

// 腾讯地图key
export const txMapKey = 'PNEBZ-F55KQ-FXJ5Q-GKILO-JDON7-VYBOH';

// 数字新浙商专访报名链接
export const InterviewRegisterUrl = 'https://jinshuju.net/f/Ddju07';

// 团标平台报名表下载地址
export const GroupLogoDownLoadUrl =
  'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/work/%E5%9B%A2%E4%BD%93%E6%A0%87%E5%87%86%E7%AB%8B%E9%A1%B9%E7%94%B3%E8%AF%B7%E8%A1%A8.docx';

// 团标信息地址
export const GroupLogoDetailUrl = 'http://www.ttbz.org.cn/StandardManage/Detail';
