import { Fragment, useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useLocation } from 'react-router-dom';
import cns from 'classnames';
import { debounce } from '@/libs';
import { HeaderType } from '../common/header';
import Menu from './components/Menu';
import styles from './index.module.less';

type HeaderPropsType = {
  menuData: HeaderType;
  logoUrl: string;
};

const HeaderMD = (props: HeaderPropsType) => {
  const { menuData, logoUrl } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const menu = JSON.parse(JSON.stringify(menuData));
  const paths = location.pathname.replace('/phone', '').split('/');
  const [showMenu, setShowMenu] = useSafeState(false);
  const [selectedKeys, setSelectedKeys] = useSafeState<Array<string>>(paths);

  useEffect(() => {
    const paths = location.pathname.replace('/phone', '').split('/');
    paths.push(location.hash);
    setSelectedKeys(paths);
  }, [location.pathname, location.hash, menuData]);

  const handleClickMenu = (e: { key: string; keyPath: Array<string> }) => {
    setSelectedKeys(e.keyPath);
    navigate(`/phone/${e.keyPath.reverse().join('/')}`);
    setShowMenu(false);
    window.scrollTo(0, 0);
  };

  const handleShowMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const handleTop = debounce(
    () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    1500,
    true,
  );

  return (
    <Fragment>
      <div className={styles.header} onDoubleClick={handleTop}>
        <img
          src={logoUrl}
          alt="logo"
          className={styles.header__logo}
          style={{ opacity: showMenu ? 0 : 1 }}
          onDoubleClick={(e) => e.stopPropagation()}
        />
        <div
          className={styles.header__icon}
          onClick={handleShowMenu}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          <div
            className={cns(styles.header__icon__line, {
              [styles['header__icon__line--cancel']]: showMenu,
            })}
          />
          <div
            className={cns(styles.header__icon__line, {
              [styles['header__icon__line--cancel']]: showMenu,
            })}
          />
          <div
            className={cns(styles.header__icon__line, {
              [styles['header__icon__line--cancel']]: showMenu,
            })}
          />
        </div>
      </div>
      <div className={styles.header__instance} />
      <div
        className={cns(styles.header__menu, {
          [styles['header__menu--show']]: showMenu,
        })}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <Menu
          menu={menu}
          selectedKeys={selectedKeys}
          className={styles.header__menu__ins}
          onClick={handleClickMenu}
        />
        <img
          src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/home/logo-reverse.png"
          alt="logo"
          className={styles.header__menu__logo}
        />
      </div>
    </Fragment>
  );
};

export default HeaderMD;
