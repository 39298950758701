import { useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';

interface PreviewConfigType {
  isVisible: boolean;
  current: number;
}

export function useSummit(type) {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useSafeState(0);
  const [previewConfig, setPreviewConfig] = useSafeState<PreviewConfigType>({
    isVisible: false,
    current: 0,
  });

  const handleTab = (current) => {
    setCurrentTab(current);
  };

  const handleRegister = (registerUrl: string) => {
    window.open(registerUrl);
  };

  const handleClickBack = (year) => {
    navigate(`${type === 'pc' ? '/project/' : ''}summit?year=${year}`);
  };

  const handlePreview = (current) => {
    setPreviewConfig((prev) => ({
      ...prev,
      isVisible: true,
      current,
    }));
  };

  const handleHidePreview = () => {
    setPreviewConfig((prev) => ({ ...prev, isVisible: false }));
  };

  return {
    currentTab,
    previewConfig,
    handleTab,
    handleRegister,
    handleClickBack,
    handlePreview,
    handleHidePreview,
  };
}
