import React, { useMemo } from 'react';
import cns from 'classnames';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import Modal from '@/components/Modal';
import pageData from '../../../common/groupLogo/data.json';
import { useGroupLogo } from '../../../hooks/useGroupLogo';
import styles from './index.module.less';
import { Button } from 'antd';

function GroupLogoPC() {
  const {
    showLogo,
    coverRef,
    modalData,
    xhEmail,
    setModalData,
    handleDownload,
    handleGroupLogo,
    handleCopyEmail,
  } = useGroupLogo('pc');

  const handleUpload = () => {
    setModalData((prev) => ({
      ...prev,
      isVisible: true,
      children: modalContent,
    }));
  };

  const modalContent = useMemo(
    () => (
      <div className={styles['modal-content']}>
        请将Word版报名表与PDF版盖章扫描报名表，一起发送至
        <span onClick={handleCopyEmail}>{xhEmail}</span>
        学会将在 7 个工作日内回复。
      </div>
    ),
    [],
  );

  const renderProfile = () => (
    <div className={styles.profile}>
      <div className={styles.profile__introduce}>
        <img
          src={pageData.profile.logoUrl}
          alt="logo"
          className={styles.profile__introduce__logo}
        />
        <div className={styles.profile__introduce__text}>{pageData.profile.content[0]}</div>
      </div>
      <div className={styles.profile__info}>{pageData.profile.content[1]}</div>
    </div>
  );

  const renderPublish = () => (
    <div className={styles.publish}>
      <div className={styles.publish__title}>{pageData.publish.title}</div>
      <div ref={coverRef} className={styles.publish__cover}>
        {pageData.publish.coverList.map((imgUrl: string) => (
          <div
            className={cns(styles.publish__cover__item, {
              [styles['publish__cover__item--anim']]: showLogo,
            })}
          >
            <img src={imgUrl} alt="" className={styles.publish__cover__img} />
            <img src={imgUrl} alt="" className={styles.publish__cover__reflect} />
          </div>
        ))}
      </div>
      <div className={styles.publish__list}>
        {pageData.publish.value.map((item) => (
          <div
            key={item.number}
            className={styles.publish__list__item}
            onClick={() => handleGroupLogo(item.id)}
          >
            {item.name} {item.number}
          </div>
        ))}
      </div>
    </div>
  );

  const renderProcess = () => (
    <div className={styles.process}>
      <div className={styles.process__title}>{pageData.process.label}</div>
      <img src={pageData.process.imgUrl} alt="" className={styles.process__img} />
      <div className={styles.process__button}>
        <Button type="primary" className={styles.process__button__item} onClick={handleDownload}>
          下载报名表
          <DownloadOutlined />
        </Button>
        <Button type="primary" className={styles.process__button__item} onClick={handleUpload}>
          上传报名表
        </Button>
      </div>
      <div className={styles.process__tips}>{pageData.process.tips}</div>
    </div>
  );

  return (
    <section className={styles.group}>
      <div className={styles.group__container}>
        {renderProfile()}
        {renderPublish()}
        {renderProcess()}
      </div>
      <Modal {...modalData} />
    </section>
  );
}

export default GroupLogoPC;
