import { CSSTransition } from 'react-transition-group';
import cns from 'classnames';
import { CommitteeType } from '../common/committee';
import './profile.less';
import styles from './index.module.less';

type CommitteePropsType = {
  committeeData: CommitteeType;
  isCollapse: boolean;
  onClick?: Function;
  className?: string;
};

const CommitteeMD = (props: CommitteePropsType) => {
  const { committeeData, isCollapse, onClick, className } = props;

  const handleClick = () => {
    onClick && onClick(committeeData.id);
  };

  const renderIcon = () => (
    <div className={styles.committee__icon} onClick={handleClick}>
      <img
        src={require('@/assets/images/grey-right-arrow.png').default}
        alt=""
        className={cns(
          styles.committee__icon__img,
          styles[`committee__icon__img--${isCollapse ? 'down' : 'up'}`],
        )}
      />
    </div>
  );

  return (
    <div className={cns(styles.committee, className)}>
      <div className={styles.committee__department}>
        <img src={committeeData.icon} alt="" className={styles.committee__department__icon} />
        <div className={styles.committee__department__info}>
          <div>{committeeData.committee}</div>
          <div>{committeeData.committeeEng}</div>
        </div>
      </div>
      <div className={styles.committee__director}>
        <img src={committeeData.avatarUrl} alt="" className={styles.committee__director__avatar} />
        <div>
          <div className={styles.committee__director__name}>
            <span>{committeeData.rank}</span>
            <span>{committeeData.name}</span>
          </div>
          <div className={styles.committee__director__appellation}>
            {committeeData.appellation.map((appellation, index) => (
              <div key={index}>{appellation}</div>
            ))}
          </div>
        </div>
      </div>
      <CSSTransition
        in={!isCollapse}
        timeout={300}
        className="committee-profile"
        classNames="committee-profile"
      >
        <div>{committeeData.profile}</div>
      </CSSTransition>
      {renderIcon()}
    </div>
  );
};

CommitteeMD.defaultProps = {
  committeeData: {},
  isCollapse: false,
  onClick: undefined,
};

export default CommitteeMD;
