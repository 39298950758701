import { useEffect, createRef, ReactElement } from 'react';
import { contactData, location } from '../../../common/contact/data';
import styles from './index.module.less';

function ContactPC() {
  const refList: Array<any> = [createRef(), createRef()];
  const { maps: TMap } = (window as any).qq;

  useEffect(() => {
    location.map((item, index) => initMap(item.latlng, refList[index].current));
  }, []);

  //地图初始化函数，本例取名为init，开发者可根据实际情况定义
  const initMap = (latlng: { lat: number; lng: number }, target: ReactElement) => {
    //定义地图中心点坐标
    var center = new TMap.LatLng(latlng.lat, latlng.lng);

    //定义map变量，调用 TMap.Map() 构造函数创建地图
    var map = new TMap.Map(target, {
      center: center, //设置地图中心点坐标
      zoom: 17, //设置地图缩放级别
      scrollable: true,
      doubleClickZoom: true,
      disableDefaultUI: true,
    });

    (window as any).qq?.maps?.event.addListener(map, 'tilesloaded', () => {
      const mapDom: any = window.document.getElementsByClassName(styles.map__ins);
      setTimeout(() => {
        [].forEach.call(mapDom, (item: any) => {
          item.children?.[0]?.children?.[1]?.setAttribute('style', 'display: none');
          item.children?.[0]?.children?.[2]?.setAttribute('style', 'display: none');
        });
      }, 100);
    });

    //创建并初始化MultiMarker
    const marker = new TMap.Marker({
      map: map, //指定地图容器
      position: center,
      icon: new TMap.MarkerImage(
        require('@/assets/images/contact/map-marker.png').default,
        new TMap.Size(21, 29),
        new TMap.Point(0, 0),
        new TMap.Point(10.5, 29),
        new TMap.Size(21, 29),
      ),
    });
  };

  const renderMap = () => (
    <div className={styles.map}>
      <div ref={refList[0]} className={styles.map__ins} />
      <div ref={refList[1]} className={styles.map__ins} />
    </div>
  );

  const renderInfo = () => (
    <div className={styles.info}>
      <div className={styles.info__contact}>
        {contactData.map((item) => (
          <div key={item.label} className={styles.info__contact__line}>
            <span>{item.label}：</span>
            <span>{item.value}</span>
          </div>
        ))}
      </div>
      <div className={styles.info__address}>
        {location.map((item, index) => (
          <div key={index} className={styles.info__address__line} />
        ))}
      </div>
    </div>
  );

  return (
    <section className={styles.contact}>
      <div className={styles.contact__container}>
        {renderMap()}
        {renderInfo()}
      </div>
    </section>
  );
}

export default ContactPC;
