import { useEffect, createRef } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { throttle } from '@/libs';
import { ModalPropsType } from '@/components/Modal';
import { GroupLogoDownLoadUrl, GroupLogoDetailUrl } from '@/constants';

export function useGroupLogo(type) {
  const navigate = useNavigate();
  const [showLogo, setShowLogo] = useSafeState(false);
  const [modalData, setModalData] = useSafeState<ModalPropsType>({
    title: '提交报名表',
    confirm: '确定',
    onConfirm: () => setModalData((prev) => ({ ...prev, isVisible: false })),
    isVisible: false,
  });
  const xhEmail = 'digitaleconomy@163.com';
  const coverRef: any = createRef();

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (coverRef.current?.getClientRects()[0]?.top < window.document.body.clientHeight / 2) {
        setShowLogo(true);
        window.removeEventListener('scroll', handleScroll, true);
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const handleDownload = () => {
    const url = GroupLogoDownLoadUrl;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.setAttribute('target', '_self');
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleGroupLogo = (id) => {
    window.open(`${GroupLogoDetailUrl}/${id}`);
  };

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(xhEmail).then(() => {
      message.success('复制成功!');
    });
  };

  return {
    showLogo,
    coverRef,
    modalData,
    xhEmail,
    setModalData,
    handleDownload,
    handleGroupLogo,
    handleCopyEmail,
  };
}
