import { useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { validatePhone, validateIdNumber } from '@/libs/utils/validate';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import apiBus from '@/serve/api';
import { initForm } from '../common/application/form';
import { bankData } from '../common/application/banks';

export function useApplicationForm() {
  const navigate = useNavigate();
  const [step, setStep] = useSafeState(0);
  const [form, setForm] = useSafeState(initForm);
  const [modal, setModal] = useSafeState({
    isVisible: false,
  });
  const [selectBankIndex, setSelectBankIndex] = useSafeState<number>(-1);
  const [loading, setLoading] = useSafeState(false);
  const [formRef] = Form.useForm();

  const validateForm = () => {
    return new Promise((resolve, reject) => {
      formRef
        .validateFields()
        .then((res) => {
          setForm((prev) => ({ ...prev, ...res }));
          if (step < 2) {
            setStep((step) => step + 1);
          }
          resolve('');
        })
        .catch(() => {
          reject();
        });
    });
  };

  const validateFormPhone = (rule, value) => {
    return new Promise((resolve, reject) => {
      if (validatePhone(value)) {
        resolve(true);
      } else {
        reject('请输入正确的手机号');
      }
    });
  };

  const validateFormIdNumber = (rule, value) => {
    return new Promise((resolve, reject) => {
      if (validateIdNumber(value)) {
        resolve(true);
      } else {
        reject('请输入正确的身份证');
      }
    });
  };

  const validateCard = (rule, value) => {
    return new Promise((resolve, reject) => {
      const flag = bankData[selectBankIndex]?.patterns.some((pattern) =>
        Boolean(value.match(pattern.reg)),
      );
      if (flag) {
        resolve(true);
      } else {
        reject('请输入正确的银行卡号');
      }
    });
  };

  const getRules = (item) => {
    switch (item.prop) {
      case 'phone':
        return {
          required: true,
          validateTrigger: 'onBlur',
          validator: validateFormPhone,
        };
      case 'idNumber':
        return {
          required: true,
          validateTrigger: 'onBlur',
          validator: validateFormIdNumber,
        };
      case 'card':
        return {
          required: true,
          validateTrigger: 'onBlur',
          validator: validateCard,
        };
      default:
        return {
          required: true,
          message: `请${item.type === 'select' ? '选择' : '输入'}${item.label}`,
        };
    }
  };

  const handleSelectChange = (value) => {
    setSelectBankIndex(bankData.findIndex((item) => item.bankName === value));
  };

  const handleNext = () => {
    validateForm();
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep((step) => step - 1);
    }
  };

  const handleSubmit = () => {
    validateForm().then(() => {
      setLoading(true);
      _submit();
    });
  };

  const _submit = useSyncCallback(() => {
    apiBus
      .addLecturer({
        bankCardNumber: form.card,
        briefIntroduction: form.introduce,
        courseContent: form.courseProfile,
        courseName: form.course,
        field: form.area,
        fullName: form.name,
        idNumber: form.idNumber,
        openingBank: form.bank,
        phone: form.phone,
      })
      .then(() => {
        setModal((prev) => ({
          ...prev,
          isVisible: true,
          type: 'success',
          content: '申请资料提提交成功',
          children: '学会秘书处将于5个工作日内电话回复',
          confirm: '确定',
          onConfirm: () => {
            setModal((prev) => ({ ...prev, isVisible: false }));
            navigate(-1);
          },
        }));
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const handleSearchBank = (input, option) => {
    return (option?.bankName ?? '').includes(input);
  };

  return {
    step,
    form,
    modal,
    formRef,
    loading,
    getRules,
    handleSelectChange,
    handleNext,
    handlePrev,
    handleSubmit,
    handleSearchBank,
  };
}
