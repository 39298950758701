import { useEffect, useRef } from 'react';
import { useSafeState, useUpdateEffect } from 'ahooks';
import cns from 'classnames';
import Swiper from 'swiper';
import 'swiper/swiper.less';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import styles from './index.module.less';

type PreviewGroupProps = {
  prefix: any;
  isVisible: boolean;
  isMobile: boolean;
  imgList: string[];
  current: number;
  className?: string;
  onClick: Function;
};

const PreviewGroupMD = (props: PreviewGroupProps) => {
  const { prefix, isVisible, isMobile, imgList, current, className, onClick } = props;
  const [delayVisible, setDelayVisible] = useSafeState(false);
  const [progress, setProgress] = useSafeState(current + 1);
  const isFirstChange = useRef(true);
  const swiper: any = useRef();
  const touchObj: any = useRef({
    moving: false,
    startX: 0,
    endX: 0,
  });

  useEffect(() => {
    swiper.current = new Swiper(`.${styles.swiper}-${prefix}`, {
      slidesPerView: 1,
      slidesPerGroup: 1,
      width: isMobile ? window.innerWidth : 1000,
      on: {
        snapIndexChange: () => {
          if (isFirstChange.current) {
            isFirstChange.current = false;
          } else {
            handleShow();
          }
        },
        slideChange: (swiper) => {
          setProgress(swiper.activeIndex + 1);
        },
      },
    });
  }, []);

  useUpdateEffect(() => {
    if (!isVisible) {
      setDelayVisible(false);
    }
  }, [isVisible]);

  useUpdateEffect(() => {
    if (isVisible) {
      if (swiper.current.activeIndex !== current) {
        swiper.current.slideTo?.(current, 0, true);
      } else {
        handleShow();
      }
    }
  }, [isVisible, current]);

  const handleShow = useSyncCallback(() => {
    setDelayVisible(true);
  });

  const handleClickMask = () => {
    onClick && onClick();
  };

  const handleTouchStart = (e) => {
    Object.assign(touchObj.current, { moving: false, startX: e.targetTouches[0]?.pageX });
  };

  const handleTouchMove = (e) => {
    Object.assign(touchObj.current, { moving: true, endX: e.targetTouches[0]?.pageX });
  };

  const handleTouchEnd = () => {
    const { moving, startX, endX } = touchObj.current;
    if (moving) {
      if (endX - startX > 50) {
        swiper.current?.slidePrev(300, true);
      } else if (endX - startX < -50) {
        swiper.current?.slideNext(300, true);
      }
    }
  };

  return (
    <div
      className={styles.mask}
      style={{
        display: isVisible && delayVisible ? 'block' : 'none',
      }}
      onClick={handleClickMask}
    >
      <div className={styles.progress}>
        {progress} / {imgList?.length}
      </div>
      <div
        className={styles.container}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className={cns('swiper', styles.swiper, `${styles.swiper}-${prefix}`)}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <div className="swiper-wrapper">
            {imgList?.map((imgUrl, index) => (
              <img
                key={index}
                src={imgUrl}
                alt=""
                className={cns(styles.swiper__img, `swiper-slide`)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PreviewGroupMD.defaultProps = {
  prefix: '',
  isVisible: false,
  isMobile: false,
  imgList: [],
  current: 0,
  className: '',
  onClick: undefined,
};

export default PreviewGroupMD;
