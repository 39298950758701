import React from 'react';
import { useNavigate } from 'react-router-dom';
import cns from 'classnames';
import pageData from '../../../common/talent/data.json';
import styles from './index.module.less';
import { Button } from 'antd';

function TalentPC() {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/project/application');
    // window.open('http://azalea-tech.mikecrm.com/S8juig5');
  };

  const renderProfile = () => (
    <div className={styles.profile}>
      <div className={styles.title}>{pageData.profile.label}</div>
      <div className={styles.content}>{pageData.profile.content}</div>
    </div>
  );

  const renderPatrolling = () => (
    <div className={styles.Patrol}>
      <div className={styles.subtitle}>{pageData.patrol.title}</div>
      <div className={styles.content}>{pageData.patrol.topContent}</div>
      <img src={pageData.patrol.imgUrl} alt="" className={styles.Patrol__img} />
      <div className={styles.content}>{pageData.patrol.lastContent}</div>
    </div>
  );

  const renderTeacher = () => (
    <div className={styles.teacher}>
      <div className={styles.subtitle}>{pageData.teacher.title}</div>
      <div className={styles.teacher__list}>
        {pageData.teacher.list.map((item, index) => (
          <div key={index} className={styles.teacher__list__item}>
            <img src={item.imgUrl} alt="" className={styles.teacher__list__item__img} />
            <div>{item.tips}</div>
          </div>
        ))}
      </div>
      <div className={styles.content}>{pageData.teacher.content}</div>
      <Button className={styles.teacher__register} onClick={handleRegister}>
        申请加入讲师团
      </Button>
    </div>
  );

  const classItem = (item: any) => (
    <div key={item.title} className={styles.class__item}>
      <div className={styles['tertiary-title']}>{item.title}</div>
      <img src={item.imgUrl} className={styles.class__img} alt="" />
      <div className={cns(styles.content, styles.class__content)}>{item.content}</div>
    </div>
  );

  const renderClass = () => (
    <div className={styles.class}>
      <div className={styles.subtitle}>{pageData.class.label}</div>
      {pageData.class.value.map((item) => classItem(item))}
    </div>
  );

  const renderCase = () => (
    <div className={styles.case}>
      <div className={styles.title}>服务案例</div>
      {renderPatrolling()}
      {renderTeacher()}
      {renderClass()}
    </div>
  );

  return (
    <section className={styles.local}>
      <div className={styles.local__container}>
        {renderProfile()}
        {renderCase()}
      </div>
    </section>
  );
}

export default TalentPC;
