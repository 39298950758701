import React from 'react';
import pageData from '../../../common/introduction/data.json';
import styles from './index.module.less';

function IntroductionMD() {
  const renderIcon = (item) => (
    <div className={styles.logo}>
      <img src={item.bg} alt="" className={styles.logo__bg} />
      <img src={item.icon} alt="" className={styles.logo__icon} />
    </div>
  );

  const renderList = (item) => (
    <div className={styles.list}>
      {item.value.map((ins) => (
        <>
          <div className={styles.list__label}>{ins.label}</div>
          <div className={styles.list__content}>{ins.content}</div>
        </>
      ))}
    </div>
  );

  const renderImg = (imgUrl) => <img src={imgUrl} alt="" className={styles.introduction__img} />;

  const renderContent = (content) => <div className={styles.introduction__content}>{content}</div>;

  return (
    <section className={styles.introduction}>
      {pageData.map((item) => {
        switch (item.type) {
          case 'icon':
            return renderIcon(item);
          case 'list':
            return renderList(item);
          case 'img':
            return renderImg(item.imgUrl);
          default:
            return renderContent(item.content);
        }
      })}
    </section>
  );
}

export default IntroductionMD;
