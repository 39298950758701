import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import Routes from './router/index';
import store from './store';

// 设置html font-size
(function (document, window, deviceWidth) {
  const rootEl = document.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    reCalc = function () {
      const clientWidth = rootEl.clientWidth;
      if (!clientWidth) return;
      if (clientWidth >= deviceWidth) {
        rootEl.style.fontSize = '100px';
      } else {
        rootEl.style.fontSize = 100 * (clientWidth / deviceWidth) + 'px';
      }
    };

  if (!document.addEventListener) return;
  window.addEventListener(resizeEvt, reCalc, false);
  document.addEventListener('DOMContentLoaded', reCalc, false);
})(document, window, 750);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <Routes />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
