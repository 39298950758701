import { useSafeState } from 'ahooks';

interface PreviewConfigType {
  isVisible: boolean;
  current: number;
}

export function useSummitDetail() {
  const [previewConfig, setPreviewConfig] = useSafeState<PreviewConfigType>({
    isVisible: false,
    current: 0,
  });

  const handlePreview = (current) => {
    setPreviewConfig((prev) => ({
      ...prev,
      isVisible: true,
      current,
    }));
  };

  const handleHidePreview = () => {
    setPreviewConfig((prev) => ({
      ...prev,
      isVisible: false,
    }));
  };

  return {
    previewConfig,
    handlePreview,
    handleHidePreview,
  };
}
