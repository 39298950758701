export const tabList = [
  {
    label: '新闻动态',
    value: 'dynamics',
  },
  {
    label: '信息公开',
    value: 'affair',
  },
];
