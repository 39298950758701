import React from 'react';
import Tab from '@/components/Tab/mobile';
import { tabList, profile } from '../common/member';
import { useMember } from '../hooks/useMember';
import MemberService from './components/MemberService';
import Choice from './components/Choice';
import Standard from './components/Standard';
import styles from './index.module.less';

function MemberMD() {
  const { currentTab, handleTab } = useMember();

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={profile.bgMD} alt="" className={styles.profile__bg} />
      <div className={styles.profile__title}>
        <div className={styles.profile__title__text}>{profile.label}</div>
        <div>{profile.subLabel}</div>
      </div>
      <div className={styles.profile__text}>{profile.content}</div>
    </div>
  );

  return (
    <section className={styles.member}>
      {renderProfile()}
      <Tab
        tabList={tabList.map((item) => item.label)}
        current={currentTab}
        isSticky
        isScrollToTop
        onClick={handleTab}
        className={styles.member__tab}
        itemClassName={styles.member__tab__item}
        activeClassName={styles.member__tab__active}
      />
      {currentTab === 0 && <MemberService />}
      {currentTab === 1 && <Choice />}
      {currentTab === 2 && <Standard />}
    </section>
  );
}

export default MemberMD;
