/**
 * 防抖
 * @param {function} func
 * @param {number} time 默认1.5s
 * @param {boolean} immediate 是否立即执行, 默认false
 * @returns
 */
const debounce = (func: Function, time: number = 1500, immediate: boolean = false) => {
  let timer: any;
  return (...params: any[]) => {
    clearTimeout(timer);

    if (immediate) {
      let callNow = !timer;
      timer = setTimeout(() => {
        timer = null;
      }, time);
      if (callNow) func.apply(this, params);
    } else {
      timer = setTimeout(() => {
        func.apply(this, params);
      }, time);
    }
  };
};

/**
 * 节流
 * @param {function} func
 * @param {number} time 默认1.5s
 * @returns
 */
const throttle = (func: Function, time: number = 1500) => {
  let timer: any;
  return (...params: any[]) => {
    if (!timer) {
      timer = setTimeout(() => {
        func.apply(this, params);
        timer = null;
      }, time);
    }
  };
}

export {
  debounce,
  throttle,
};
