import http from './';

const PREFIX = 'app/front';

type LecturerType = {
  bankCardNumber: string;
  briefIntroduction: string;
  courseContent: string;
  courseName: string;
  field: string;
  fullName: string;
  idNumber: string;
  openingBank: string;
  phone: string;
};

const apiBus = {
  // 获取PC轮播图
  getBanner: (data) =>
    http.get(`${PREFIX}/banner/list`, {
      params: data,
    }),

  // 获取h5轮播图
  getBannerMD: (data) =>
    http.get(`${PREFIX}/bannerMobile/list`, {
      params: data,
    }),

  // 获取访谈列表
  getInterviewList: (data) =>
    new Promise((resolve, reject) => {
      http
        .get(`${PREFIX}/lastestNews/page`, {
          params: {
            ...data,
            projectId: 15,
          },
        })
        .then((res: any) => {
          const tmp = res.records.map((item) => ({
            id: item.id,
            number: `No.${item.numero || item.id}`,
            title: item.title.split(' | ')[0],
            imgUrl:
              item.cover ||
              'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/interview/interview-cover.png',
          }));
          tmp.total = res.total;
          resolve(tmp);
        })
        .catch((error) => reject(error));
    }),

  // 获取访谈详情
  getInterviewDetail: (data) =>
    http
      .get(`${PREFIX}/lastestNews/selectById`, {
        params: data,
      })
      .then((res: any) => {
        res.title = res?.title?.split(' | ')[0];
        return res;
      }),

  // 获取前三条新闻
  getFirstThreeNews: () =>
    http.get(`${PREFIX}/firstThreeNews`).then((res: any) => {
      const tmp = res?.map((item) => ({
        id: item.id,
        content: item.content,
        time: item.datetime,
        imgUrl: item.frontImage,
        title: item.title,
      }));
      return tmp;
    }),

  // 获取新闻列表
  getNewsList: (data) =>
    new Promise((resolve, reject) => {
      http
        .get(`${PREFIX}/news/list`, {
          params: data,
        })
        .then((res: any) => {
          const tmp = res.records.map((item) => ({
            id: item.id,
            content: item.content,
            time: item.datetime,
            imgUrl: item.frontImage,
            title: item.title,
          }));
          tmp.total = res.total;
          resolve(tmp);
        })
        .catch((error) => reject(error));
    }),

  // 获取新闻详情
  getNewsDetail: (data) =>
    http.get(`${PREFIX}/news/selectById`, {
      params: data,
    }),

  // 获取信息公开列表
  getAffairList: (data) =>
    new Promise((resolve, reject) => {
      http
        .get(`${PREFIX}/msg/list`, {
          params: data,
        })
        .then((res: any) => {
          const tmp = res.records.map((item) => ({
            id: item.id,
            content: item.content,
            time: item.datetime,
            title: item.title,
          }));
          tmp.total = res.total;
          resolve(tmp);
        })
        .catch((error) => reject(error));
    }),

  // 获取信息公开详情
  getAffairDetail: (data) =>
    http.get(`${PREFIX}/msg/selectById`, {
      params: data,
    }),

  // 讲师申请
  addLecturer: (data: LecturerType) => http.post('app/lecturer/add', data),
};

export default apiBus;
