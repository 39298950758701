import React from 'react';
import { useNavigate } from 'react-router-dom';
import pageData from '../../../common/standard/data.json';
import styles from './index.module.less';

function StandardPC() {
  const navigate = useNavigate();

  const handleMore = () => {
    navigate('/member/more#become');
  };

  const renderDues = () => (
    <div className={styles.dues}>
      <div className={styles.title}>{pageData.dues.label}</div>
      <div className={styles.dues__table}>
        {pageData.dues.value.map((item) => (
          <div key={item.type} className={styles.dues__table__line}>
            <div className={styles.dues__table__line__type}>
              <img src={item.iconUrl} alt="" className={styles.dues__table__line__type__img} />
              {item.type}
            </div>
            <div className={styles.dues__table__line__content}>
              {Array.isArray(item.content)
                ? item.content.map((info, index) => (
                    <div key={index} className={styles.dues__table__line__content__item}>
                      <img
                        src={info.iconUrl}
                        alt=""
                        className={styles.dues__table__line__content__item__img}
                      />
                      <div className={styles.dues__table__line__content__item__tips}>
                        {info.tips}
                      </div>
                      <div>{info.price}</div>
                    </div>
                  ))
                : item.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderProcess = () => (
    <div className={styles.process}>
      <div className={styles.title}>{pageData.process.label}</div>
      <img src={pageData.process.imgUrl} alt="" className={styles.process__img} />
    </div>
  );

  const renderRequire = () => (
    <div className={styles.require}>
      <div className={styles.title}>{pageData.require.label}</div>
      <div className={styles.require__list}>
        {pageData.require.value.map((item, index) => (
          <div key={index} className={styles.require__list__item}>
            <span className={styles.require__list__item__number}>{index + 1}. </span>
            <span>{item}</span>
          </div>
        ))}
      </div>
      <div className={styles.require__more} onClick={handleMore}>
        {'查看更多 >>'}
      </div>
    </div>
  );

  const renderBecome = () => (
    <div className={styles.become}>
      <div className={styles.title}>{pageData.become.label}</div>
      <div className={styles.become__list}>
        {pageData.become.code.map((item, index) => (
          <div key={index} className={styles.become__list__item}>
            <img src={item.url} alt="" className={styles.become__list__item__img} />
            <div>{item.tips}</div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <section className={styles.standard}>
      <div className={styles.standard__container}>
        {renderDues()}
        {renderProcess()}
        {renderRequire()}
        {renderBecome()}
      </div>
    </section>
  );
}

export default StandardPC;
