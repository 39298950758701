import { Button, Image } from 'antd';
import Tab from '@/components/Tab/pc';
import Winner from '@/components/Winner';
import pageData from '../../../common/summit/summit.json';
import winnerData from '../../../common/summit/summit-winner.json';
import backData from '../../../common/summit/summit-back.json';
import { useSummit } from '../../../hooks/useSummit';
import styles from './index.module.less';

const SummitPC = () => {
  const { currentTab, handleTab, handleRegister, handleClickBack } = useSummit('pc');

  const renderBrief = () => (
    <div className={styles.brief}>
      <img src={pageData.brief.logo} alt="logo" className={styles.brief__logo} />
      <div className={styles.brief__text}>{pageData.brief.content}</div>
    </div>
  );

  const renderScene = () => (
    <div className={styles.scene}>
      <Image.PreviewGroup>
        <div className={styles.scene__left}>
          <div className={styles.scene__left__small}>
            <Image src={pageData.scene[0]} />
          </div>
          <div className={styles.scene__left__small}>
            <Image src={pageData.scene[1]} />
          </div>
          <div className={styles.scene__left__large}>
            <Image src={pageData.scene[2]} />
          </div>
        </div>
        <div className={styles.scene__right}>
          <div className={styles.scene__right__item}>
            <Image src={pageData.scene[3]} />
          </div>
          <div className={styles.scene__right__item}>
            <Image src={pageData.scene[4]} />
          </div>
        </div>
      </Image.PreviewGroup>
    </div>
  );

  const renderWinner = () => (
    <div className={styles.winner}>
      <div className={styles.subtitle}>历届年度数字新浙商名单</div>
      <Tab
        tabList={winnerData.map((item) => item.year)}
        current={currentTab}
        onClick={handleTab}
        className={styles.winner__tab}
        itemClassName={styles.winner__tab__item}
        activeClassName={styles.winner__tab__active}
      />
      <div className={styles.winner__list}>
        {winnerData[currentTab].winner.map((winner) => (
          <Winner key={winner.name} winner={winner} className={styles.winner__list__item} />
        ))}
      </div>
    </div>
  );

  const renderProcess = () =>
    pageData.process.map((item) => (
      <div key={item.label} className={styles.process}>
        {item.label === '活动流程' && (
          <img
            src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/pc/summit-bg-3.png"
            alt=""
            className={styles.process__bg}
          />
        )}
        <div className={styles.subtitle}>{item.label}</div>
        {item.type === 'text' ? (
          <div className={styles.process__text}>{item.content}</div>
        ) : (
          <img src={item.imgUrl} className={styles.process__img} alt="" />
        )}
      </div>
    ));

  const renderBack = () => (
    <div className={styles.back}>
      <div className={styles.subtitle}>往届回顾</div>
      {backData.map((item) => (
        <div
          key={item.year}
          className={styles.back__item}
          onClick={() => handleClickBack(item.year)}
        >
          <div className={styles.back__item__text}>
            <div>{item.year.substring(0, 2)}</div>
            <div>{item.year.substring(2)}</div>
          </div>
          <img src={item.imgUrl} className={styles.back__item__img} alt="" />
        </div>
      ))}
    </div>
  );

  const renderCooperator = () => (
    <div className={styles.cooperator}>
      <div className={styles.subtitle}>{pageData.cooperator.label}</div>
      <div className={styles.cooperator__img}>
        <img src={pageData.cooperator.imgUrl} alt="" />
      </div>
    </div>
  );

  return (
    <div className={styles.summit}>
      <img
        src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/pc/summit-bg-1.png"
        className={styles.summit__cover}
        alt=""
      />
      <div className={styles.summit__decoration} />
      <div className={styles.summit__content}>
        {renderBrief()}
        {renderScene()}
        {renderWinner()}
        {renderProcess()}
        {pageData.registerUrl && (
          <Button
            type="primary"
            size="small"
            className={styles.summit__btn}
            onClick={() => handleRegister(pageData.registerUrl)}
          >
            官方报名通道
          </Button>
        )}
        {renderBack()}
        {/* {renderCooperator()} */}
      </div>
    </div>
  );
};

export default SummitPC;
