export const tabList = ['地方服务', '人才服务', '智库服务', '产业服务'];

export const profile = {
  bg: 'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/service/service-bg.png',
  bgMD: 'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/service/service-bg-MD.png',
  label: '服务项目',
  subLabel: 'Industry Service',
  content:
    '学会连接政产学研用金各界力量，联动国内外行业和人才资源，通过智库、人才、产业、媒介四大平台，持续发挥“数字经济连接者”的功能，为地方发展数字经济产业赋能，为浙江省建设数字经济强省服务。',
};
