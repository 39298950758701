import React, { ReactElement } from 'react';
import { useSafeState } from 'ahooks';
import cns from 'classnames';
import { Pagination, Autoplay, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.less';
import 'swiper/modules/pagination/pagination.less';
import Icon from '@/components/Icon';
import styles from './index.module.less';

type SwiperPropsType = {
  children: Array<ReactElement>;
  spaceBetween?: number;
  slidesPerView?: number;
  slidesPerGroup?: number;
  hasNavigation?: boolean;
  isLoop?: boolean;
  isAutoplay?: boolean;
  className?: string;
  insClassName?: string;
  arrowColor?: 'yellow' | 'grey';
  hasArrowShadow?: boolean;
  hasPagination?: boolean;
  onSlideChange?: Function;
  next?: Function;
  prev?: Function;
  setRef?: Function;
};

const ASwiper = (props: SwiperPropsType) => {
  const {
    hasNavigation,
    next,
    prev,
    onSlideChange,
    className,
    insClassName,
    arrowColor,
    hasArrowShadow,
  } = props;
  const [swiperIns, setSwiperIns] = useSafeState<any>(null);
  const handlePrev = () => {
    swiperIns.slidePrev();
    prev && prev();
  };

  const handleNext = () => {
    swiperIns.slideNext();
    next && next();
  };

  const handleSlideChange = (swiper) => {
    onSlideChange && onSlideChange(swiper);
  };

  return (
    <div className={cns(styles.swiper, className)}>
      {hasNavigation && (
        <div
          className={cns(styles.swiper__arrow, styles.swiper__arrow__left, {
            [styles['swiper__arrow--shadow']]: hasArrowShadow,
          })}
          onClick={handlePrev}
        >
          <img
            src={require(`@/assets/images/swiper/${arrowColor}-left-arrow.png`).default}
            alt=""
          />
        </div>
      )}
      <Swiper
        modules={[Pagination, Autoplay, EffectCoverflow]}
        spaceBetween={props.spaceBetween}
        slidesPerView={props.slidesPerView}
        slidesPerGroup={props.slidesPerGroup}
        loop={props.isLoop}
        autoplay={
          props.isAutoplay && {
            delay: 5000,
          }
        }
        pagination={
          props.hasPagination && {
            el: `.${styles['swiper__pagination']}`,
            type: 'bullets',
            clickable: true,
            bulletClass: styles.swiper__pagination__item,
            bulletActiveClass: styles['swiper__pagination__item--active'],
          }
        }
        className={cns(styles.swiper__ins, insClassName)}
        onSwiper={(swiper) => {
          setSwiperIns(swiper);
          props.setRef && props.setRef(swiper);
        }}
        onSlideChange={handleSlideChange}
      >
        {React.Children.map(props.children, (element, index) => (
          <SwiperSlide key={index}>{element}</SwiperSlide>
        ))}
        {props.hasPagination && <div className={styles['swiper__pagination']} />}
      </Swiper>
      {hasNavigation && (
        <div
          className={cns(styles.swiper__arrow, styles.swiper__arrow__right, {
            [styles['swiper__arrow--shadow']]: hasArrowShadow,
          })}
          onClick={handleNext}
        >
          <img
            src={require(`@/assets/images/swiper/${arrowColor}-right-arrow.png`).default}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

ASwiper.defaultProps = {
  spaceBetween: 0,
  slidesPerView: 0,
  slidesPerGroup: 0,
  hasNavigation: false,
  isLoop: false,
  isAutoplay: false,
  className: '',
  insClassName: '',
  arrowColor: 'grey',
  hasArrowShadow: false,
  hasPagination: false,
  onSlideChange: undefined,
  next: undefined,
  prev: undefined,
};

export default ASwiper;
