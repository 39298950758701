import React from 'react';
import styles from './index.module.less';

type InstitutionDetailProps = {
  data: any;
};

function InstitutionDetailPC(props: InstitutionDetailProps) {
  const { data } = props;

  const renderContent = () => (
    <div className={styles.institution}>
      <div className={styles.title}>{data.title}</div>
      {data.profile && <div className={styles.profile}>{data.profile}</div>}
      {data.chapters?.map((chapters) => (
        <div className={styles.chapters}>
          <div className={styles.chapters__title}>{chapters.title}</div>
          {chapters.value?.map((item) => (
            <div className={styles.chapters__content}>
              <div>{item.content}</div>
              {item.subList &&
                item.subList?.map((subItem) => (
                  <div className={styles.chapters__content__sub}>{subItem}</div>
                ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  return renderContent();
}

export default InstitutionDetailPC;
