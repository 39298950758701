import React from 'react';
import { Button } from 'antd';
import { InterviewRegisterUrl } from '@/constants';
import pageData from '../../../common/merchant/data.json';
import styles from './index.module.less';

function MerchantPC() {
  const handleApply = () => {
    window.open(InterviewRegisterUrl);
  };

  return (
    <section className={styles.merchant}>
      <img src={pageData.logo} alt="" className={styles.merchant__logo} />
      <div className={styles.merchant__content}>{pageData.content[0]}</div>
      <img src={pageData.scene} alt="" className={styles.merchant__scene} />
      <div className={styles.merchant__content}>{pageData.content[1]}</div>
      <img src={pageData.splitIcon} alt="" className={styles.merchant__split} />
      <img src={pageData.bookUrl} alt="" className={styles.merchant__book} />
      <Button className={styles.merchant__button} onClick={handleApply}>
        专访申请
      </Button>
    </section>
  );
}

export default MerchantPC;
