import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import cns from 'classnames';
import { throttle, debounce } from '@/libs';
import { getScroll } from '@/libs/utils/getScroll';
import AIcon from '../Icon';
import styles from './index.module.less';

type BackTopType = {
  target?: Function;
  type?: 'pc' | 'mobile';
  iconSize?: number;
  visibilityHeight?: number;
  className?: string;
  onClick?: Function;
};

const BackTopPC = (props: BackTopType) => {
  const [visible, setVisible] = useSafeState(false);
  const { target, type, iconSize, visibilityHeight, className, onClick } = props;

  useEffect(() => {
    const handleScroll = throttle((e) => {
      const height = getScroll().top;

      if (height > Number(visibilityHeight)) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }, 500);

    const ins = target!();

    ins.addEventListener('scroll', handleScroll, true);

    return () => {
      ins.removeEventListener('scroll', handleScroll, true);
    };
  }, [target, visibilityHeight, type]);

  const handleClick = debounce(
    () => {
      onClick && onClick();
    },
    500,
    true,
  );

  return (
    <div
      onClick={handleClick}
      className={cns(styles.back, className)}
      style={{ display: visible ? '' : 'none' }}
    >
      {/* <div className={styles.back__icon}>
        <Icon name="arrow-up" color="rgba(210, 210, 210, 1)" size={iconSize} />
      </div> */}
      <img
        src={require('@/assets/images/backTop/arrow-icon.png').default}
        alt=""
        className={styles.back__icon}
      />
      {type === 'pc' && <span>回到顶部</span>}
    </div>
  );
};

BackTopPC.defaultProps = {
  target: () => window,
  type: 'pc',
  iconSize: 26,
  visibilityHeight: 400,
  onClick: undefined,
};

export default BackTopPC;
