import React from 'react';
import Tab from '@/components/Tab/pc';
import { tabList, profile } from '../common/service';
import { useService } from '../hooks/useService';
import Local from './components/Local';
import Talent from './components/Talent';
import ThinkTank from './components/ThinkTank';
import Industry from './components/Industry';
import styles from './index.module.less';

function ServicePC() {
  const { currentTab, handleTab } = useService();

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={profile.bg} alt="" className={styles.profile__bg} />
      <div className={styles.profile__container}>
        <div className={styles.profile__title}>
          <div className={styles.profile__title__text}>{profile.label}</div>
          <div>{profile.subLabel}</div>
        </div>
        <div className={styles.profile__text}>{profile.content}</div>
      </div>
    </div>
  );

  return (
    <section className={styles.service}>
      {renderProfile()}
      <Tab
        tabList={tabList}
        current={currentTab}
        isScrollToTop
        onClick={handleTab}
        className={styles.service__tab}
      />
      {currentTab === 0 && <Local />}
      {currentTab === 1 && <Talent />}
      {currentTab === 2 && <ThinkTank />}
      {currentTab === 3 && <Industry />}
    </section>
  );
}

export default ServicePC;
