import cns from 'classnames';
import styles from './index.module.less';

type LoadingPropsType = {
  text?: string;
  type?: 'pc' | 'mobile';
  className?: string;
};

const Loading = (props: LoadingPropsType) => {
  const { text, type, className } = props;

  return (
    <div className={cns(styles.loading, styles[`loading--${type}`], className)}>
      <div className={styles.loading__ins} />
      {text && <span>{text}</span>}
    </div>
  );
};

Loading.defaultProps = {
  text: '加载中...',
  type: 'mobile',
};

export default Loading;
