import cns from 'classnames';
import styles from './index.module.less';
import { InterviewType } from '../../common/interview';

type lineType = {
  interviewData: InterviewType;
  className?: string;
  onClick: Function;
};

const InterviewLinePC = (props: lineType) => {
  const { interviewData, className, onClick } = props;

  const handleClick = () => {
    onClick && onClick(interviewData.id);
  };

  return (
    <div className={cns(styles.line, className)} onClick={handleClick}>
      <div className={styles.line__number}>{interviewData.number}</div>
      <div className={styles.line__title}> {interviewData.title}</div>
    </div>
  );
};

InterviewLinePC.defaultProps = {
  interviewData: {},
  onClick: undefined,
};

export default InterviewLinePC;
