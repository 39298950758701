import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useSearchParams } from 'react-router-dom';
import institutionData from '../../common/rules/institution.json';
import InstitutionDetailPC from '../components/InstitutionDetail';
import styles from './index.module.less';

function InstitutionMD() {
  const [searchParams] = useSearchParams();
  const [data, setData] = useSafeState({});

  useEffect(() => {
    const id = searchParams.get('id') || '';
    setData(institutionData[id]);
  }, [searchParams]);

  return (
    <section className={styles.institution}>
      <InstitutionDetailPC data={data} />
    </section>
  );
}

export default InstitutionMD;
