import cns from 'classnames';
import styles from './index.module.less';

type ImagePreviewPropsType = {
  isVisible: boolean;
  imgUrl: string;
  tips?: string;
  className?: string;
  onClick: Function;
};

const ImagePreview = (props: ImagePreviewPropsType) => {
  const { isVisible, imgUrl, tips, className, onClick } = props;

  const handleClickMask = () => {
    onClick && onClick();
  };

  return (
    <div
      className={styles.mask}
      style={{ display: isVisible ? 'block' : 'none' }}
      onClick={handleClickMask}
    >
      <div className={styles.container}>
        <img
          src={imgUrl}
          className={cns(styles.img, className)}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
        {tips && <div className={styles.tips}>{tips}</div>}
      </div>
    </div>
  );
};

ImagePreview.defaultProps = {
  isVisible: false,
  imgUrl: '',
  tips: '',
  onClick: undefined,
};

export default ImagePreview;
