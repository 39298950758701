/**
 * 手机号校验
 * @param {string} val
 * @returns
 */
function validatePhone(val: string) {
  const regPhone = /^1[3-9]\d{9}$/;
  return regPhone.test(val);
}

/**
 * 身份证号校验
 * @param {string} val
 * @returns
 */
function validateIdNumber(val: string) {
  const regIdNumber = /^(\d{18}|\d{15}|\d{17}X)$/;
  return regIdNumber.test(val);
}

/**
 * 邮箱校验
 * @param {string} val
 * @returns
 */
function validateEmailAddress(val: string) {
  const regEmail = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
  return regEmail.test(val);
}

/**
 * 中文姓名校验
 * @param {string} val
 * @returns
 */
function validateName(val: string) {
  const regName = /^[\u4e00-\u9fa5]$/;
  return regName.test(val);
}

/**
 * 判断当前是否是IOS环境
 * @returns
 */
function validateIOS() {
  const u = navigator.userAgent;
  const regIOS = /\(i[^;]+;( U;)? CPU.+Mac OS X/;
  return !!u.match(regIOS);
}

export { validatePhone, validateIdNumber, validateEmailAddress, validateName, validateIOS };
