import { useEffect, createRef, useMemo } from 'react';
import { useSafeState } from 'ahooks';
import { throttle } from '@/libs';
import cns from 'classnames';
import styles from './index.module.less';

type tabType = {
  tabList: Array<string>;
  current: number;
  isSticky?: boolean;
  isScrollToTop?: boolean;
  className?: string;
  itemClassName?: string;
  activeClassName?: string;
  onClick: Function;
};

const TabMD = (props: tabType) => {
  const insRef: any = useMemo(() => createRef(), []);
  const { tabList, current, isScrollToTop, className, itemClassName, activeClassName, onClick } =
    props;
  const [tabIndex, setTabIndex] = useSafeState(current);
  const [isFixed, setIsFixed] = useSafeState(false);

  useEffect(() => {
    setTabIndex(props.current);
  }, [props]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const rect = insRef.current?.getBoundingClientRect();
      if (rect) {
        // 添加rect判断，防止页面跳转监听移除前执行函数
        if (rect?.top <= 0) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    }, 50);

    if (props.isSticky) {
      window.addEventListener('scroll', handleScroll, true);

      return () => {
        window.removeEventListener('scroll', handleScroll, true);
      };
    }
  }, [props, insRef]);

  const handleClickTab = (index: number) => {
    if (tabIndex !== index) {
      setTabIndex(index);
      onClick && onClick(index);
      if (isScrollToTop) {
        insRef.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <>
      <div className={cns(styles.tab, className, { [styles['tab--fixed']]: isFixed })}>
        {tabList.map((item, index) => (
          <div
            key={item}
            className={cns(styles.tab__item, itemClassName, {
              [styles['tab__item--active']]: tabIndex === index,
              [activeClassName || '']: tabIndex === index,
            })}
            onClick={() => handleClickTab(index)}
          >
            {item}
          </div>
        ))}
      </div>
      <div ref={insRef} className={styles.instance} />
    </>
  );
};

TabMD.defaultProps = {
  tabList: [],
  current: 0,
  isSticky: false,
  isScrollToTop: false,
  onClick: undefined,
};

export default TabMD;
