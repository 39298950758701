import { useSafeState } from 'ahooks';
import TabPC from '@/components/Tab/pc';
import pageData from '../../../common/choice/data.json';
import styles from './index.module.less';

function ChoicePC() {
  const [current, setCurrent] = useSafeState(0);

  const handleTab = (cur) => {
    if (current !== cur) {
      setCurrent(cur);
    }
  };

  return (
    <section className={styles.choice}>
      <div className={styles.choice__container}>
        <TabPC
          tabList={pageData.tabs}
          current={current}
          hasInitMargin={false}
          onClick={handleTab}
          className={styles.choice__tabs}
        />
        <div className={styles.choice__info}>
          <img src={pageData.imgMap[current]} alt="" className={styles.choice__img} />
        </div>
      </div>
    </section>
  );
}

export default ChoicePC;
