import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import Loading from '@/components/Loading';
import News from '@/components/News/mobile';
import Tab from '@/components/Tab/mobile';
import { throttle } from '@/libs';
import { getDeviceRatio } from '@/libs/utils/ratio';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import { getRemainTop } from '@/libs/utils/getScroll';
import { useTrip } from '../../../hooks/useTrip';
import pageData from '../../../common/trip/data.json';
import styles from './index.module.less';

function TripMD() {
  const tabList = ['外省活动', '本地活动'];
  const [current, setCurrent] = useSafeState(0);
  const {
    pageSize,
    pagination,
    setPagination,
    newsList,
    loading,
    setLoading,
    getNewsList,
    handleNews,
  } = useTrip('mobile');

  useEffect(() => {
    getNewsList();
  }, []);

  useEffect(() => {
    const footerHeight = getDeviceRatio() * 10.62;
    const handleScroll = throttle(() => {
      // 页面滑动到底部（footer显示之前）加载新闻信息
      if (getRemainTop() <= footerHeight) {
        handleReachBottom();
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const handleReachBottom = useSyncCallback(() => {
    if (loading) {
      return;
    }

    if ((pagination.page + 1) * pageSize < pagination.totalNumber) {
      setLoading(true);
      setTimeout(() => {
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
        getNewsList();
      }, 1000);
    }
  });

  const handleTab = (cur) => {
    setCurrent(cur);
  };

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.bgUrlMD} alt="" className={styles.profile__bg} />
      <img src={pageData.profile.logoUrl} alt="logo" className={styles.profile__logo} />
      <div className={styles.profile__text}>{pageData.profile.content}</div>
    </div>
  );

  const renderOther = () => (
    <div className={styles.other}>
      <div className={styles.other__img}>
        <div className={styles.other__img__line1}>
          {pageData.other.img_line1.slice(0, 2).map((imgUrl, index) => (
            <img key={index} src={imgUrl} alt="" className={styles.other__img__line1__item} />
          ))}
        </div>
        <img src={pageData.other.img_line2.large} alt="" className={styles.other__img__line2} />
        <div className={styles.other__img__line1}>
          {pageData.other.img_line1.slice(2, 4).map((imgUrl, index) => (
            <img key={index} src={imgUrl} alt="" className={styles.other__img__line1__item} />
          ))}
        </div>
        <div className={styles.other__img__line4}>
          {pageData.other.img_line2.small.map((imgUrl, index) => (
            <img key={index} src={imgUrl} alt="" className={styles.other__img__line4__item} />
          ))}
        </div>
      </div>
      <div className={styles.other__text}>{pageData.other.content[0]}</div>
      <div className={styles.other__img2}>
        {pageData.other.img_line3.map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" className={styles.other__img2__item} />
        ))}
      </div>
      <div className={styles.other__text}>{pageData.other.content[1]}</div>
    </div>
  );

  const renderLocal = () => (
    <div className={styles.local}>
      {pageData.local.imgList.map((imgUrl, index) => (
        <img key={index} src={imgUrl} alt="" className={styles.local__img} />
      ))}
      <div className={styles.local__text}>{pageData.local.content}</div>
    </div>
  );

  const renderNews = () => (
    <div className={styles.news}>
      <div className={styles.news__title}>相关新闻</div>
      <div className={styles.news__list}>
        {newsList.map((item) => (
          <News
            key={item.id}
            newsData={item}
            position="horizontal"
            onClick={handleNews}
            className={styles.news__list__item}
          />
        ))}
        {loading && <Loading />}
      </div>
    </div>
  );

  return (
    <section className={styles.trip}>
      {renderProfile()}
      <div className={styles.trip__tab}>
        <Tab
          tabList={tabList}
          current={current}
          onClick={handleTab}
          isSticky
          isScrollToTop
          className={styles.trip__tab__ins}
          itemClassName={styles.trip__tab__item}
          activeClassName={styles.trip__tab__active}
        />
      </div>
      <div className={styles.trip__content}>
        {current === 0 && renderOther()}
        {current === 1 && renderLocal()}
      </div>
      {newsList.length > 0 && renderNews()}
    </section>
  );
}

export default TripMD;
