import React, { useEffect } from 'react';
import { Image, Button } from 'antd';
import Tab from '@/components/Tab/pc';
import InterviewCard from '@/components/Interview/pc/card';
import InterviewLine from '@/components/Interview/pc/line';
import NewsCard from '@/components/News/pc';
import Icon from '@/components/Icon';
import Swiper from '@/components/Swiper';
import { useHome } from '../hooks/useHome';
import styles from './index.module.less';
import projectData from '../common/project.json';

function HomePC() {
  const {
    banners,
    interviews,
    news,
    swiperRef,
    setSwiperRef,
    currentProject,
    setCurrentProject,
    getBanners,
    getInterviews,
    getNews,
    handleBanner,
    handleSwiperChange,
    handleProjectDetail,
    handleInterview,
    handleNews,
    handleMore,
  } = useHome('pc');

  useEffect(() => {
    getBanners();
    getInterviews();
    getNews();
  }, []);

  const handleTab = (current: number) => {
    if (currentProject !== current) {
      setCurrentProject(current);
      swiperRef.slideTo(current + 1, 1000, false);
    }
  };

  const renderBanner = () => (
    <div className={styles.banner}>
      <Swiper
        isLoop
        hasPagination
        slidesPerView={1}
        slidesPerGroup={1}
        className={styles.banner__swiper}
      >
        {banners.map((item) => (
          <img
            key={item.id}
            src={item.imgUrl}
            alt=""
            onClick={() => handleBanner(item.linkUrl)}
            className={styles.banner__img}
          />
        ))}
      </Swiper>
    </div>
  );

  const renderProject = () => (
    <div className={styles.project}>
      <div className={styles.title}>重点项目</div>
      <Tab
        tabList={projectData.map((item) => item.tab)}
        current={currentProject}
        onClick={handleTab}
      />
      <Swiper
        isLoop
        isAutoplay
        slidesPerView={1}
        slidesPerGroup={1}
        className={styles.project__container}
        insClassName={styles.project__swiper__ins}
        onSlideChange={handleSwiperChange}
        setRef={setSwiperRef}
      >
        {projectData.map((project, index) => (
          <div key={index} className={styles.project__container}>
            <div className={styles.project__info}>
              <img src={project.bgUrl} alt="" className={styles.project__info__bg} />
              <div className={styles.project__info__title}>{project.title}</div>
              <div className={styles.project__info__content}>{project.content}</div>
              <Button
                size="small"
                className={styles.project__info__detail}
                onClick={() => handleProjectDetail(project)}
              >
                查看详情
              </Button>
            </div>
            <div className={styles.project__img}>
              <Image
                src={project.imgUrl}
                preview={false}
                className={styles['project__img--instance']}
              />
            </div>
          </div>
        ))}
      </Swiper>
    </div>
  );

  const renderMore = (isNews) => (
    <div className={styles.more}>
      <div className={styles.more__instance} onClick={() => handleMore(isNews)}>
        查看更多
        <Icon name="arrow-right" size={14} className={styles.more__arrow} />
      </div>
    </div>
  );

  const renderInterview = () => (
    <div className={styles.interview}>
      <div className={styles.title}>解码数字新浙商</div>
      {renderMore(false)}
      <div className={styles.interview__container}>
        <div className={styles['interview__card-box']}>
          {interviews.slice(0, 2).map((item) => (
            <div key={item.id} className={styles['interview__card-box__item']}>
              <InterviewCard interviewData={item} onClick={handleInterview} />
            </div>
          ))}
        </div>
        <div className={styles['interview__line-box']}>
          {interviews.slice(2).map((item) => (
            <div key={item.id} className={styles['interview__line-box__item']}>
              <InterviewLine interviewData={item} onClick={handleInterview} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderNews = () => (
    <div className={styles.news}>
      <div className={styles.title}>学会新闻</div>
      {renderMore(true)}
      <div className={styles.news__box}>
        {news.map((item) => (
          <div key={item.id} className={styles.news__box__item}>
            <NewsCard newsData={item} onClick={handleNews} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <section className={styles.home}>
      {banners.length > 0 && renderBanner()}
      {renderProject()}
      {renderInterview()}
      {renderNews()}
    </section>
  );
}

export default HomePC;
