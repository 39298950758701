import dayjs from 'dayjs';
import cns from 'classnames';
import styles from './index.module.less';
import { newsItemType } from '../common/news';

type cardType = {
  newsData: newsItemType;
  className?: string;
  onClick: Function;
};

const NewsCardPC = (props: cardType) => {
  const { newsData, className, onClick } = props;

  const handleClick = () => {
    onClick && onClick(newsData.id);
  };

  return (
    <div className={cns(styles.card, className)} onClick={handleClick}>
      <img src={newsData.imgUrl} alt="" className={styles.card__img} />
      <div className={styles.card__title}>{newsData.title}</div>
      <div className={styles.card__time}>{dayjs(newsData.time).format('YYYY.MM.DD')}</div>
    </div>
  );
};

export default NewsCardPC;
