import { Button } from 'antd';
import { useSafeState } from 'ahooks';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import pageData from '../../../common/industry/data.json';
import { useIndustry } from '../../../hooks/useIndustry';
import styles from './index.module.less';

function IndustryPC() {
  const pageSize = 6;
  const [page, setPage] = useSafeState(0);
  const { modal, setModal, applyType, handleChangeApplyType, handleConfirm } = useIndustry();

  const handleMore = () => {
    setPage((prev) => prev + 1);
  };

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.logo} alt="logo" className={styles.profile__logo} />
      <div className={styles.profile__info}>
        <img src={pageData.profile.imgUrl} alt="" className={styles.profile__img} />
        <div className={styles.profile__content}>{pageData.profile.content}</div>
      </div>
      <img src={pageData.profile.cooperator} alt="" className={styles.profile__cooperator} />
    </div>
  );

  const renderStructure = () => (
    <div className={styles.structure}>
      <div className={styles.title}>{pageData.structure.label}</div>
      <div className={styles.structure__list}>
        {pageData.structure.value.map((item) => (
          <div key={item.name} className={styles.structure__item}>
            <img src={item.avatarUrl} alt="" className={styles.structure__item__avatar} />
            <div className={styles.structure__item__info}>
              <div className={styles.structure__item__info__emphasis}>
                <div className={styles.structure__item__rank}>{item.rank}</div>
                <div className={styles.structure__item__name}>{item.name}</div>
              </div>
              <div className={styles.structure__item__appellation}>
                {item.appellation.map((appellation, index) => (
                  <div key={index}>{appellation}</div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Button
        className={styles.structure__button}
        onClick={() => setModal((prev) => ({ ...prev, isVisible: true }))}
      >
        申请加入联盟
      </Button>
    </div>
  );

  const renderEvent = () => (
    <div className={styles.event}>
      <div className={styles.title}>{pageData.event.label}</div>
      <div className={styles.event__list}>
        {pageData.event.value.slice(0, pageSize * (page + 1)).map((item, index) => (
          <div key={index} className={styles.event__item}>
            <img src={item.imgUrl} alt="" className={styles.event__item__img} />
            <div className={styles.event__item__info}>
              <div className={styles.event__item__title}>{item.title}</div>
              <div className={styles.event__item__content}>{item.content}</div>
            </div>
          </div>
        ))}
      </div>
      {pageSize * (page + 1) < pageData.event.value.length && (
        <div className={styles.event__more} onClick={handleMore}>
          加载更多
          <Icon name="arrow-down" size={20} color="rgba(207, 156, 110, 1)" />
        </div>
      )}
    </div>
  );

  const renderModal = () => (
    <Modal {...modal} className={styles.modal} onConfirm={handleConfirm}>
      <div className={styles.apply}>
        {pageData.apply.map((item) => (
          <div
            key={item.type}
            onClick={() => handleChangeApplyType(item.value)}
            className={styles.apply__item}
          >
            <div className={styles.apply__img}>
              <img
                src={require(`@/assets/images/service/${item.icon}.png`).default}
                alt=""
                className={styles.apply__img__default}
              />
              <img
                src={require(`@/assets/images/service/${item.icon}-select.png`).default}
                alt=""
                className={styles.apply__img__active}
                style={{ opacity: item.value === applyType ? 1 : 0 }}
              />
            </div>
            <div>{item.type}</div>
          </div>
        ))}
      </div>
    </Modal>
  );

  return (
    <section className={styles.industry}>
      <div className={styles.industry__container}>
        {renderProfile()}
        {renderStructure()}
        {renderEvent()}
      </div>
      {renderModal()}
    </section>
  );
}

export default IndustryPC;
