import React, { ReactElement } from 'react';
import cns from 'classnames';
import styles from './index.module.less';

export type ActionSheetPropsType = {
  isVisible: boolean;
  tips?: string;
  children: Array<ReactElement | undefined>;
  className?: string;
  setVisible: Function;
  onClick?: Function;
  onCancel?: Function;
};

const ActionSheet = (props: ActionSheetPropsType) => {
  const { isVisible, tips, children, className, setVisible, onClick, onCancel } = props;

  const handleClick = (index) => {
    onClick && onClick(index);
    setVisible(false);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setVisible(false);
  };

  return (
    <div
      className={styles.mask}
      style={{ display: isVisible ? 'block' : 'none' }}
      onClick={() => setVisible(false)}
    >
      <div className={cns(styles.sheet, className)} onClick={(e) => e.stopPropagation()}>
        {tips && <div className={styles.sheet__tips}>{tips}</div>}
        {React.Children.map(children, (child, index) =>
          child ? (
            <div className={styles.sheet__item} onClick={() => handleClick(index)}>
              {child}
            </div>
          ) : undefined,
        )}
        <div className={styles.sheet__cancel} onClick={handleCancel}>
          取消
        </div>
      </div>
    </div>
  );
};

ActionSheet.defaultProps = {
  isVisible: false,
  tips: '',
  children: undefined,
  setVisible: undefined,
  onClick: undefined,
  onCancel: undefined,
};

export default ActionSheet;
