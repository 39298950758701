export const bannerData = {
  imgUrl: 'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/profile-bg.png',
  imgUrlMD:
    'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/profile-bg-MD.png',
  logoUrl: 'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/digit-logo.png',
  content:
    '“数字新浙商”是指浙江数字经济领域的创业者、企业家，由浙江省数字经济学会于2019年7月首倡该项目通过《解码数宇新浙商》系列专访、数字新浙商年度峰会、数字新浙商中国行等活动，分享数字新思想、发掘经济新动力，为浙江省数字经济一号工程加油助力。',
};

export const TabList = ['解码数字新浙商', '数字新浙商年度峰会', '数字新浙商中国行'];
