import rules from '../../../common/rules/data.json';
import { institutionTitle, institutionList } from '../../../common/rules/institutionList';
import InstitutionDetailPC from '../InstitutionDetail';
import styles from './index.module.less';

function RulesMD() {
  const handleInstitution = (value) => {
    window.open(window.location.href.replace('/#rules', `/institution?id=${value}#rules`));
  };

  const renderLogo = () => (
    <div className={styles.logo}>
      <img src={require('@/assets/images/logo.png').default} alt="" className={styles.logo__img} />
    </div>
  );

  const renderContent = () => (
    <div className={styles.content}>
      <InstitutionDetailPC data={rules} />
    </div>
  );

  const renderInstitution = () => (
    <div className={styles.institution}>
      <div className={styles.institution__title}>{institutionTitle}</div>
      {institutionList.map((item, index) => (
        <div
          key={index}
          onClick={() => handleInstitution(item.value)}
          className={styles.institution__ins}
        >
          {item.label}
        </div>
      ))}
    </div>
  );

  return (
    <section className={styles.rules}>
      {renderLogo()}
      {renderContent()}
      {renderInstitution()}
    </section>
  );
}

export default RulesMD;
