import React, { useEffect } from 'react';
import cns from 'classnames';
import { throttle } from '@/libs';
import Loading from '@/components/Loading';
import Tab from '@/components/Tab/mobile';
import News from '@/components/News/mobile';
import Affair from '@/components/Affair';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import { getDeviceRatio } from '@/libs/utils/ratio';
import { getRemainTop } from '@/libs/utils/getScroll';
import { useNewsList } from '../../hooks/useNewsList';
import styles from './index.module.less';

function NewsListMD() {
  const {
    tabList,
    pageSize,
    pagination,
    setPagination,
    newsList,
    affairList,
    loading,
    setLoading,
    currentTab,
    handleClick,
    handleTab,
    getData,
  } = useNewsList('mobile');

  useEffect(() => {
    const footerHeight = getDeviceRatio() * 10.32;
    const handleScroll = throttle(() => {
      if (getRemainTop() <= footerHeight) {
        handleReachBottom();
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const handleReachBottom = useSyncCallback(() => {
    if (loading) {
      return;
    }

    if ((pagination.page + 1) * pageSize < pagination.totalNumber) {
      setLoading(true);
      setTimeout(() => {
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
        getData();
      }, 1000);
    }
  });

  const renderNewsList = () => (
    <div className={styles.list}>
      {newsList.map((news, index) => (
        <News
          key={news.id}
          position={index !== 0 ? 'horizontal' : 'vertical'}
          newsData={news}
          onClick={handleClick}
          className={cns(styles.list__item, {
            [styles['list__item--horizontal']]: index !== 0,
          })}
        />
      ))}
      {loading && <Loading />}
    </div>
  );

  const renderAffairList = () => (
    <div className={styles.affair}>
      {affairList.map((item) => (
        <Affair
          affair={item}
          type="mobile"
          onClick={handleClick}
          className={styles.affair__list__item}
        />
      ))}
      {loading && <Loading />}
    </div>
  );

  return (
    <section className={styles.news}>
      <div className={styles.news__tab}>
        <Tab
          tabList={tabList.map((item) => item.label)}
          current={currentTab}
          isSticky
          isScrollToTop
          onClick={handleTab}
          className={styles.news__tab__ins}
          itemClassName={styles.news__tab__item}
          activeClassName={styles.news__tab__active}
        />
      </div>
      {currentTab === 0 && renderNewsList()}
      {currentTab === 1 && renderAffairList()}
    </section>
  );
}

export default NewsListMD;
