export const tabList = [
  {
    label: '会员服务',
    value: 'service',
  },
  {
    label: '会员精选',
    value: 'choice',
  },
  {
    label: '成为会员',
    value: 'become',
  },
];

export const profile = {
  bg: 'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/member/pc/profile-bg.png',
  bgMD: 'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/member/mobile/profile-bg.png',
  label: '学会会员',
  subLabel: 'Society Member',
  content:
    '根据浙江省数字经济学会章程，学会会员分为个人会员和单位会员两类。个人会员是数字经济领域的研究者和从业者，单位会员是数字经济领域的企业、高校、研究院所和社会组织。截至2022年8月，企业会员共计455家，其中上市公司23家、独角兽（准独角兽）企业76家；个人会员553人，其中教授（研究员）58人。',
};
