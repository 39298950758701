import { useNavigate } from 'react-router-dom';
import cns from 'classnames';
import { FooterType } from '../common/footer';
import styles from './index.module.less';

type FooterPropsType = {
  footerData: FooterType;
};

const FooterPC = (props: FooterPropsType) => {
  const { footerData } = props;
  const {
    project: projectData,
    contact: contactData,
    code: codeData,
    right: rightData,
  } = footerData as any;
  const navigate = useNavigate();

  const handleProject = (item) => {
    navigate(item.linkUrl);
    window.scrollTo(0, 0);
  };

  return (
    <section className={styles.footer}>
      <div className={styles.footer__content}>
        <div className={styles.footer__info}>
          <div className={styles.footer__label}>{projectData.label}</div>
          <ul>
            {projectData.value.map((item: { title: string; linkUrl: string }, index) => (
              <li
                key={index}
                className={cns(styles.footer__info__line, styles.footer__info__project)}
                onClick={() => handleProject(item)}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.footer__info}>
          <div className={styles.footer__label}>{contactData.label}</div>
          <ul>
            {contactData.value.map((item: any) => (
              <li key={item.label} className={styles.footer__info__line}>
                <div>{item.label}：</div>
                <div>
                  {typeof item.value === 'string'
                    ? item.value
                    : item.value.map((subItem: string, subIndex: number) => (
                        <div key={subIndex}>{subItem}</div>
                      ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
        {codeData.map((item: any, index: number) => (
          <div key={index} className={styles.footer__code}>
            <img src={item.codeUrl} alt="公众号" className={styles.footer__code__img} />
            {item.tips.map((tip: string) => (
              <div key={tip}>{tip}</div>
            ))}
          </div>
        ))}
        <div className={styles.footer__right}>
          <div>
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
              {rightData[1].label}
            </a>
            <img
              src={require('@/assets/images/beian-icon.png').default}
              alt="公众号"
              className={styles.footer__beian__img}
            />
            <a
              href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002014405"
              rel="noreferrer"
              target="_blank"
            >
              {rightData[2].label}
            </a>
          </div>
          <div>{rightData[0].label}</div>
        </div>
      </div>
    </section>
  );
};

export default FooterPC;
