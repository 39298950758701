import { useEffect } from 'react';
import { HashRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import LayoutPC from '../pages/layout/pc';
import HomePC from '../pages/home/pc';
import DigitPC from '../pages/project/digit/pc';
import SummitDetailPC from '../pages/project/digit/pc/summitDetail';
import CityPC from '../pages/project/city/pc';
import WorkPC from '../pages/project/work/pc';
import ServicePC from '../pages/project/service/pc';
import ApplicationFormPC from '../pages/project/service/pc/applicationForm';
import NewsListPC from '../pages/news/pc/list';
import NewsDetailPC from '../pages/news/pc/detail';
import MemberPC from '../pages/member/pc';
import StandardMorePC from '../pages/member/pc/more';
import AboutPC from '../pages/about/pc';
import InstitutionPC from '../pages/about/pc/institution';

import LayoutMD from '../pages/layout/mobile';
import HomeMD from '../pages/home/mobile';
import DigitMD from '../pages/project/digit/mobile';
import InterviewListMD from '../pages/project/digit/mobile/InterviewList';
import InterviewMD from '../pages/project/digit/mobile/Interview';
import SummitDetailMD from '../pages/project/digit/mobile/summitDetail';
import WorkMD from '../pages/project/work/mobile';
import CityMD from '../pages/project/city/mobile';
import ServiceMD from '../pages/project/service/mobile';
import ApplicationFormMD from '../pages/project/service/mobile/applicationForm';
import NewsListMD from '../pages/news/mobile/list';
import NewsDetailMD from '../pages/news/mobile/detail';
import MemberMD from '../pages/member/mobile';
import StandardMoreMD from '../pages/member/mobile/more';
import AboutMD from '../pages/about/mobile';
import InstitutionMD from '../pages/about/mobile/institution';

// 路由重定向
function Redirect({ to }: any) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to, { replace: true });
  });
  return null;
}

// 路由切换，将页面返回顶部
function ScrollToTop(props) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return props.children;
}

const BasicRoute = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<LayoutPC />}>
            <Route index element={<Redirect to="/home" />} />
            <Route path="home" element={<HomePC />} />
            <Route path="project">
              <Route index element={<DigitPC />} />
              <Route path="digit" element={<DigitPC />} />
              <Route path="summit" element={<SummitDetailPC />} />
              <Route path="work" element={<WorkPC />} />
              <Route path="city" element={<CityPC />} />
              <Route path="service" element={<ServicePC />} />
              <Route path="application" element={<ApplicationFormPC />} />
            </Route>
            <Route path="news">
              <Route index element={<NewsListPC />} />
              <Route path="detail" element={<NewsDetailPC />} />
            </Route>
            <Route path="member">
              <Route index element={<MemberPC />} />
              <Route path="more" element={<StandardMorePC />} />
            </Route>
            <Route path="about">
              <Route index element={<AboutPC />} />
              <Route path="institution" element={<InstitutionPC />} />
            </Route>
          </Route>
          <Route path="/phone" element={<LayoutMD />}>
            <Route index element={<Redirect to="/phone/home" />} />
            <Route path="home" element={<HomeMD />} />
            <Route path="project">
              <Route index element={<DigitMD />} />
              <Route path="digit">
                <Route index element={<DigitMD />} />
                <Route path="list" element={<InterviewListMD />} />
                <Route path="interview" element={<InterviewMD />} />
                <Route path="summit" element={<SummitDetailMD />} />
              </Route>
              <Route path="work">
                <Route index element={<WorkMD />} />
              </Route>
              <Route path="city" element={<CityMD />} />
              <Route path="service">
                <Route index element={<ServiceMD />} />
                <Route path="application" element={<ApplicationFormMD />} />
              </Route>
            </Route>
            <Route path="news">
              <Route index element={<NewsListMD />} />
              <Route path="detail" element={<NewsDetailMD />} />
            </Route>
            <Route path="member">
              <Route index element={<MemberMD />} />
              <Route path="more" element={<StandardMoreMD />} />
            </Route>
            <Route path="about">
              <Route index element={<AboutMD />} />
              <Route path="institution" element={<InstitutionMD />} />
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default BasicRoute;
