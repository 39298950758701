import cns from 'classnames';
import { CommitteeType } from '../common/committee';
import styles from './index.module.less';

type tabType = {
  committeeData: CommitteeType;
  className?: string;
};

const CommitteePC = (props: tabType) => {
  const { committeeData, className } = props;

  return (
    <div className={cns(styles.committee, className)}>
      <div className={styles.committee__department}>
        <img src={committeeData.icon} alt="" className={styles.committee__department__icon} />
        <span>{committeeData.committee}</span>
        <span>{committeeData.committeeEng}</span>
      </div>
      <div className={styles.committee__director}>
        <img src={committeeData.avatarUrl} alt="" className={styles.committee__director__avatar} />
        <div>
          <div className={styles.committee__director__name}>
            <span>{committeeData.rank}</span>
            <span>{committeeData.name}</span>
          </div>
          <div className={styles.committee__director__appellation}>
            {committeeData.appellation.join('、')}
          </div>
          <div className={styles.committee__director__profile}>{committeeData.profile}</div>
        </div>
      </div>
    </div>
  );
};

export default CommitteePC;
