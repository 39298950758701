import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import cns from 'classnames';
import Swiper from '@/components/Swiper';
import Tab from '@/components/Tab/mobile';
import { throttle } from '@/libs';
import { getRemainTop } from '@/libs/utils/getScroll';
import { getDeviceRatio } from '@/libs/utils/ratio';
import pageData from '../../../common/talent/data.json';
import styles from './index.module.less';

function TalentMD() {
  const navigate = useNavigate();
  const tabList = ['浙江省数字经济巡讲团', '数字经济系列高研班'];
  const [current, setCurrent] = useSafeState(0);
  const [showBtn, setShowBtn] = useSafeState(true);

  useEffect(() => {
    const btnHideHeight = getDeviceRatio() * 10.72;
    const handleScroll = throttle(() => {
      if (getRemainTop() <= btnHideHeight) {
        setShowBtn(false);
      } else {
        setShowBtn(true);
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const handleTab = (cur) => {
    setCurrent(cur);
  };

  const handleRegister = () => {
    navigate('/phone/project/service/application');
    // window.open('http://azalea-tech.mikecrm.com/S8juig5');
  };

  const renderProfile = () => (
    <div className={styles.profile}>
      <div className={styles.title}>{pageData.profile.label}</div>
      <div className={styles.content}>{pageData.profile.content}</div>
    </div>
  );

  const renderPatrolling = () => (
    <div className={styles.Patrol}>
      <div className={styles.title}>{pageData.patrol.title}</div>
      <div className={styles.content}>{pageData.patrol.topContent}</div>
      {pageData.patrol.imgListMD.map((imgUrl) => (
        <img src={imgUrl} alt="" className={styles.Patrol__img} />
      ))}
      <div className={styles.content}>{pageData.patrol.lastContent}</div>
    </div>
  );

  const renderTeacher = () => (
    <div className={styles.teacher}>
      <div className={styles.title}>{pageData.teacher.title}</div>
      <Swiper
        isLoop
        spaceBetween={6}
        slidesPerView={1.45}
        slidesPerGroup={1}
        className={styles.teacher__list}
      >
        {pageData.teacher.list.map((item, index) => (
          <div key={index} className={styles.teacher__list__item}>
            <img src={item.imgUrl} alt="" className={styles.teacher__list__item__img} />
            <div>{item.tips}</div>
          </div>
        ))}
      </Swiper>
      <div className={styles.content}>{pageData.teacher.content}</div>
      <div
        className={cns(
          styles.teacher__register,
          styles[`teacher__register--${showBtn ? 'fixed' : 'absolute'}`],
        )}
      >
        <Button className={styles.teacher__register__ins} onClick={handleRegister}>
          申请加入讲师团
        </Button>
      </div>
    </div>
  );

  const renderClass = () => (
    <div className={styles.class}>
      {pageData.class.value.map((item) => (
        <div key={item.title} className={styles.class__item}>
          <div className={styles.title}>{item.title}</div>
          {item.imgListMD.map((imgUrl) => (
            <img src={imgUrl} className={styles.class__item__img} alt="" />
          ))}
          <div className={cns(styles.content, styles.content)}>{item.content}</div>
        </div>
      ))}
    </div>
  );

  const renderCase = () => (
    <div className={styles.case}>
      <div className={styles.title}>服务案例</div>
      <div className={styles.case__tab}>
        <Tab
          tabList={tabList}
          current={current}
          isSticky
          isScrollToTop
          onClick={handleTab}
          className={styles.case__tab__ins}
          itemClassName={styles.case__tab__item}
          activeClassName={styles.case__tab__active}
        />
      </div>
      {current === 0 && renderPatrolling()}
      {current === 0 && renderTeacher()}
      {current === 1 && renderClass()}
    </div>
  );

  return (
    <section className={styles.local}>
      {renderProfile()}
      {renderCase()}
    </section>
  );
}

export default TalentMD;
