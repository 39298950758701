import pageData from '../../common/more/data.json';
import styles from './index.module.less';

function StandardMoreMD() {
  const renderCondition = () => (
    <div className={styles.condition}>
      {pageData.map((item) => (
        <div key={item.label} className={styles.condition__item}>
          <div className={styles.condition__item__label}>{item.label}</div>
          <div className={styles.condition__item__list}>
            {item.value.map((condition, index) => (
              <div key={index}>{condition}</div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  return <section className={styles.more}>{renderCondition()}</section>;
}

export default StandardMoreMD;
