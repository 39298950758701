import React, { useEffect } from 'react';
import cns from 'classnames';
import InterviewCard from '@/components/Interview/mobile/card';
import InterviewLine from '@/components/Interview/mobile/line';
import NewsCard from '@/components/News/mobile';
import Icon from '@/components/Icon';
import Swiper from '@/components/Swiper';
import { useHome } from '../hooks/useHome';
import styles from './index.module.less';
import projectData from '../common/project.json';

function HomeMD() {
  const {
    banners,
    interviews,
    news,
    swiperRef,
    setSwiperRef,
    getBanners,
    getInterviews,
    getNews,
    handleBanner,
    handleSwiperChange,
    handleProjectDetail,
    handleInterview,
    handleNews,
    handleMore,
  } = useHome('mobile');

  useEffect(() => {
    getBanners();
    getInterviews();
    getNews();
  }, []);

  const renderBanner = () => (
    <div className={styles.banner}>
      <Swiper
        isLoop
        hasPagination
        slidesPerView={1}
        slidesPerGroup={1}
        className={styles.banner__swiper}
      >
        {banners.map((item) => (
          <img
            key={item.id}
            src={item.imgUrl}
            alt=""
            onClick={() => handleBanner(item.linkUrl)}
            className={styles.banner__img}
          />
        ))}
      </Swiper>
    </div>
  );

  const renderProject = () => (
    <div className={styles.project}>
      <div className={styles.title}>重点项目</div>
      <Swiper
        isLoop
        slidesPerView={1.2}
        slidesPerGroup={1}
        spaceBetween={20}
        className={styles.project__container}
        insClassName={styles.project__swiper__ins}
        onSlideChange={handleSwiperChange}
        setRef={setSwiperRef}
      >
        {projectData.map((project, index) => (
          <div
            key={index}
            className={cns(styles.project__card, {
              [styles['project__card--active']]: swiperRef?.realIndex === index,
            })}
            onClick={() => handleProjectDetail(project)}
          >
            <img src={project.imgUrl} alt="" className={styles.project__card__img} />
            <div className={styles.project__card__info}>
              <div className={styles.project__card__info__title}>{project.title}</div>
              <div className={styles.project__card__info__content}>{project.content}</div>
            </div>
            <Icon
              name="arrow-right"
              size={42}
              color="rgba(167, 167, 167, 1)"
              className={styles.project__card__icon}
            />
          </div>
        ))}
      </Swiper>
    </div>
  );

  const renderMore = (isNews) => (
    <div className={styles.more}>
      <div className={styles.more__instance} onClick={() => handleMore(isNews)}>
        查看更多
        <Icon name="arrow-right" size={23} color="rgba(167, 167, 167, 1)" />
      </div>
    </div>
  );

  const renderInterview = () => (
    <div className={styles.interview}>
      <div className={styles.title}>解码数字新浙商</div>
      <div className={styles.interview__container}>
        {interviews[0] && <InterviewCard interviewData={interviews[0]} onClick={handleInterview} />}
        <div className={styles.interview__line}>
          {interviews.slice(1, 4).map((item) => (
            <InterviewLine
              key={item.id}
              interviewData={item}
              onClick={handleInterview}
              className={styles.interview__line__item}
            />
          ))}
        </div>
      </div>
      {renderMore(false)}
    </div>
  );

  const renderNews = () => (
    <div className={styles.news}>
      <div className={styles.title}>学会新闻</div>
      <div className={styles.news__box}>
        {news.slice(0, 3).map((item, index) => (
          <NewsCard
            key={item.id}
            newsData={item}
            position={index === 0 ? 'vertical' : 'horizontal'}
            onClick={handleNews}
            className={styles.news__box__item}
          />
        ))}
      </div>
      {renderMore(true)}
    </div>
  );

  return (
    <section className={styles.home}>
      {banners.length > 0 && renderBanner()}
      <div className={styles.home__container}>
        {renderProject()}
        {renderInterview()}
        {renderNews()}
      </div>
    </section>
  );
}

export default HomeMD;
