import { useEffect, useMemo } from 'react';
import { useSafeState } from 'ahooks';
import cns from 'classnames';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { throttle } from '@/libs';
import Modal from '@/components/Modal';
import { getDeviceRatio } from '@/libs/utils/ratio';
import { getRemainTop } from '@/libs/utils/getScroll';
import pageData from '../../../common/groupLogo/data.json';
import { useGroupLogo } from '../../../hooks/useGroupLogo';
import styles from './index.module.less';

function GroupLogoMD() {
  const [showBtn, setShowBtn] = useSafeState(true);
  const {
    showLogo,
    coverRef,
    modalData,
    xhEmail,
    setModalData,
    handleDownload,
    handleGroupLogo,
    handleCopyEmail,
  } = useGroupLogo('mobile');

  useEffect(() => {
    const btnHideHeight = getDeviceRatio() * 10.72;
    const handleBtnShow = throttle(() => {
      if (getRemainTop() <= btnHideHeight) {
        setShowBtn(false);
      } else {
        setShowBtn(true);
      }
    }, 50);

    window.addEventListener('scroll', handleBtnShow, true);

    return () => {
      window.removeEventListener('scroll', handleBtnShow, true);
    };
  }, []);

  const handleUpload = () => {
    setModalData((prev) => ({
      ...prev,
      isVisible: true,
      children: modalContent,
    }));
  };

  const modalContent = useMemo(
    () => (
      <div className={styles['modal-content']}>
        请将Word版报名表与PDF版盖章扫描报名表，一起发送至
        <span onClick={handleCopyEmail}>{xhEmail}</span>
        学会将在 7 个工作日内回复。
      </div>
    ),
    [],
  );

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.logoUrlMD} alt="logo" className={styles.profile__logo} />
      {pageData.profile.content.map((item, index) => (
        <div key={index} className={styles.profile__info}>
          {item}
        </div>
      ))}
    </div>
  );

  const renderPublish = () => (
    <div className={styles.publish}>
      <div className={styles.publish__title}>{pageData.publish.title}</div>
      <div ref={coverRef} className={styles.publish__cover}>
        {pageData.publish.coverList.map((imgUrl: string) => (
          <div
            className={cns(styles.publish__cover__item, {
              [styles['publish__cover__item--anim']]: showLogo,
            })}
          >
            <img src={imgUrl} alt="" className={styles.publish__cover__img} />
            <img src={imgUrl} alt="" className={styles.publish__cover__reflect} />
          </div>
        ))}
      </div>
      <div className={styles.publish__list}>
        {pageData.publish.value.map((item) => (
          <div
            key={item.number}
            className={styles.publish__list__item}
            onClick={() => handleGroupLogo(item.id)}
          >
            <div>{item.name}</div>
            <div>{item.number}</div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderProcess = () => (
    <div className={styles.process}>
      <div className={styles.process__title}>{pageData.process.label}</div>
      <img src={pageData.process.imgUrlMD} alt="" className={styles.process__img} />
    </div>
  );

  const renderButtons = () => (
    <div
      className={cns(
        styles.group__button,
        styles[`group__button--${showBtn ? 'fixed' : 'absolute'}`],
      )}
    >
      <Button type="primary" className={styles.group__button__item} onClick={handleDownload}>
        下载报名表
        <DownloadOutlined />
      </Button>
      <Button type="primary" className={styles.group__button__item} onClick={handleUpload}>
        上传报名表
      </Button>
    </div>
  );

  return (
    <section className={styles.group}>
      {renderProfile()}
      {renderPublish()}
      {renderProcess()}
      {renderButtons()}
      <Modal {...modalData} isMobile />
    </section>
  );
}

export default GroupLogoMD;
