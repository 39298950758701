import { createSlice } from '@reduxjs/toolkit';

export const interviewSlice = createSlice({
  name: 'interview',
  initialState: {
    list: [],
  },
  reducers: {
    setInterviewCache: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setInterviewCache } = interviewSlice.actions;

export default interviewSlice.reducer;
