import React from 'react';
import cns from 'classnames';
import dayjs from 'dayjs';
import Icon from '@/components/Icon';
import News from '@/components/News/mobile';
import { useNewsDetail } from '../../hooks/useNewsDetail';
import styles from './index.module.less';

function NewsMD() {
  const { isNews, newsList, newsDetail, handleNews, handleMore } = useNewsDetail('mobile');

  const handlePreviewAppendix = (index) => {
    window.open(newsDetail.attachments[index]);
  };

  const renderNewsDetail = () => (
    <div className={styles.detail}>
      <div className={styles.detail__title}>{newsDetail.title}</div>
      <div className={styles.detail__time}>{dayjs(newsDetail.time).format('YYYY.MM.DD')}</div>
      <div className={styles.detail__article}>
        <div dangerouslySetInnerHTML={{ __html: newsDetail.content }} />
      </div>
      {newsDetail.attachments?.length > 0 && renderAppendix()}
    </div>
  );

  const renderNewsRecommend = () => (
    <div className={styles.recommend}>
      <div className={styles.recommend__title}>新闻推荐</div>
      <div className={styles.recommend__list}>
        {newsList.map((news, index) => (
          <News
            key={news.id}
            position={index !== 0 ? 'horizontal' : 'vertical'}
            newsData={news}
            onClick={handleNews}
            className={cns(styles.recommend__list__item, {
              [styles['recommend__list__item--horizontal']]: index !== 0,
            })}
          />
        ))}
      </div>
      <div onClick={handleMore} className={styles.recommend__more}>
        查看更多
        <Icon name="arrow-right" size={24} color="rgba(167, 167, 167, 1)" />
      </div>
    </div>
  );

  const renderAppendix = () => (
    <div className={styles.appendix}>
      <div className={styles.appendix__title}>下载附件：</div>
      {newsDetail.attachments?.map((fileName, index) => (
        <span
          key={index}
          className={styles.appendix__item}
          onClick={() => handlePreviewAppendix(index)}
        >
          {fileName.split('/')?.slice(-1)?.[0]}
        </span>
      ))}
    </div>
  );

  return (
    <section className={styles.news}>
      {renderNewsDetail()}
      {isNews && renderNewsRecommend()}
    </section>
  );
}

export default NewsMD;
