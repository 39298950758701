import cns from 'classnames';
import styles from './index.module.less';
import { InterviewType } from '../../common/interview';

type cardType = {
  interviewData: InterviewType;
  className?: string;
  onClick: Function;
};

const InterviewCardMD = (props: cardType) => {
  const { interviewData, className, onClick } = props;

  const handleClick = () => {
    onClick && onClick(interviewData.id);
  };

  return (
    <div className={cns(styles.card, className)} onClick={handleClick}>
      <img src={interviewData.imgUrl} alt="" className={styles.card__img} />
      <div className={styles.card__info}>
        <div className={styles.card__info__number}>{interviewData.number}</div>
        <div className={styles.card__info__content}>{interviewData.title}</div>
      </div>
    </div>
  );
};

export default InterviewCardMD;
