import React from 'react';
import Tab from '@/components/Tab/mobile';
import { useAbout } from '../hooks/useAbout';
import Introduction from './components/Introduction';
import DetailOrganization from './components/Organization';
import Branch from './components/Branch';
import Rules from './components/Rules';
import Contact from './components/contact';
import styles from './index.module.less';

function AboutMD() {
  const { tabList, currentTab, handleTab } = useAbout();

  return (
    <section className={styles.about}>
      <Tab
        tabList={tabList.map((item) => item.label)}
        current={currentTab}
        onClick={handleTab}
        isSticky
        isScrollToTop
        className={styles.about__tab}
        itemClassName={styles.about__tab__item}
        activeClassName={styles.about__tab__active}
      />
      {currentTab === 0 && <Introduction />}
      {currentTab === 1 && <DetailOrganization />}
      {currentTab === 2 && <Branch />}
      {currentTab === 3 && <Rules />}
      {currentTab === 4 && <Contact />}
    </section>
  );
}

export default AboutMD;
