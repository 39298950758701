import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useLocation } from 'react-router-dom';

export function useService() {
  const navigate = useNavigate();
  const location = useLocation();
  const initCurrent = Number(location.hash.substring(1));
  const [currentTab, setCurrentTab] = useSafeState(initCurrent || 0);

  useEffect(() => {
    setCurrentTab(initCurrent);
  }, [initCurrent]);

  const handleTab = (current: number) => {
    setCurrentTab(current);
    navigate(`#${current}`);
  };

  return {
    currentTab,
    handleTab,
  };
}
