// 获取容器滚动范围
export const getScroll = () => {
  return {
    left:
      window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0,
    top: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0,
  };
};

// 获取文档剩余高度
export const getRemainTop = () => {
  const html = document.documentElement;
  return html.scrollHeight - getScroll().top - html.clientHeight;
};
