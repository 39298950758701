import cns from 'classnames';
import styles from './index.module.less';
import { InterviewType } from '../../common/interview';

type lineType = {
  interviewData: InterviewType;
  position?: 'horizontal' | 'vertical';
  isActive?: boolean;
  onClick: Function;
};

const InterviewLinePC = (props: lineType) => {
  const { interviewData, position, isActive, onClick } = props;

  const handleClick = () => {
    onClick && onClick(interviewData.id);
  };

  return (
    <div
      className={cns(styles.line, styles[`line--${position}`], {
        [styles[`line--${position}--active`]]: isActive,
      })}
      onClick={handleClick}
    >
      <div
        className={cns(styles.line__number, {
          [styles[`line__number--active`]]: isActive,
        })}
      >
        {interviewData.number}
      </div>
      <div className={styles.line__title}> {interviewData.title}</div>
    </div>
  );
};

InterviewLinePC.defaultProps = {
  interviewData: {},
  position: 'horizontal',
  isActive: false,
  onClick: undefined,
};

export default InterviewLinePC;
