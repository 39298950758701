import { useSafeState } from 'ahooks';
import ImagePreview from '@/components/ImagePreview';
import { FooterType } from '../common/footer';
import styles from './index.module.less';

type FooterPropsType = {
  footerData: FooterType;
};

const FooterMD = (props: FooterPropsType) => {
  const { footerData } = props;
  const { contact: contactData, code: codeData, right: rightData } = footerData as any;
  const [imagePreview, setImagePreview] = useSafeState({
    isVisible: false,
    imageUrl: '',
    tips: '',
  });

  const handleClickCode = (item) => {
    setImagePreview((prev) => ({
      ...prev,
      isVisible: true,
      imageUrl: item.codeUrl,
      tips: item.tips,
    }));
  };

  const hidePreview = () => {
    setImagePreview((prev) => ({
      ...prev,
      isVisible: false,
      imageUrl: '',
      tips: '',
    }));
  };

  return (
    <section className={styles.footer}>
      <div className={styles.footer__label}>{contactData.label}</div>
      <ul className={styles.footer__list}>
        {contactData.value.map((item: any) => (
          <li key={item.label} className={styles.footer__list__line}>
            <div>{item.label}：</div>
            <div>
              {typeof item.value === 'string'
                ? item.value
                : item.value.map((subItem: string, subIndex: number) => (
                    <div key={subIndex} className={styles.footer__list__line__address}>
                      {subItem}
                    </div>
                  ))}
            </div>
          </li>
        ))}
        <li className={styles.footer__list__line}>
          <div />
          {codeData.map((item: any, index: number) => (
            <div key={index} className={styles.footer__code}>
              <img
                src={item.codeUrl}
                alt="公众号"
                className={styles.footer__code__img}
                onClick={() => handleClickCode(item)}
              />
              <div>{item.label}</div>
            </div>
          ))}
        </li>
        <li className={styles.footer__list__line}>
          <div />
          <div className={styles.footer__right}>
            <div>
              <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
                {rightData[1].label}
              </a>
            </div>
            <div>
              <img
                src={require('@/assets/images/beian-icon.png').default}
                alt="公众号"
                className={styles.footer__beian__img}
              />
              <a
                href="https://beian.mps.gov.cn/#/query/webSearch?code=33011002014405"
                rel="noreferrer"
                target="_blank"
              >
                {rightData[2].label}
              </a>
            </div>
            <div>{rightData[0].label}</div>
          </div>
        </li>
      </ul>
      <ImagePreview
        isVisible={imagePreview.isVisible}
        imgUrl={imagePreview.imageUrl}
        tips={imagePreview.tips}
        onClick={hidePreview}
      />
    </section>
  );
};

export default FooterMD;
