import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { throttle } from '@/libs';
import Loading from '@/components/Loading';
import InterviewCard from '@/components/Interview/mobile/card';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import apiBus from '@/serve/api';
import { getDeviceRatio } from '@/libs/utils/ratio';
import { getRemainTop } from '@/libs/utils/getScroll';
import { InterviewType } from '../../common/interview/interview';
import styles from './index.module.less';

function InterviewListMD() {
  const pageSize = 6;
  const navigate = useNavigate();
  const interviewsCache = useSelector((state: any) => state.interview.list);
  const [pagination, setPagination] = useSafeState({
    page: 1,
    totalNumber: interviewsCache.length || 0,
  });
  const [interviews, setInterviews] = useSafeState<Array<InterviewType>>([]);
  const [loading, setLoading] = useSafeState(false);

  useEffect(() => {
    getInterviews();
  }, []);

  useEffect(() => {
    const footerHeight = getDeviceRatio() * 10.32;
    const handleScroll = throttle(() => {
      if (getRemainTop() <= footerHeight) {
        handleReachBottom();
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const getInterviews = useSyncCallback(() => {
    setLoading(true);
    const { page } = pagination;

    if (loading) {
      return;
    }

    apiBus
      .getInterviewList({
        page,
        limit: pageSize,
      })
      .then((res: any) => {
        setInterviews((prev) => [...prev, ...res]);
        setPagination((prev) => ({ ...prev, totalNumber: res.total }));
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const handleReachBottom = useSyncCallback(() => {
    if (loading) {
      return;
    }

    if (pagination.totalNumber > pageSize * pagination.page) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      getInterviews();
    }
  });

  const handleClick = (id) => {
    navigate(`/phone/project/digit/interview?id=${id}`);
  };

  return (
    <section className={styles['interview-list']}>
      {interviews.slice(0, (pagination.page + 1) * pageSize).map((item) => (
        <InterviewCard
          key={item.id}
          interviewData={item}
          onClick={handleClick}
          className={styles['interview-list__item']}
        />
      ))}
      {loading && <Loading />}
    </section>
  );
}

export default InterviewListMD;
