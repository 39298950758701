export const tabList = ['团标平台', '区块链标技委'];

export const profile = {
  bg: 'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/work/pc/bg.png',
  bgMD: 'https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/work/mobile/bg.png',
  label: '标准化工作',
  subLabel: 'Standardization Project',
  content:
    '标准化工作对支撑数字经济高质量发展发挥着基础性、战略性和引领性作用。学会全面启动标准化工作：积极联合会员单位共同打造团体标准；承担省区块链标准化技术委员会秘书处工作；组织指导会员单位参与区域性标准化工作。',
};
