import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';
import apiBus from '@/serve/api';
import { getDeviceRatio } from '@/libs/utils/ratio';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import Loading from '@/components/Loading';
import InterviewLine from '@/components/Interview/pc/line';
import { InterviewType } from '@/components/Interview/common/interview';
import { getScroll } from '@/libs/utils/getScroll';
import styles from './index.module.less';
import Merchant from '../Merchant';
import { InterviewType as InterviewDetailType } from '../../../common/interview/interview';

type InterviewPropsType = {
  interviewData: Array<InterviewType>;
};

function InterViewPC(props: InterviewPropsType) {
  const { interviewData } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useSafeState(false);
  const [articleId, setArticleId] = useSafeState(Number(searchParams.get('id')) || -1);
  const tmpIndex = interviewData.findIndex((item) => item.id === articleId);
  const [currentIvIdx, setCurrentIvIdx] = useSafeState(tmpIndex < 0 ? 0 : tmpIndex);
  const [interviewDetail, setInterviewDetail] = useSafeState<InterviewDetailType>(
    {} as InterviewDetailType,
  );

  useEffect(() => {
    const id = Number(searchParams.get('id')) || -1;
    const tmpIndex = interviewData.findIndex((item) => item.id === id);

    setArticleId(id);
    setCurrentIvIdx(tmpIndex);
  }, [interviewData]);

  useEffect(() => {
    if (articleId === -1 || interviewData.length < 1) {
      return;
    }

    setLoading(true);
    getInterviewDetail();
  }, [articleId, interviewData]);

  const getInterviewDetail = useSyncCallback(() => {
    apiBus
      .getInterviewDetail({
        id: articleId,
      })
      .then((res: any) => {
        setInterviewDetail(res);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const handleClickIv = (id) => {
    const tmpIndex = interviewData.findIndex((item) => item.id === id);
    setCurrentIvIdx(tmpIndex);
    setArticleId(id);
    setSearchParams({ id });
    _backToArticleTop();
  };

  const _backToArticleTop = () => {
    const profileHeight = 2.9 * getDeviceRatio();

    if (getScroll().top > profileHeight) {
      window.scrollTo({
        top: profileHeight,
        left: 0,
      });
    }
  };

  const renderOther = () => (
    <div className={styles.other}>
      {interviewData[currentIvIdx - 1] && interviewData[currentIvIdx - 1]?.id !== -1 && (
        <div
          className={styles.other__prev}
          onClick={() => handleClickIv(interviewData[currentIvIdx - 1]?.id)}
        >
          上一篇：{interviewData[currentIvIdx - 1]?.title}
        </div>
      )}
      {interviewData[currentIvIdx + 1] && (
        <div
          className={styles.other__next}
          onClick={() => handleClickIv(interviewData[currentIvIdx + 1]?.id)}
        >
          下一篇：{interviewData[currentIvIdx + 1]?.title}
        </div>
      )}
    </div>
  );

  const renderArticle = () => (
    <div className={styles.article}>
      <div className={styles.article__container}>
        {articleId === -1 ? (
          <Merchant />
        ) : (
          <>
            <div className={styles.article__container__title}>
              <div>{interviewDetail.title}</div>
              {interviewDetail.numero && <div>解码数字新浙商No.{interviewDetail.numero}</div>}
            </div>
            <div className={styles.article__container__content}>
              <div dangerouslySetInnerHTML={{ __html: interviewDetail.content }} />
            </div>
            {renderOther()}
          </>
        )}
      </div>
    </div>
  );

  const renderList = () => (
    <div className={styles.previous}>
      <div className={styles.previous__title}>往期访谈</div>
      <div className={styles.previous__list}>
        {interviewData.map((data, index) => (
          <div key={data.id} className={styles.previous__list__item}>
            <InterviewLine
              interviewData={data}
              position="vertical"
              isActive={currentIvIdx === index}
              onClick={handleClickIv}
            />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Spin indicator={<Loading type="pc" />} spinning={interviewData.length < 1 || loading}>
      <section className={styles.interview}>
        {interviewData.length > 0 && (
          <div className={styles.interview__content}>
            {renderArticle()}
            {renderList()}
          </div>
        )}
      </section>
    </Spin>
  );
}

export default InterViewPC;
