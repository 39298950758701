import React from 'react';
import cns from 'classnames';
import pageData from '../../../common/local/data.json';
import styles from './index.module.less';

function LocalMD() {
  const renderProfile = () => (
    <div className={styles.profile}>
      <div className={styles.title}>{pageData.profile.label}</div>
      <div className={styles.content}>{pageData.profile.content}</div>
    </div>
  );

  const caseItem = (item: any) => (
    <div className={styles.case__item}>
      <div className={styles.title}>{item.title}</div>
      <div>
        {item.imgListMD.map((imgUrl, index) => (
          <img key={index} src={imgUrl} className={styles.case__img} alt="" />
        ))}
      </div>
      <div className={cns(styles.content, styles.case__content)}>{item.content}</div>
    </div>
  );

  const renderCase = () => (
    <div className={styles.case}>
      <div className={styles.title}>{pageData.case.label}</div>
      {pageData.case.value.map((item) => caseItem(item))}
    </div>
  );

  const renderLast = () => (
    <div>
      <div className={styles.title}>{pageData.last.title}</div>
      <div className={styles.content}>{pageData.last.content}</div>
    </div>
  );

  return (
    <section className={styles.local}>
      <div className={styles.local__container}>
        {renderProfile()}
        {renderCase()}
        {renderLast()}
      </div>
    </section>
  );
}

export default LocalMD;
