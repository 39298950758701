import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, Outlet } from 'react-router-dom';
import isMobile from 'is-mobile';
import cns from 'classnames';
import { DEVICE_WIDTH } from '@/constants';
import BackTop from '@/components/BackTop';
import HeaderPC from '@/components/Header/pc';
import FooterPC from '@/components/Footer/pc';
import { getDeviceRatio } from '@/libs/utils/ratio';
import { throttle } from '@/libs';
import { getRemainTop } from '@/libs/utils/getScroll';
import menuData from '../common/menu.json';
import footerData from '../common/footerPC.json';
import { logoUrl } from '../common/logo';
import styles from './index.module.less';

function LayoutPC() {
  const navigate = useNavigate();
  const [fixed, setFixed] = useSafeState(false);

  useEffect(() => {
    const CLIENT_WIDTH = document.body.clientWidth;

    if (CLIENT_WIDTH <= DEVICE_WIDTH || isMobile()) {
      navigate('/phone', { replace: true });
    }
  }, []);

  useEffect(() => {
    const btnHideHeight = getDeviceRatio() * 2.08;
    const handleScroll = throttle(() => {
      if (getRemainTop() <= btnHideHeight) {
        setFixed(false);
      } else {
        setFixed(true);
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <section className={styles.layout}>
      <HeaderPC menuData={menuData} logoUrl={logoUrl} />
      <div className={styles.layout__content}>
        <Outlet />
      </div>
      <FooterPC footerData={footerData} />
      <BackTop
        type="pc"
        iconSize={26}
        visibilityHeight={400}
        className={cns(
          styles['layout__back-top'],
          styles[`layout__back-top--${fixed ? 'fixed' : 'absolute'}`],
        )}
        onClick={handleTop}
      />
    </section>
  );
}

export default LayoutPC;
