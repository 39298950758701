import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useSelector, useDispatch } from 'react-redux';
import cns from 'classnames';
import apiBus from '@/serve/api';
import { setInterviewCache } from '@/store/slices/interview';
import Tab from '@/components/Tab/pc';
import { InterviewType } from '@/components/Interview/common/interview';
import { bannerData, TabList } from '../common/variable';
import { useDigit } from '../hooks/useDigit';
import Interview from './components/Interview';
import Summit from './components/Summit';
import Trip from './components/Trip';
import styles from './index.module.less';

function DigitPC() {
  const dispatch = useDispatch();
  const interviewsCache = useSelector((state: any) => state.interview.list);
  const [interviewData, setInterviewData] = useSafeState<Array<InterviewType>>(interviewsCache);
  const { currentTab, handleTab } = useDigit();

  useEffect(() => {
    getInterviews();
  }, []);

  const getInterviews = () => {
    if (!interviewsCache?.length) {
      apiBus
        .getInterviewList({
          page: 1,
          limit: 1000,
        })
        .then((res: any) => {
          const tmp = [...res];
          setInterviewData(tmp);
          dispatch(setInterviewCache(res));
        });
    } else {
      setInterviewData((prev: any) => {
        const tmp = [...prev];
        return tmp;
      });
    }
  };

  const renderBanner = () => (
    <div className={styles.banner}>
      <img src={bannerData.imgUrl} alt="" className={styles.banner__bg} />
      <div className={styles.banner__container}>
        <img src={bannerData.logoUrl} alt="" className={styles.banner__logo} />
        <div className={styles.banner__content}>{bannerData.content}</div>
      </div>
    </div>
  );

  return (
    <section className={styles.digit}>
      {renderBanner()}
      <div className={styles.digit__bg}>
        <Tab
          tabList={TabList}
          current={currentTab}
          isScrollToTop
          onClick={handleTab}
          className={cns(styles.digit__tab, {
            [styles['digit__tab--transparent']]: currentTab === 1,
          })}
        />
        {currentTab === 0 && <Interview interviewData={interviewData} />}
        {currentTab === 1 && <Summit />}
        {currentTab === 2 && <Trip />}
      </div>
    </section>
  );
}

export default DigitPC;
