import { useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import apiBus from '@/serve/api';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import { newsItemType } from '@/components/News/common/news';

export function useTrip(type) {
  const pageSize = 6;
  const navigate = useNavigate();
  const [pagination, setPagination] = useSafeState({
    page: 0,
    totalNumber: 0,
  });
  const [newsList, setNewsList] = useSafeState<Array<newsItemType>>([]);
  const [loading, setLoading] = useSafeState(false);

  const getNewsList = useSyncCallback(() => {
    apiBus
      .getNewsList({
        pageNo: pagination.page + 1,
        pageSize,
        tag: 'zgx',
      })
      .then((res: any) => {
        setPagination((prev) => ({
          ...prev,
          totalNumber: res.total,
        }));

        setNewsList((prev) => [...prev, ...res]);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const handleNews = (id: number) => {
    navigate(`${type === 'mobile' ? '/phone' : ''}/news/detail?id=${id}&isNews=true`);
  };

  return {
    pageSize,
    pagination,
    setPagination,
    newsList,
    setNewsList,
    loading,
    setLoading,
    getNewsList,
    handleNews,
  };
}
