import { Fragment, useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useLocation } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { HeaderType } from '../common/header';
import styles from './index.module.less';

type HeaderPropsType = {
  menuData: HeaderType;
  logoUrl: string;
};

const HeaderPC = (props: HeaderPropsType) => {
  const { menuData, logoUrl } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const menu = JSON.parse(JSON.stringify(menuData));
  const paths = location.pathname.split('/');
  const [current, setCurrent] = useSafeState(paths[paths.length - 1]);

  useEffect(() => {
    const paths = location.pathname.split('/');
    if (paths.length > 2) {
      if (['news', 'member', 'about'].includes(paths[1])) {
        // 判断当前页面是否是学会会员或关于学会
        setCurrent(location.hash);
      } else {
        // 判断最后一级路径是否存在menu，不存在则显示上一级
        menuData
          ?.find((item) => item?.key === paths[1])
          ?.children?.find((item) => item.key === paths[paths.length - 1])
          ? setCurrent(paths[paths.length - 1])
          : setCurrent(paths[1]);
      }
    } else {
      setCurrent(paths[1]);
    }
  }, [location.pathname, location.hash, menuData]);

  const handleClickMenu: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
    navigate(`/${e.keyPath.reverse().join('/')}`);
  };

  const handleHome = () => {
    if (location.pathname !== '/home') {
      navigate('/');
    }
  };

  return (
    <Fragment>
      <div className={styles.header}>
        <div className={styles.header__content}>
          <img src={logoUrl} alt="logo" className={styles.header__logo} onClick={handleHome} />
          <Menu
            items={menu}
            selectedKeys={[current]}
            mode="horizontal"
            getPopupContainer={(menuNode) => menuNode.parentNode as any}
            className={styles.header__menu}
            onClick={handleClickMenu}
          />
        </div>
      </div>
      <div className={styles.header__instance} />
    </Fragment>
  );
};

export default HeaderPC;
