import cns from 'classnames';
import dayjs from 'dayjs';
import styles from './index.module.less';
import { AffairType } from './Affair';

type AffairPropType = {
  affair: AffairType;
  type: 'pc' | 'mobile';
  className?: string;
  onClick: Function;
};

const Affair = (props: AffairPropType) => {
  const { affair, type, className, onClick } = props;

  const handleClick = () => {
    onClick && onClick(affair.id);
  };

  return (
    <div className={cns(styles.affair, styles[`affair--${type}`], className)} onClick={handleClick}>
      <div className={styles.affair__time}>
        <div className={styles.affair__time__date}>{dayjs(affair.time).date()}</div>
        <div className={styles.affair__time__month}>{dayjs(affair.time).format('YYYY.MM')}</div>
      </div>
      <div className={styles.affair__title}> {affair.title}</div>
    </div>
  );
};

Affair.defaultProps = {
  title: '',
  day: '',
  date: '',
  type: 'pc',
  onClick: undefined,
};

export default Affair;
