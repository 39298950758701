import React, { ReactElement } from 'react';
import cns from 'classnames';
import pageData from '../../../common/memberService/data.json';
import styles from './index.module.less';

function MemberServicePC() {
  const renderProfile = () => (
    <div className={styles.profile}>
      <div className={styles.title}>{pageData.profile.label}</div>
      <div className={styles.profile__info}>
        <img src={pageData.profile.imgUrl} alt="" className={styles.profile__img} />
        <div className={styles.profile__content}>
          {pageData.profile.content.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderBase = () => (
    <div className={styles.base}>
      <div className={styles.title}>{pageData.base.label}</div>
      <div className={styles.base__list}>
        {pageData.base.value.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    </div>
  );

  const plusList = (plusData: Array<{ type: string; content: string }>) => {
    const res: Array<ReactElement> = [];

    for (let i = 0; i < plusData.length - 1; i += 2) {
      res.push(
        <tr key={i} className={styles.plus__table__tbody__tr}>
          <td
            className={cns(styles.plus__table__tbody__td, styles['plus__table__tbody__td--split'])}
          >
            <span>{plusData[i].type}</span>
            <span>{plusData[i].content}</span>
          </td>
          <td className={styles.plus__table__tbody__td}>
            <span>{plusData[i + 1].type}</span>
            <span>{plusData[i + 1].content}</span>
          </td>
        </tr>,
      );
    }

    return res;
  };

  const renderPlus = () => (
    <div className={styles.plus}>
      <div className={styles.title}>{pageData.plus.label}</div>
      <table className={styles.plus__table}>
        <thead className={styles.plus__table__thead}>
          <tr>
            <td className={styles.plus__table__thead__td}>
              <span>服务类型</span>
              <span>服务内容</span>
            </td>
            <td className={styles.plus__table__thead__td}>
              <span>服务类型</span>
              <span>服务内容</span>
            </td>
          </tr>
        </thead>
        <tbody>{plusList(pageData.plus.value)}</tbody>
      </table>
    </div>
  );

  return (
    <section className={styles.service}>
      <div className={styles.service__container}>
        {renderProfile()}
        {renderBase()}
        {renderPlus()}
      </div>
    </section>
  );
}

export default MemberServicePC;
