import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { Button } from 'antd';
import cns from 'classnames';
import Loading from '@/components/Loading';
import Tab from '@/components/Tab/mobile';
import Modal from '@/components/Modal';
import { throttle } from '@/libs';
import { getDeviceRatio } from '@/libs/utils/ratio';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import { getRemainTop } from '@/libs/utils/getScroll';
import pageData from '../../../common/industry/data.json';
import { useIndustry } from '../../../hooks/useIndustry';
import styles from './index.module.less';

function IndustryMD() {
  const pageSize = 3;
  const tabList = ['组织架构', '联盟大事记'];
  const [current, setCurrent] = useSafeState(0);
  const [pagination, setPagination] = useSafeState({
    page: 0,
    totalNumber: pageData.event.value.length,
  });
  const [events, setEvents] = useSafeState<Array<any>>([]);
  const [loading, setLoading] = useSafeState(false);
  const [showBtn, setShowBtn] = useSafeState(true);
  const { modal, setModal, applyType, handleChangeApplyType, handleConfirm } = useIndustry();

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    const footerHeight = getDeviceRatio() * 10.32;
    const btnHideHeight = getDeviceRatio() * 10.72;
    const handleScroll = throttle(() => {
      if (getRemainTop() <= footerHeight) {
        handleReachBottom();
      }

      if (getRemainTop() <= btnHideHeight) {
        setShowBtn(false);
      } else {
        setShowBtn(true);
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const getEvents = useSyncCallback(() => {
    setEvents(pageData.event.value.slice(0, (pagination.page + 1) * pageSize));
    setLoading(false);
  });

  const handleReachBottom = useSyncCallback(() => {
    if (loading) {
      return;
    }

    if ((pagination.page + 1) * pageSize < pagination.totalNumber) {
      setLoading(true);
      setTimeout(() => {
        setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
        getEvents();
      }, 1000);
    }
  });

  const handleTab = (cur) => {
    setCurrent(cur);
  };

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.logo} alt="logo" className={styles.profile__logo} />
      <img src={pageData.profile.imgUrl} alt="" className={styles.profile__img} />
      <div className={styles.profile__content}>{pageData.profile.content}</div>
      <img src={pageData.profile.cooperatorMD} alt="" className={styles.profile__cooperator} />
    </div>
  );

  const renderStructure = () => (
    <div className={styles.structure}>
      {pageData.structure.value.map((item) => (
        <div key={item.name} className={styles.structure__item}>
          <img src={item.avatarUrl} alt="" className={styles.structure__item__avatar} />
          <div className={styles.structure__item__info}>
            <div className={styles.structure__item__info__emphasis}>
              <div className={styles.structure__item__rank}>{item.rank}</div>
              <div className={styles.structure__item__name}>{item.name}</div>
            </div>
            <div className={styles.structure__item__appellation}>
              {item.appellation.map((appellation, index) => (
                <div key={index}>{appellation}</div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderEvent = () => (
    <div className={styles.event}>
      {events.map((item, index) => (
        <div key={index} className={styles.event__item}>
          <img src={item.imgUrl} alt="" className={styles.event__item__img} />
          <div className={styles.event__item__title}>{item.title}</div>
          <div className={styles.event__item__content}>{item.content}</div>
        </div>
      ))}
      {loading && <Loading />}
    </div>
  );

  const renderModal = () => (
    <Modal {...modal} isMobile onConfirm={handleConfirm}>
      <div className={styles.apply}>
        {pageData.apply.map((item) => (
          <div
            key={item.type}
            onClick={() => handleChangeApplyType(item.value)}
            className={styles.apply__item}
          >
            <div className={styles.apply__img}>
              <img
                src={require(`@/assets/images/service/${item.icon}.png`).default}
                alt=""
                className={styles.apply__img__default}
              />
              <img
                src={require(`@/assets/images/service/${item.icon}-select.png`).default}
                alt=""
                className={styles.apply__img__active}
                style={{ opacity: item.value === applyType ? 1 : 0 }}
              />
            </div>
            <div>{item.type}</div>
          </div>
        ))}
      </div>
    </Modal>
  );

  return (
    <section className={styles.industry}>
      {renderProfile()}
      <div className={styles.industry__tab}>
        <Tab
          tabList={tabList}
          current={current}
          isSticky
          isScrollToTop
          onClick={handleTab}
          className={styles.industry__tab__ins}
          itemClassName={styles.industry__tab__item}
          activeClassName={styles.industry__tab__active}
        />
      </div>
      {current === 0 && renderStructure()}
      {current === 1 && renderEvent()}
      {renderModal()}
      <div
        className={cns(
          styles.industry__button,
          styles[`industry__button--${showBtn ? 'fixed' : 'absolute'}`],
        )}
      >
        <Button
          className={styles.industry__button__ins}
          onClick={() => setModal((prev) => ({ ...prev, isVisible: true }))}
        >
          申请加入联盟
        </Button>
      </div>
    </section>
  );
}

export default IndustryMD;
