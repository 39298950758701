export const tabList = [
  {
    label: '学会简介',
    value: 'introduction',
  },
  {
    label: '组织架构',
    value: 'organization',
  },
  {
    label: '分支机构',
    value: 'branch',
  },
  {
    label: '章程与制度',
    value: 'rules',
  },
  {
    label: '联系方式',
    value: 'contact',
  },
];
