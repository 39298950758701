export const formFramework = [
  {
    label: '讲师姓名',
    type: 'input',
    prop: 'name',
    step: 0,
  },
  {
    label: '研究领域',
    type: 'input',
    prop: 'area',
    step: 0,
  },
  {
    label: '讲师简介',
    type: 'textarea',
    prop: 'introduce',
    step: 0,
  },
  {
    label: '课程主题',
    type: 'input',
    prop: 'course',
    step: 1,
  },
  {
    label: '课程内容概括',
    type: 'textarea',
    prop: 'courseProfile',
    step: 1,
  },
  {
    label: '手机号',
    type: 'input',
    prop: 'phone',
    step: 2,
  },
  {
    label: '身份证号',
    type: 'input',
    prop: 'idNumber',
    step: 2,
  },
  {
    label: '开户行',
    type: 'select',
    prop: 'bank',
    step: 2,
  },
  {
    label: '银行卡号',
    type: 'input',
    prop: 'card',
    step: 2,
  },
];

export const initForm = {
  name: '',
  area: '',
  introduce: '',
  course: '',
  courseProfile: '',
  phone: '',
  idNumber: '',
  bank: '',
  card: '',
};
