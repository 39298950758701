import React from 'react';
import Tab from '@/components/Tab/pc';
import { useAbout } from '../hooks/useAbout';
import IntroductionPC from './components/Introduction';
import DetailOrganizationPC from './components/Organization';
import BranchPC from './components/Branch';
import RulesPC from './components/Rules';
import ContactPC from './components/contact';
import styles from './index.module.less';

function AboutPC() {
  const { tabList, currentTab, handleTab } = useAbout();

  return (
    <section className={styles.about}>
      <Tab
        tabList={tabList.map((item) => item.label)}
        current={currentTab}
        isScrollToTop
        onClick={handleTab}
        className={styles.about__tab}
      />
      {currentTab === 0 && <IntroductionPC />}
      {currentTab === 1 && <DetailOrganizationPC />}
      {currentTab === 2 && <BranchPC />}
      {currentTab === 3 && <RulesPC />}
      {currentTab === 4 && <ContactPC />}
    </section>
  );
}

export default AboutPC;
