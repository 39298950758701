import { CSSProperties, FC } from 'react';
import classNames from 'classnames';
import AIconProps from './icon';
import './index.less';

const AIcon: FC<AIconProps> = (props) => {
  const hex2rgb = (hex: string) => {
    const rgb: Array<number> = [];
    hex = hex.substring(1);
    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, '$1$1');
    }
    hex.replace(/../g, (color) => {
      rgb.push(parseInt(color, 0x10));
      return color;
    });
    return `rgba(${rgb.join(',')},1)`;
  };

  const fixColor = () => {
    const { color } = props;
    if (color) {
      if (typeof color === 'string') {
        return color.indexOf('#') === 0 ? hex2rgb(color) : color;
      } else {
        return color.map((item) => (item.indexOf('#') === 0 ? hex2rgb(item) : item));
      }
    }
  };

  const getStyle = () => {
    if (props.size) {
      let svgSize = `${props.size / 56}rem`;
      const color =
        typeof props.color === 'string' ? fixColor() : fixColor()?.[0] || 'rgba(51,51,51,1)';
      return {
        color,
        fontSize: svgSize,
      };
    }
  };

  /**
   * 点击事件
   * @param e
   */
  function handleClick(e) {
    props.onClick && props.onClick(e);
  }

  return (
    <div
      className={classNames('iconfont', `icon-${props.name}`, props.className)}
      style={getStyle() as CSSProperties}
      onClick={handleClick}
    />
  );
};

AIcon.defaultProps = {
  name: undefined,
  size: 60,
  color: 'rgba(51,51,51,1)',
};

export default AIcon;
