import React from 'react';
import pageData from '../../../common/memberService/data.json';
import styles from './index.module.less';

function MemberServiceMD() {
  const renderProfile = () => (
    <div className={styles.profile}>
      <div className={styles.title}>{pageData.profile.label}</div>
      <img src={pageData.profile.imgUrl} alt="" className={styles.profile__img} />
      <div className={styles.profile__content}>
        {pageData.profile.content.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    </div>
  );

  const renderBase = () => (
    <div className={styles.base}>
      <div className={styles.title}>{pageData.base.label}</div>
      <div className={styles.base__list}>
        {pageData.base.value.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    </div>
  );

  const renderPlus = () => (
    <div className={styles.plus}>
      <div className={styles.title}>{pageData.plus.label}</div>
      <table className={styles.plus__table}>
        <thead className={styles.plus__table__thead}>
          <tr>
            <td className={styles.plus__table__thead__td}>
              <span>服务类型</span>
              <span>服务内容</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {pageData.plus.value.map((item, index) => (
            <tr key={index} className={styles.plus__table__tbody__tr}>
              <td className={styles.plus__table__tbody__td}>
                <span>{item.type}</span>
                <span>{item.content}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <section className={styles.service}>
      {renderProfile()}
      {renderBase()}
      {renderPlus()}
    </section>
  );
}

export default MemberServiceMD;
