import React from 'react';
import pageData from '../../../common/thinkTank/data.json';
import styles from './index.module.less';

function ThinkTankPC() {
  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.logo} alt="logo" className={styles.profile__logo} />
      <div className={styles.content}>{pageData.profile.content}</div>
      <img src={pageData.profile.imgUrl} alt="" className={styles.profile__img} />
    </div>
  );

  const renderInitiator = () => (
    <div className={styles.initiator}>
      <div className={styles.title}>{pageData.initiator.label}</div>
      <div className={styles.initiator__list}>
        {pageData.initiator.value.map((item, index) => (
          <div key={index} className={styles.initiator__list__item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );

  const renderStructure = () => (
    <div className={styles.structure}>
      <div className={styles.title}>{pageData.structure.label}</div>
      <div className={styles.structure__list}>
        {pageData.structure.value.map((item) => (
          <div key={item.name} className={styles.structure__item}>
            <img src={item.avatarUrl} alt="" className={styles.structure__item__avatar} />
            <div className={styles.structure__item__rank}>{item.rank}</div>
            <div className={styles.structure__item__name}>{item.name}</div>
            <div className={styles.structure__item__appellation}>
              {item.appellation.map((appellation, index) => (
                <div key={index}>{appellation}</div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderActivity = () => (
    <div className={styles.activity}>
      <div className={styles.title}>{pageData.activity.label}</div>
      {pageData.activity.value.map((item, index) =>
        item.type === 'text' ? (
          <div key={index} className={styles.content}>
            {item.content}
          </div>
        ) : (
          <img key={index} src={item.imgUrl} alt="" className={styles.activity__img} />
        ),
      )}
    </div>
  );

  return (
    <section className={styles.tank}>
      <div className={styles.tank__container}>
        {renderProfile()}
        {renderInitiator()}
        {renderStructure()}
        {renderActivity()}
      </div>
    </section>
  );
}

export default ThinkTankPC;
