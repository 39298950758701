import React from 'react';
import type { PaginationProps } from 'antd';
import { Pagination, Spin } from 'antd';
import Tab from '@/components/Tab/pc';
import Affair from '@/components/Affair';
import NewsCardPC from '@/components/News/pc';
import Loading from '@/components/Loading';
import { useNewsList } from '../../hooks/useNewsList';
import styles from './index.module.less';

function NewsListPC() {
  const {
    tabList,
    pageSize,
    pagination,
    setPagination,
    newsList,
    affairList,
    loading,
    setLoading,
    currentTab,
    handleClick,
    handleTab,
    getData,
  } = useNewsList('pc');

  const handlePageChange: PaginationProps['onChange'] = (pageNumber: number) => {
    setPagination((prev) => ({
      ...prev,
      page: pageNumber - 1,
    }));
    setLoading(true);
    getData();
  };

  const renderAffairList = () => (
    <div className={styles.affair}>
      <div className={styles.affair__title}>信息公开</div>
      <div className={styles.affair__list}>
        {affairList.map((item) => (
          <Affair affair={item} onClick={handleClick} className={styles.affair__list__item} />
        ))}
      </div>
    </div>
  );

  const renderNewsList = () => (
    <div className={styles.list}>
      {newsList.map((news) => (
        <NewsCardPC
          key={news.id}
          newsData={news}
          onClick={handleClick}
          className={styles.list__item}
        />
      ))}
    </div>
  );

  const renderPagination = () => (
    <div className={styles.pagination}>
      <Pagination
        showQuickJumper
        current={pagination.page + 1}
        pageSize={pageSize}
        total={pagination.totalNumber}
        onChange={handlePageChange}
      />
    </div>
  );

  return (
    <section className={styles.news}>
      <Tab
        tabList={tabList.map((item) => item.label)}
        current={currentTab}
        isScrollToTop
        onClick={handleTab}
        className={styles.news__tab}
      />
      <div className={styles.news__container}>
        <Spin indicator={<Loading type="pc" />} spinning={loading}>
          {currentTab === 0 && renderNewsList()}
          {currentTab === 1 && renderAffairList()}
        </Spin>
        {renderPagination()}
      </div>
    </section>
  );
}

export default NewsListPC;
