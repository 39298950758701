import { createPortal } from 'react-dom';
import Swiper from '@/components/Swiper';
import PreviewGroupMB from '@/components/PreviewGroup/mobile';
import { useCity } from '../hooks/useCity';
import pageData from '../common/data.json';
import styles from './index.module.less';

function CityMD() {
  const { envPreviewConfig, activityPreviewConfig, handlePreview, handleHidePreview } = useCity();

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.bgUrlMD} alt="" className={styles.profile__bg} />
      <div className={styles.profile__info}>
        <div className={styles.profile__title}>{pageData.profile.label}</div>
        <div className={styles.profile__text}>{pageData.profile.content}</div>
      </div>
    </div>
  );

  const renderBrief = () => (
    <div className={styles.brief}>
      <div className={styles.brief__logo}>
        {pageData.brief.logo.map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" className={styles.brief__logo__item} />
        ))}
      </div>
      <div className={styles.brief__img}>
        <img src={pageData.brief.imgList[0]} alt="" className={styles.brief__img__large} />
        {pageData.brief.imgList.slice(1).map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" className={styles.brief__img__item} />
        ))}
      </div>
      <div className={styles.article}>{pageData.brief.content}</div>
    </div>
  );

  const renderList = (data) => (
    <div className={styles.list}>
      <div className={styles.subtitle}>{data.label}</div>
      <div className={styles.list__list}>
        {data.value.map((item) => (
          <div key={item.label} className={styles.list__item}>
            <div className={styles.list__item__label}>{item.label || item.time}</div>
            <div>
              {Array.isArray(item.result)
                ? item.result.map((policy) => <div>{policy}</div>)
                : item.result}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderPark = (data, key) => (
    <div className={styles.park}>
      <div className={styles.subtitle}>{data.label}</div>
      <Swiper
        spaceBetween={8}
        slidesPerView={1.1}
        slidesPerGroup={1}
        isLoop
        className={styles.swiper}
        insClassName={styles.swiper__ins}
      >
        {data.imgList.map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" onClick={() => handlePreview(key, index)} />
        ))}
      </Swiper>
      <div className={styles.article}>{data.content}</div>
    </div>
  );

  return (
    <section className={styles.city}>
      {renderProfile()}
      <div className={styles.city__content}>
        {renderBrief()}
        {renderList(pageData.process)}
        {renderPark(pageData.env, 'env')}
        {renderPark(pageData.activity, 'activity')}
        {renderList(pageData.policy)}
      </div>
      {createPortal(
        <PreviewGroupMB
          {...envPreviewConfig}
          prefix="env"
          isMobile
          imgList={pageData.env.imgList}
          onClick={() => handleHidePreview('env')}
        />,
        document.getElementById('root')!,
      )}
      {createPortal(
        <PreviewGroupMB
          {...activityPreviewConfig}
          prefix="act"
          isMobile
          imgList={pageData.activity.imgList}
          onClick={() => handleHidePreview('activity')}
        />,
        document.getElementById('root')!,
      )}
    </section>
  );
}

export default CityMD;
