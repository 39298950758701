import { useSafeState } from 'ahooks';
import cns from 'classnames';
import Tab from '@/components/Tab/mobile';
import pageData from '../../../common/thinkTank/data.json';
import styles from './index.module.less';

function ThinkTankMD() {
  const tabList = ['发起单位', '组织架构', '联合体活动'];
  const [current, setCurrent] = useSafeState(0);

  const handleTab = (cur) => {
    setCurrent(cur);
  };

  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.logo} alt="logo" className={styles.profile__logo} />
      <div className={styles.content}>{pageData.profile.content}</div>
      {pageData.profile.imgListMD.map((imgUrl, index) => (
        <img key={index} src={imgUrl} alt="" className={styles.profile__img} />
      ))}
    </div>
  );

  const renderInitiator = () => (
    <div className={styles.initiator}>
      <div className={styles.initiator__list}>
        {pageData.initiator.value.map((item, index) => (
          <div key={index} className={styles.initiator__list__item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );

  const renderStructure = () => (
    <div className={styles.structure}>
      {pageData.structure.value.map((item) => (
        <div key={item.name} className={styles.structure__item}>
          <img src={item.avatarUrl} alt="" className={styles.structure__item__avatar} />
          <div>
            <div className={styles.structure__item__rank}>{item.rank}</div>
            <div className={styles.structure__item__name}>{item.name}</div>
            <div className={styles.structure__item__appellation}>
              {item.appellation.map((appellation, index) => (
                <div key={index}>{appellation}</div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderActivity = () => (
    <div className={styles.activity}>
      {pageData.activity.value.map((item, index) =>
        item.type === 'text' ? (
          <div key={index} className={cns(styles.content, styles.activity__content)}>
            {item.content}
          </div>
        ) : (
          <div className={styles.activity__img}>
            {item.imgListMD?.map((imgUrl) => (
              <img src={imgUrl} alt="" className={styles.activity__img__ins} />
            ))}
          </div>
        ),
      )}
    </div>
  );

  return (
    <section className={styles.tank}>
      {renderProfile()}
      <div className={styles.tank__tab}>
        <Tab
          tabList={tabList}
          current={current}
          isSticky
          isScrollToTop
          onClick={handleTab}
          className={styles.tank__tab__ins}
          itemClassName={styles.tank__tab__item}
          activeClassName={styles.tank__tab__active}
        />
      </div>
      {current === 0 && renderInitiator()}
      {current === 1 && renderStructure()}
      {current === 2 && renderActivity()}
    </section>
  );
}

export default ThinkTankMD;
