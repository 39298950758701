import { useSafeState } from 'ahooks';
import Tab from '@/components/Tab/mobile';
import pageData from '../../../common/choice/data.json';
import styles from './index.module.less';

function ChoiceMD() {
  const [current, setCurrent] = useSafeState(0);

  const handleTab = (cur) => {
    if (current !== cur) {
      setCurrent(cur);
    }
  };

  return (
    <section className={styles.choice}>
      <div className={styles.choice__tabs}>
        <Tab
          tabList={pageData.tabs}
          current={current}
          onClick={handleTab}
          isSticky
          className={styles.choice__tabs__ins}
          itemClassName={styles.choice__tabs__item}
          activeClassName={styles.choice__tabs__active}
        />
      </div>
      <div className={styles.choice__info}>
        <img src={pageData.imgMapMD[current]} alt="" className={styles.choice__img} />
      </div>
    </section>
  );
}

export default ChoiceMD;
