import axios from 'axios';

const baseURL = process.env.REACT_APP_URL;

const http = axios.create({
  baseURL,
  timeout: 30000,
  withCredentials: true,
});

http.interceptors.response.use(
  (res) => {
    return res.data?.data;
  },
  (error) => {
    return Promise.reject(error.response);
  },
);

export default http;
