import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import cns from 'classnames';
import styles from './index.module.less';

type tabType = {
  tabList: Array<string>;
  current: number;
  hasInitMargin?: boolean;
  className?: string;
  itemClassName?: string;
  activeClassName?: string;
  isScrollToTop?: boolean;
  onClick: Function;
};

const TabPC = (props: tabType) => {
  const {
    tabList,
    current,
    className,
    itemClassName,
    activeClassName,
    hasInitMargin,
    isScrollToTop,
    onClick,
  } = props;
  const [tabIndex, setTabIndex] = useSafeState(current);

  useEffect(() => {
    setTabIndex(props.current);
  }, [props]);

  const handleClickTab = (index: number) => {
    if (tabIndex !== index) {
      setTabIndex(index);
      onClick && onClick(index);
      if (isScrollToTop) {
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <div className={cns(styles.tab, className)}>
      {tabList.map((item, index) => (
        <div
          key={item}
          className={cns(
            styles.tab__item,
            {
              [styles['tab__item--active']]: tabIndex === index,
              [styles['tab__item--margin']]: hasInitMargin,
            },
            itemClassName,
            activeClassName,
          )}
          onClick={() => handleClickTab(index)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

TabPC.defaultProps = {
  tabList: [],
  current: 0,
  hasInitMargin: true,
  isScrollToTop: false,
  onClick: undefined,
};

export default TabPC;
