import dayjs from 'dayjs';
import cns from 'classnames';
import styles from './index.module.less';
import { newsItemType } from '../common/news';

type cardType = {
  newsData: newsItemType;
  position: 'vertical' | 'horizontal';
  isSummit?: boolean;
  className?: string;
  onClick: Function;
};

const NewsCardMD = (props: cardType) => {
  const { newsData, position, isSummit, className, onClick } = props;

  const handleClick = () => {
    onClick && onClick(newsData.id);
  };

  return (
    <div className={cns(styles.card, styles[`card--${position}`], className)} onClick={handleClick}>
      {position === 'vertical' ? (
        <>
          <img src={newsData.imgUrl} alt="" className={styles.card__img} />
          <div className={styles.card__info}>
            <div className={styles.card__info__title}>{newsData.title}</div>
            <div className={styles.card__info__time}>
              {dayjs(newsData.time).format('YYYY.MM.DD')}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.card__info}>
            <div
              className={cns(styles.card__info__title, {
                [styles['card__info__title--summit']]: isSummit,
              })}
            >
              {newsData.title}
            </div>
            <div className={styles.card__info__time}>
              {dayjs(newsData.time).format('YYYY.MM.DD')}
            </div>
          </div>
          <img src={newsData.imgUrl} alt="" className={styles.card__img} />
        </>
      )}
    </div>
  );
};

NewsCardMD.defaultProps = {
  newsData: {},
  position: 'vertical',
  isSummit: false,
};

export default NewsCardMD;
