import React from 'react';
import { Steps, Form, Input, Select, Button } from 'antd';
import Modal from '@/components/Modal';
import { formFramework } from '../../common/application/form';
import { bankData } from '../../common/application/banks';
import { useApplicationForm } from '../../hooks/useApplicationForm';
import styles from './index.module.less';

function ApplicationFormMD() {
  const {
    step,
    form,
    modal,
    formRef,
    loading,
    getRules,
    handleSelectChange,
    handleNext,
    handlePrev,
    handleSubmit,
    handleSearchBank,
  } = useApplicationForm();

  const renderSteps = () => (
    <div className={styles.step}>
      <Steps current={step} responsive={false} direction="horizontal" labelPlacement="vertical">
        <Steps.Step title="讲师信息" />
        <Steps.Step title="课程信息" />
        <Steps.Step title="收款信息" />
      </Steps>
    </div>
  );

  const renderForm = () => (
    <div className={styles.form}>
      {formFramework
        .filter((item) => item.step === step)
        .map((item) => (
          <Form
            scrollToFirstError
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            autoComplete="off"
            validateTrigger={['onChange', 'onBlur']}
            form={formRef}
          >
            <Form.Item label={item.label} name={item.prop} rules={[getRules(item)]}>
              {item.type === 'input' ? (
                <Input
                  allowClear
                  value={form[item.prop]}
                  placeholder={`请输入${item.label}`}
                  size="large"
                />
              ) : item.type === 'textarea' ? (
                <Input.TextArea
                  allowClear
                  size="large"
                  value={form[item.prop]}
                  autoSize={{ minRows: 4 }}
                  placeholder={`请输入${item.label}`}
                />
              ) : (
                <Select
                  showSearch
                  allowClear
                  size="large"
                  fieldNames={{ label: 'bankName', value: 'bankName' }}
                  placeholder={`请选择${item.label}`}
                  filterOption={handleSearchBank}
                  options={bankData}
                  onChange={handleSelectChange}
                />
              )}
            </Form.Item>
          </Form>
        ))}
    </div>
  );

  const renderButtons = () => (
    <div className={styles.button__box}>
      {step === 0 && (
        <Button onClick={handleNext} className={styles.button__item}>
          下一步
        </Button>
      )}
      {step === 1 && (
        <>
          <Button onClick={handlePrev} className={styles.button__item}>
            上一步
          </Button>
          <Button onClick={handleNext} className={styles.button__item}>
            下一步
          </Button>
        </>
      )}
      {step === 2 && (
        <>
          <Button onClick={handlePrev} className={styles.button__item}>
            上一步
          </Button>
          <Button loading={loading} onClick={handleSubmit} className={styles.button__item}>
            提交
          </Button>
        </>
      )}
    </div>
  );

  return (
    <section className={styles.application}>
      {renderSteps()}
      {renderForm()}
      {renderButtons()}
      <Modal {...modal} isMobile />
    </section>
  );
}

export default ApplicationFormMD;
