import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import isMobile from 'is-mobile';
import { DEVICE_WIDTH } from '@/constants';
import HeaderMD from '@/components/Header/mobile';
import FooterMD from '@/components/Footer/mobile';
import menuData from '../common/menu.json';
import footerData from '../common/footerMD.json';
import { logoUrl } from '../common/logo';
import styles from './index.module.less';

function LayoutMD() {
  const navigate = useNavigate();

  useEffect(() => {
    const CLIENT_WIDTH = document.body.clientWidth;

    if (CLIENT_WIDTH > DEVICE_WIDTH && !isMobile()) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <section className={styles.layout}>
      <HeaderMD menuData={menuData} logoUrl={logoUrl} />
      <div className={styles.layout__content}>
        <Outlet />
      </div>
      <FooterMD footerData={footerData} />
    </section>
  );
}

export default LayoutMD;
