import { createRef, useEffect, useMemo } from 'react';
import { useSafeState } from 'ahooks';
import cns from 'classnames';
import { WinnerType } from './winner';
import styles from './index.module.less';
type tabType = {
  winner: WinnerType;
  type?: 'pc' | 'mobile';
  hasVideo?: boolean;
  className?: string;
  onClick?: Function;
};

const WinnerPC = (props: tabType) => {
  const { winner, type, hasVideo, className, onClick } = props;
  const winnerRef: any = useMemo(() => createRef(), []);
  const [showInfo, setShowInfo] = useSafeState(type === 'mobile');

  useEffect(() => {
    const winnerIns = winnerRef.current;
    if (type === 'pc') {
      winnerIns.addEventListener('mouseenter', handleMouseEnter);
      winnerIns.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (type === 'pc') {
        winnerIns.removeEventListener('mouseenter', handleMouseEnter);
        winnerIns.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [winnerRef, type]);

  const handleMouseEnter = () => {
    setShowInfo(true);
  };

  const handleMouseLeave = () => {
    setShowInfo(false);
  };

  const handleClick = () => {
    onClick && onClick(winner);
  };

  return (
    <div
      ref={winnerRef}
      className={cns(
        styles.winner,
        {
          [styles['winner--show']]: showInfo && type !== 'mobile',
        },
        { [styles['winner--mobile']]: type === 'mobile' },
        { [styles['winner--cursor']]: hasVideo },
        className,
      )}
      onClick={handleClick}
    >
      <img src={winner.imgUrl} alt={winner.name} className={styles.winner__img} />
      <div
        className={cns(styles.winner__info, {
          [styles['winner__info--show']]: showInfo,
        })}
      >
        {hasVideo && (
          <img
            src={require('@/assets/images/play.png').default}
            alt="start-icon"
            className={styles.winner__icon}
          />
        )}
        <div
          className={cns(styles.winner__name, {
            [styles['winner__name--show']]: showInfo,
          })}
        >
          {winner.name}
        </div>
        <div
          className={cns(styles.winner__company, {
            [styles['winner__company--show']]: showInfo,
          })}
        >
          {winner.company}
        </div>
      </div>
    </div>
  );
};

WinnerPC.defaultProps = {
  winner: {},
  type: 'pc',
  hasVideo: false,
  onClick: undefined,
};

export default WinnerPC;
