import cns from 'classnames';
import styles from './index.module.less';
import { JudgeType } from './judge';

type JudgePropsType = {
  judge: JudgeType;
  position?: 'horizontal' | 'vertical';
  type?: 'pc' | 'mobile';
  className?: string;
};

const Judge = (props: JudgePropsType) => {
  const { judge, position, type, className } = props;

  return (
    <div
      className={cns(
        styles[`judge${type === 'mobile' ? '--mobile' : '--pc'}`],
        styles[`judge--${position}`],
        className,
      )}
    >
      <img src={judge.avatarUrl} alt="" className={styles.judge__avatar} />
      <div className={styles.judge__name}>{judge.name}</div>
      <div className={styles.judge__rank}>
        {judge.rank.map((rank, index) => (
          <div key={index}>{rank}</div>
        ))}
      </div>
    </div>
  );
};

Judge.defaultProps = {
  judge: {},
  position: 'vertical',
  type: 'pc',
};

export default Judge;
