import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiBus from '@/serve/api';
import { newsItemType, newsDetailType } from '@/components/News/common/news';

export function useNewsDetail(type) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isNews, setIsNews] = useSafeState(true);
  const [newsList, setNewsList] = useSafeState<Array<newsItemType>>([]);
  const [newsDetail, setNewsDetail] = useSafeState<newsDetailType>({} as newsDetailType);

  useEffect(() => {
    const id = Number(searchParams.get('id'));
    const isAffair = searchParams.get('isNews') === 'false';
    setIsNews(!isAffair);

    if (!isAffair) {
      getNewsList(id);
      getNewsDetail(id);
    } else {
      getAffairDetail(id);
    }
  }, [searchParams]);

  const getNewsList = (id) => {
    apiBus
      .getNewsList({
        pageNo: Math.ceil(Math.random() * 10),
        pageSize: 4,
      })
      .then((res: any) => {
        setNewsList(res.filter((news) => news.id !== id).slice(0, 3));
      });
  };

  const getNewsDetail = (id) => {
    apiBus.getNewsDetail({ id }).then((res: any) => {
      setNewsDetail({
        id: res.id,
        time: res.datetime,
        title: res.title,
        content: res.content,
        attachments: res.attachments ? res.attachments.split(',') : [],
      });
    });
  };

  const getAffairDetail = (id) => {
    apiBus.getAffairDetail({ id }).then((res: any) => {
      setNewsDetail({
        id: res.id,
        time: res.datetime,
        title: res.title,
        content: res.content,
        attachments: res.attachments.split(','),
      });
    });
  };

  const handleNews = (id: number) => {
    setSearchParams(
      {
        id: `${id}`,
      },
      {
        replace: true,
      },
    );
    window.scrollTo(0, 0);
  };

  const handleMore = () => {
    navigate(`${type === 'mobile' ? '/phone' : ''}/news/#dynamics`, {
      replace: true,
    });
  };

  return {
    isNews,
    newsList,
    newsDetail,
    handleNews,
    handleMore,
  };
}
