import React from 'react';
import pageData from '../../../common/blockChain/data.json';
import styles from './index.module.less';

function BlockChainMD() {
  const renderProfile = () => (
    <div className={styles.profile}>
      <img src={pageData.profile.logoUrl} alt="logo" className={styles.profile__logo} />
      <div className={styles.text}>{pageData.profile.content}</div>
    </div>
  );

  const renderContent = () => (
    <div className={styles.content}>
      <img src={pageData.chain.photo} alt="" className={styles.content__photo} />
      <img src={pageData.chain.cooperator} alt="" className={styles.content__cooperator} />
      <div className={styles.text}>{pageData.chain.content[0]}</div>
      <div className={styles.content__images}>
        {pageData.chain.imgListMD.map((imgUrl, index) => (
          <img key={index} src={imgUrl} alt="" className={styles.content__images__item} />
        ))}
      </div>
      <div className={styles.text}>{pageData.chain.content[1]}</div>
    </div>
  );

  return (
    <section className={styles.chain}>
      <div className={styles.chain__container}>
        {renderProfile()}
        {renderContent()}
      </div>
    </section>
  );
}

export default BlockChainMD;
