import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { tabList } from '../common/about';

export function useAbout() {
  const navigate = useNavigate();
  const location = useLocation();
  const initTab = location.hash.substring(1);
  const initCurrent = tabList.findIndex((item) => item.value === initTab);
  const [currentTab, setCurrentTab] = useSafeState(initCurrent < 0 ? 0 : initCurrent);

  useEffect(() => {
    const initCurrent = tabList.findIndex((item) => item.value === initTab);
    setCurrentTab(initCurrent < 0 ? 0 : initCurrent);
  }, [initTab]);

  const handleTab = (current: number) => {
    setCurrentTab(current);
    navigate(`#${tabList[current].value}`);
  };

  return {
    tabList,
    currentTab,
    handleTab,
  };
}
