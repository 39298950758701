import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { createPortal } from 'react-dom';
import { Button } from 'antd';
import cns from 'classnames';
import Tab from '@/components/Tab/mobile';
import Winner from '@/components/Winner';
import PreviewGroupMB from '@/components/PreviewGroup/mobile';
import { throttle } from '@/libs';
import { getDeviceRatio } from '@/libs/utils/ratio';
import { getRemainTop } from '@/libs/utils/getScroll';
import pageData from '../../../common/summit/summit.json';
import winnerData from '../../../common/summit/summit-winner.json';
import backData from '../../../common/summit/summit-back.json';
import { useSummit } from '../../../hooks/useSummit';
import styles from './index.module.less';

const SummitMD = () => {
  const [showBtn, setShowBtn] = useSafeState(true);
  const {
    currentTab,
    previewConfig,
    handleTab,
    handleRegister,
    handleClickBack,
    handlePreview,
    handleHidePreview,
  } = useSummit('mobile');

  useEffect(() => {
    if (!pageData.registerUrl) {
      return;
    }

    const btnHideHeight = getDeviceRatio() * 10.6;
    const handleScroll = throttle(() => {
      if (getRemainTop() <= btnHideHeight) {
        setShowBtn(false);
      } else {
        setShowBtn(true);
      }
    }, 50);

    window.addEventListener('scroll', handleScroll, true);

    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  const renderBrief = () => (
    <div className={styles.brief}>
      <img src={pageData.brief.logo} alt="logo" className={styles.brief__logo} />
      <div className={styles.brief__text}>{pageData.brief.content}</div>
    </div>
  );

  const renderScene = () => (
    <div className={styles.scene}>
      <div className={styles.scene__small}>
        <div className={styles.scene__small__ins}>
          <img src={pageData.scene[0]} alt="" onClick={() => handlePreview(0)} />
        </div>
        <div className={styles.scene__small__ins}>
          <img src={pageData.scene[1]} alt="" onClick={() => handlePreview(1)} />
        </div>
      </div>
      <div className={styles.scene__large}>
        <img src={pageData.scene[2]} alt="" onClick={() => handlePreview(2)} />
      </div>
      <div className={styles.scene__small}>
        <div className={styles.scene__small__ins}>
          <img src={pageData.scene[3]} alt="" onClick={() => handlePreview(3)} />
        </div>
        <div className={styles.scene__small__ins}>
          <img src={pageData.scene[4]} alt="" onClick={() => handlePreview(4)} />
        </div>
      </div>
    </div>
  );

  const renderWinner = () => (
    <div className={styles.winner}>
      <div className={styles.subtitle}>历届年度数字新浙商名单</div>
      <div className={styles.winner__tab}>
        <Tab
          tabList={winnerData.map((item) => item.year)}
          current={currentTab}
          onClick={handleTab}
          className={styles.winner__tab__ins}
          itemClassName={styles.winner__tab__item}
          activeClassName={styles.winner__tab__active}
        />
      </div>
      <div
        className={`${styles.winner__list} ${
          styles[`winner__list--${winnerData[currentTab].year}`]
        }`}
      >
        {winnerData[currentTab].winner.map((winner) => (
          <Winner
            key={winner.name}
            winner={winner}
            type="mobile"
            className={styles.winner__list__item}
          />
        ))}
      </div>
    </div>
  );

  const renderProcess = () =>
    pageData.process.map((item) => (
      <div key={item.label} className={styles.process}>
        {item.label === '活动流程' && (
          <img
            src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/mobile/summit-bg-3.png"
            alt=""
            className={styles.process__bg}
          />
        )}
        <div className={styles.subtitle}>{item.label}</div>
        {item.type === 'text' ? (
          <div className={styles.process__text}>{item.content}</div>
        ) : (
          <img src={item.imgUrlMD} className={styles.process__img} alt="" />
        )}
      </div>
    ));

  const renderBack = () => (
    <div className={styles.back}>
      <div className={styles.subtitle}>往届回顾</div>
      {backData.map((item) => (
        <div
          key={item.year}
          className={styles.back__item}
          onClick={() => handleClickBack(item.year)}
        >
          <img src={item.imgUrlMD} className={styles.back__item__img} alt="" />
        </div>
      ))}
    </div>
  );

  const renderCooperator = () => (
    <div className={styles.cooperator}>
      <div className={styles.subtitle}>{pageData.cooperator.label}</div>
      <div className={styles.cooperator__img}>
        <img src={pageData.cooperator.imgUrlMD} alt="" />
      </div>
    </div>
  );

  const renderButton = () => (
    <div className={cns(styles.btn, styles[`btn--${showBtn ? 'fixed' : 'absolute'}`])}>
      <Button
        type="primary"
        size="small"
        className={styles.btn__ins}
        onClick={() => handleRegister(pageData.registerUrl)}
      >
        官方报名通道
      </Button>
    </div>
  );

  return (
    <div className={styles.summit}>
      <div className={styles.summit__bg} />
      <div className={styles.summit__content}>
        {renderBrief()}
        {renderScene()}
        {renderWinner()}
        {renderProcess()}
        {renderBack()}
        {renderCooperator()}
        {pageData.registerUrl && renderButton()}
      </div>
      {createPortal(
        <PreviewGroupMB
          {...previewConfig}
          prefix="smd"
          isMobile
          imgList={pageData.scene}
          onClick={handleHidePreview}
        />,
        document.getElementById('root')!,
      )}
    </div>
  );
};

export default SummitMD;
