import styles from './index.module.less';
import { InterviewType } from '../../common/interview';

type cardType = {
  interviewData: InterviewType;
  onClick: Function;
};

const InterviewCardPC = (props: cardType) => {
  const { interviewData, onClick } = props;

  const handleClick = () => {
    onClick && onClick(interviewData.id);
  };

  return (
    <div className={styles.card} onClick={handleClick}>
      <img src={interviewData.imgUrl} alt="" className={styles.card__img} />
      <div className={styles.card__number}>{interviewData.number}</div>
      <div className={styles.card__content}>{interviewData.title}</div>
    </div>
  );
};

export default InterviewCardPC;
