import { useEffect, useState, useCallback } from 'react';

export function useSyncCallback(callback: Function): Function {
  const [proxyState, setProxyState] = useState({ current: false });

  const fn = useCallback(() => {
    setProxyState({ current: true });
  }, []);

  useEffect(() => {
    if (proxyState.current === true) setProxyState({ current: false });
  }, [proxyState]);

  useEffect(() => {
    proxyState.current && callback();
  });

  return fn;
}
