import { useSafeState } from 'ahooks';

interface PreviewConfigType {
  isVisible: boolean;
  current: number;
}

export function useCity() {
  const [envPreviewConfig, setEnvPreviewConfig] = useSafeState<PreviewConfigType>({
    isVisible: false,
    current: 0,
  });

  const [activityPreviewConfig, setActivityPreviewConfig] = useSafeState<PreviewConfigType>({
    isVisible: false,
    current: 0,
  });

  const handlePreview = (type: 'env' | 'activity', current) => {
    if (type === 'env') {
      setEnvPreviewConfig((prev) => ({
        ...prev,
        isVisible: true,
        current,
      }));
    } else {
      setActivityPreviewConfig((prev) => ({
        ...prev,
        isVisible: true,
        current,
      }));
    }
  };

  const handleHidePreview = (type: 'env' | 'activity') => {
    if (type === 'env') {
      setEnvPreviewConfig((prev) => ({
        ...prev,
        isVisible: false,
      }));
    } else {
      setActivityPreviewConfig((prev) => ({
        ...prev,
        isVisible: false,
      }));
    }
  };

  return {
    envPreviewConfig,
    activityPreviewConfig,
    handlePreview,
    handleHidePreview,
  };
}
