import { ReactElement } from 'react';
import cns from 'classnames';
import styles from './index.module.less';
import { Button } from 'antd';

export type ModalPropsType = {
  isVisible: boolean;
  title?: string;
  content?: string;
  isMobile?: boolean;
  type?: 'success' | 'error' | 'warning';
  confirm?: string;
  cancel?: string;
  children?: ReactElement | string;
  hasCancelIcon?: boolean;
  className?: string;
  onConfirm?: Function;
  onCancel?: Function;
};

const Modal = (props: ModalPropsType) => {
  const {
    isVisible,
    title,
    content,
    children,
    isMobile,
    type,
    confirm,
    cancel,
    hasCancelIcon,
    className,
    onCancel,
    onConfirm,
  } = props;

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
  };

  return (
    <div className={styles.modal} style={{ display: isVisible ? 'flex' : 'none' }}>
      <div
        className={cns(
          styles.modal__container,
          className,
          styles[`modal__container--${isMobile ? 'md' : 'pc'}`],
        )}
      >
        {type && (
          <img
            src={require(`@/assets/images/modal/${type}.png`).default}
            className={styles.modal__icon}
            alt=""
          />
        )}
        {title && <div className={styles.modal__title}>{title}</div>}
        {content && <div className={styles.modal__content}>{content}</div>}
        {children && <div className={styles.modal__children}>{children}</div>}
        <div className={styles.modal__button}>
          {cancel && (
            <Button onClick={handleCancel} className={styles.modal__button__item}>
              {cancel}
            </Button>
          )}
          {confirm && (
            <Button onClick={handleConfirm} className={styles.modal__button__item}>
              {confirm}
            </Button>
          )}
        </div>
        {hasCancelIcon && (
          <img
            src={require('@/assets/images/close.png').default}
            className={styles['modal__cancel-icon']}
            alt=""
            onClick={handleCancel}
          />
        )}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  title: '',
  content: '',
  isMobile: false,
  type: '',
  confirm: '',
  cancel: '',
  children: undefined,
  hasCancelIcon: false,
  onConfirm: undefined,
  onCancel: undefined,
};

export default Modal;
