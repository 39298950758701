import { useSafeState } from 'ahooks';
import Tab from '@/components/Tab/mobile';
import Committee from '@/components/Committee/mobile';
import pageData from '../../../common/branch/data.json';
import styles from './index.module.less';

function BranchMD() {
  const isMultiSelect = false;
  const [current, setCurrent] = useSafeState(0);
  const [expandList, setExpandList] = useSafeState<Array<number>>([]);

  const handleTab = (cur) => {
    if (cur !== current) {
      setCurrent(cur);
      setExpandList([]);
    }
  };

  const handleCommittee = (id) => {
    if (isMultiSelect) {
      setExpandList((prev) => {
        if (prev.includes(id)) {
          return prev.filter((value) => value !== id);
        } else {
          return [...prev, id];
        }
      });
    } else {
      setExpandList((prev) => {
        if (prev.includes(id)) {
          return [];
        } else {
          return [id];
        }
      });
    }
  };

  return (
    <section className={styles.branch}>
      <div className={styles.branch__tab}>
        <Tab
          tabList={pageData.map((item) => item.label)}
          current={current}
          isSticky
          isScrollToTop
          onClick={handleTab}
          className={styles.branch__tab__ins}
          itemClassName={styles.branch__tab__item}
          activeClassName={styles.branch__tab__active}
        />
      </div>
      <div className={styles.branch__list}>
        {pageData[current].value.map((committee) => (
          <Committee
            key={committee.id}
            committeeData={committee}
            isCollapse={!expandList.includes(committee.id)}
            onClick={handleCommittee}
            className={styles.branch__list__item}
          />
        ))}
      </div>
    </section>
  );
}

export default BranchMD;
