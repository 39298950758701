import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import apiBus from '@/serve/api';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import Icon from '@/components/Icon';
import InterviewCard from '@/components/Interview/mobile/card';
import styles from './index.module.less';
import { InterviewType } from '../../common/interview/interview';

function InterviewMD() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [interview, setInterview] = useSafeState<InterviewType>({} as InterviewType);
  const [interviewList, setInterviewList] = useSafeState<Array<InterviewType>>([]);

  useEffect(() => {
    getData();
  }, [searchParams]);

  const getData = useSyncCallback(() => {
    const id = Number(searchParams.get('id'));
    getInterView(id);
    getInterviews();
  });

  const getInterView = (id) => {
    apiBus
      .getInterviewDetail({
        id: id,
      })
      .then((res: any) => {
        setInterview(res);
      });
  };

  const getInterviews = () => {
    apiBus
      .getInterviewList({
        page: 1,
        limit: 3,
      })
      .then((res: any) => {
        setInterviewList(res);
      });
  };

  const handleClick = (id) => {
    setSearchParams(
      {
        id: `${id}`,
      },
      {
        replace: true,
      },
    );
    window.scrollTo(0, 0);
  };

  const handleMore = () => {
    navigate('/phone/project/digit/list', { replace: true });
  };

  const renderArticle = () => (
    <div className={styles.article}>
      <div className={styles.article__title}>{interview.title}</div>
      {interview.numero && (
        <div className={styles.article__number}>解码数字新浙商No.{interview.numero}</div>
      )}
      <div
        className={styles.article__detail}
        dangerouslySetInnerHTML={{ __html: interview.content }}
      />
    </div>
  );

  const renderList = () => (
    <div className={styles.previous}>
      <div className={styles.previous__title}>往期访谈</div>
      <div className={styles.previous__list}>
        {interviewList.map((data) => (
          <div key={data.id} className={styles.previous__list__item}>
            <InterviewCard interviewData={data} onClick={handleClick} />
          </div>
        ))}
      </div>
      <div className={styles.previous__more} onClick={handleMore}>
        查看更多 <Icon name="arrow-right" size={28} color="rgba(151, 151, 151, 1)" />
      </div>
    </div>
  );

  return (
    <section className={styles.interview}>
      {renderArticle()}
      {renderList()}
    </section>
  );
}

export default InterviewMD;
