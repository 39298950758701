import React from 'react';
import Swiper from '@/components/Swiper';
import CommitteePC from '@/components/Committee/pc';
import pageData from '../../../common/branch/data.json';
import styles from './index.module.less';

function BranchPC() {
  const renderCommittee = (item) => (
    <div key={item.label} className={styles.committee}>
      <div className={styles.title}>{item.label}</div>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        slidesPerGroup={1}
        arrowColor="grey"
        isLoop
        hasNavigation
        hasArrowShadow
        hasPagination
        className={styles.committee__swiper}
      >
        {item.value.map((committee) => (
          <div key={committee.id} className={styles.committee__item}>
            <CommitteePC
              key={committee.name}
              committeeData={committee}
              className={styles.committee__item__ins}
            />
          </div>
        ))}
      </Swiper>
    </div>
  );

  return (
    <section className={styles.branch}>
      <div className={styles.branch__container}>
        {pageData.map((item) => renderCommittee(item))}
      </div>
    </section>
  );
}

export default BranchPC;
