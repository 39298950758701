import { useSafeState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import apiBus from '@/serve/api';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import { newsItemType } from '@/components/News/common/news';
import { InterviewType } from '@/components/Interview/common/interview';

export function useHome(type) {
  const navigate = useNavigate();
  const [banners, setBanners] = useSafeState<
    Array<{ id: number; imgUrl: string; linkUrl: string }>
  >([]);
  const interviewsCache = useSelector((state: any) => state.interview.list);
  const [interviews, setInterviews] = useSafeState<Array<InterviewType>>(
    interviewsCache.slice(0, 7),
  );
  const [news, setNews] = useSafeState<Array<newsItemType>>([]);
  const [currentProject, setCurrentProject] = useSafeState(0);
  const [swiperRef, setSwiperRef] = useSafeState<any>(null);

  const getBanners = () => {
    const bannerPro = type === 'mobile' ? apiBus.getBannerMD({}) : apiBus.getBanner({});
    bannerPro.then((res: any) => {
      setBanners(
        res.map((item) => {
          if (type === 'mobile' && item.link && !item.link.includes('/phone')) {
            const paths = item.link.split('/#/');
            item.link = `${paths[0]}/#/phone/${paths[1]}`;
          }

          return {
            id: item.id,
            imgUrl: item.image,
            linkUrl: item.link,
          };
        }),
      );
    });
  };

  const getInterviews = () => {
    if (!interviewsCache?.length) {
      apiBus
        .getInterviewList({
          page: 1,
          limit: 7,
        })
        .then((res: any) => {
          setInterviews(res);
        });
    }
  };

  const getNews = () => {
    apiBus.getFirstThreeNews().then((res: any) => {
      setNews(res);
    });
  };

  const handleBanner = (linkUrl: string) => {
    window.open(linkUrl, '_blank');
  };

  const handleSwiperChange = useSyncCallback(() => {
    if (currentProject !== swiperRef.realIndex) {
      setCurrentProject(swiperRef.realIndex);
    }
  });

  const handleProjectDetail = (project: any) => {
    navigate(`${type === 'mobile' ? '/phone' : ''}${project.target}`);
  };

  const handleInterview = (id: number) => {
    if (type === 'mobile') {
      navigate(`/phone/project/digit/interview?id=${id}`);
    } else if (type === 'pc') {
      navigate(`/project/digit?id=${id}`);
    }
  };

  const handleNews = (id: number) => {
    navigate(`${type === 'mobile' ? '/phone' : ''}/news/detail?id=${id}&isNews=true`);
  };

  const handleMore = (isNews: boolean) => {
    if (isNews) {
      navigate(`${type === 'mobile' ? '/phone' : ''}/news/#dynamics`);
    } else {
      navigate(
        `${type === 'mobile' ? '/phone' : ''}/project/digit${type === 'mobile' ? '/list' : ''}`,
      );
    }
  };

  return {
    banners,
    interviews,
    news,
    swiperRef,
    setSwiperRef,
    currentProject,
    setCurrentProject,
    getBanners,
    getInterviews,
    getNews,
    handleBanner,
    handleSwiperChange,
    handleProjectDetail,
    handleInterview,
    handleNews,
    handleMore,
  };
}
