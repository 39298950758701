import { useEffect, createRef, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSafeState } from 'ahooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import cns from 'classnames';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { CloseCircleOutlined } from '@ant-design/icons';
import Winner from '@/components/Winner';
import Judge from '@/components/Judge';
import Swiper from '@/components/Swiper';
import Icon from '@/components/Icon';
import News from '@/components/News/mobile';
import PreviewGroupMB from '@/components/PreviewGroup/mobile';
import { newsItemType } from '@/components/News/common/news';
import apiBus from '@/serve/api';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import tagData from '../../common/summitDetail/summit-tag.json';
import pageData from '../../common/summitDetail/summit-detail.json';
import winnerData from '../../common/summitDetail/summit-winner.json';
import judgesData from '../../common/summitDetail/summit-judges.json';
import specimensData from '../../common/summitDetail/summit-specimens.json';
import structureData from '../../common/summitDetail/summit-structure.json';
import { useSummitDetail } from '../../hooks/useSummitDetail';
import styles from './index.module.less';

function SummitDetailPC() {
  const pageSize = 6;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentYear = Number(searchParams.get('year')) || new Date().getFullYear();
  const [pagination, setPagination] = useSafeState({
    page: 0,
    totalNumber: 0,
  });
  const [newsData, setNewsData] = useSafeState<Array<newsItemType>>([]);
  const [video, setVideo] = useSafeState(false);
  const videoRef: any = createRef();
  const player: any = useRef();

  const { previewConfig, handlePreview, handleHidePreview } = useSummitDetail();

  useEffect(() => {
    player.current = videojs(videoRef.current, {
      controls: true,
      autoplay: true,
      preload: 'auto',
      aspectRatio: '16:9',
      language: 'zh',
    });

    return () => player.current.dispose();
  }, []);

  useEffect(() => {
    setNewsData([]);
    getNews();
  }, []);

  const getNews = useSyncCallback(() => {
    apiBus
      .getNewsList({
        pageNo: pagination.page + 1,
        pageSize,
        tag: `fh${currentYear}`,
      })
      .then((res: any) => {
        setPagination((prev) => ({
          ...prev,
          totalNumber: res.total,
        }));

        setNewsData((prev) => [...prev, ...res]);
      });
  });

  const handleMore = () => {
    if ((pagination.page + 1) * pageSize < pagination.totalNumber) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      getNews();
    }
  };

  const handleNews = (id) => {
    navigate(`/phone/news/detail?id=${id}&isNews=true`);
  };

  const handlePlayVideo = () => {
    setVideo(true);
    player.current.src({
      src: pageData[currentYear].pv.videoUrl,
    });
    player.current.play();
  };

  const handleHideVideo = () => {
    player.current.pause();
    // player.current.src({
    //   src: '/',
    // });
    setVideo(false);
  };

  const handlePlayWinner = (winner) => {
    setVideo(true);
    player.current.src({
      src: winner.videoUrl,
    });
    player.current.play();
  };

  const renderPV = () => (
    <>
      <div className={styles.pv}>
        <img src={pageData[currentYear].pv.imgUrlMD} alt="" className={styles.pv__img} />
        <img
          src={require('@/assets/images/play.png').default}
          alt="start-icon"
          className={styles.pv__icon}
          onClick={handlePlayVideo}
        />
      </div>
      {pageData[currentYear].pv.detail && (
        <>
          <div className={styles.pv__detail}>{pageData[currentYear].pv.detail}</div>
        </>
      )}
    </>
  );

  const renderTag = () => (
    <div className={styles.tag}>
      {tagData[currentYear].map((item) => (
        <div key={item.label} className={styles.tag__card}>
          <div>
            <div className={styles.tag__label}>{item.label}</div>
            <div>
              {item.tips.map((item) => (
                <div>{item}</div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderCup = () => (
    <div className={styles.cup}>
      <div className={styles.subtitle}>{pageData[currentYear].cup.label}</div>
      <div className={styles.cup__bg}>
        <img src={pageData[currentYear].cup.bgMD} alt="" className={styles.cup__bg__img} />
      </div>
      <div className={styles.cup__info}>{pageData[currentYear].cup.content}</div>
    </div>
  );

  const renderTheme = () => (
    <div className={styles.theme}>
      <img
        src="https://college-official.oss-cn-hangzhou.aliyuncs.com/materials/digit/summit/2022/theme-MD.png"
        alt=""
        className={styles.theme__img}
      />
      <div className={styles.theme__content}>{pageData[currentYear].theme}</div>
    </div>
  );

  const renderMoment = () => (
    <div className={styles.moment}>
      <div className={styles.subtitle}>{pageData[currentYear].moment.label}</div>
      <div className={styles.moment__info}>{pageData[currentYear].moment.content}</div>
    </div>
  );

  const renderWinner = () => (
    <div className={styles.winner}>
      {winnerData[currentYear].map((item) => (
        <div key={item.title} className={styles.winner__item}>
          <div className={styles.winner__item__img}>
            {item.winner.map((winner) => (
              <div key={winner.name} className={styles.winner__item__img__ins}>
                <Winner winner={winner} type="mobile" hasVideo onClick={handlePlayWinner} />
              </div>
            ))}
          </div>
          <div className={styles.winner__item__words}>
            <div className={styles.subtitle}>{item.title}</div>
            {item.words.map((paragraph, index) => (
              <div key={index} className={styles.winner__item__words__para}>
                {paragraph.map((word) => (
                  <div>{word}</div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  const renderJudges = () => (
    <div className={styles.judges}>
      <div className={styles.judges__container}>
        <div className={styles.subtitle}>评委会成员</div>
        <div className={styles.judges__subtitle}>评委会主席</div>
        <Judge judge={judgesData[currentYear].president} type="mobile" position="horizontal" />
        <div className={styles.judges__subtitle}>评委会成员</div>
        <div className={styles.judges__list}>
          {judgesData[currentYear].member.map((member) => (
            <div key={member.name} className={styles.judges__list__item}>
              <Judge judge={member} type="mobile" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderSpecimens = () => (
    <div className={styles.specimens}>
      <div className={styles.subtitle}>图片集锦</div>
      <Swiper
        spaceBetween={6}
        slidesPerView={1.1}
        slidesPerGroup={1}
        isLoop
        className={styles.specimens__swiper}
        insClassName={styles.specimens__swiper__ins}
      >
        {specimensData[currentYear].map((imgUrl, index) => (
          <img
            key={index}
            src={imgUrl}
            alt=""
            className={styles.specimens__img}
            onClick={() => handlePreview(index)}
          />
        ))}
      </Swiper>
    </div>
  );

  const renderNews = () => (
    <div className={styles.news}>
      <div className={styles.subtitle}>相关新闻</div>
      <div className={styles.news__list}>
        {newsData.map((item) => (
          <News
            key={item.id}
            newsData={item}
            position="horizontal"
            isSummit
            onClick={handleNews}
            className={styles.news__list__item}
          />
        ))}
      </div>
      {(pagination.page + 1) * pageSize < pagination.totalNumber && (
        <div className={styles.news__more} onClick={handleMore}>
          加载更多
          <Icon name="arrow-down" size={28} color="rgba(138, 138, 138, 1)" />
        </div>
      )}
    </div>
  );

  const renderStructure = () => (
    <div className={styles.structure}>
      <div className={styles.subtitle}>组织架构</div>
      <div className={styles.structure__content}>
        {structureData[currentYear].map((item, index) => (
          <>
            <div className={styles.structure__item}>
              <div>{item.label}</div>
              <div className={styles.structure__item__list}>
                {item.value.map((subItem) => (
                  <div key={subItem} className={styles.structure__item__organ}>
                    {subItem}
                  </div>
                ))}
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );

  const renderCooperator = () => (
    <div className={styles.cooperator}>
      <div className={styles.subtitle}>{pageData[currentYear].cooperator.label}</div>
      <div className={styles.cooperator__img}>
        <img src={pageData[currentYear].cooperator.imgUrlMD} alt="" />
      </div>
    </div>
  );

  const renderVideo = () => (
    <div className={styles.video} style={video ? {} : { zIndex: -9999, opacity: 0 }}>
      <div className={styles.video__container}>
        <video ref={videoRef} className={cns(styles.video__ins, 'video-js')} />
        <CloseCircleOutlined className={styles.video__cancel} onClick={handleHideVideo} />
      </div>
    </div>
  );

  return (
    <section className={`${styles.summit} ${styles[`year-${currentYear}`]}`}>
      {renderPV()}
      {currentYear === 2021 && renderTag()}
      {currentYear === 2021 && renderCup()}
      {currentYear === 2022 && renderTheme()}
      {renderMoment()}
      {renderWinner()}
      {(currentYear === 2022 || currentYear === 2021) && renderJudges()}
      {renderSpecimens()}
      {newsData.length > 0 && renderNews()}
      {renderStructure()}
      {(currentYear === 2022 || currentYear === 2021) && renderCooperator()}
      {renderVideo()}
      {createPortal(
        <PreviewGroupMB
          {...previewConfig}
          prefix="sdpc"
          isMobile
          imgList={specimensData[currentYear]}
          onClick={handleHidePreview}
        />,
        document.getElementById('root')!,
      )}
    </section>
  );
}

export default SummitDetailPC;
