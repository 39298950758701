import React from 'react';
import cns from 'classnames';
import Tab from '@/components/Tab/mobile';
import { bannerData, TabList } from '../common/variable';
import { useDigit } from '../hooks/useDigit';
import Summit from './components/Summit';
import Trip from './components/Trip';
import Merchant from './components/Merchant';
import styles from './index.module.less';

function DigitMD() {
  const { currentTab, handleTab } = useDigit();

  const renderBanner = () => (
    <div className={styles.banner}>
      <img src={bannerData.imgUrlMD} alt="" className={styles.banner__bg} />
      <div className={styles.banner__container}>
        <img src={bannerData.logoUrl} alt="" className={styles.banner__logo} />
        <div className={styles.banner__content}>{bannerData.content}</div>
      </div>
    </div>
  );

  return (
    <section className={styles.digit}>
      {renderBanner()}
      <Tab
        tabList={TabList}
        current={currentTab}
        isSticky
        isScrollToTop
        onClick={handleTab}
        className={cns(styles.digit__tab, {
          [styles['digit__tab--transparent']]: currentTab === 1,
        })}
        itemClassName={styles.digit__tab__item}
        activeClassName={
          currentTab === 1
            ? styles['digit__tab--transparent--active']
            : styles['digit__tab__item--active']
        }
      />
      <div className={styles.digit__bg}>
        {currentTab === 0 && <Merchant />}
        {currentTab === 1 && <Summit />}
        {currentTab === 2 && <Trip />}
      </div>
    </section>
  );
}

export default DigitMD;
