import { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { useNavigate, useLocation } from 'react-router-dom';
import apiBus from '@/serve/api';
import { useSyncCallback } from '@/libs/utils/useSyncCallback';
import { AffairType } from '@/components/Affair/Affair';
import { newsItemType } from '@/components/News/common/news';
import { tabList } from '../common/tab';

export function useNewsList(type) {
  const navigate = useNavigate();
  const location = useLocation();
  const pageSize = 6;
  const [pagination, setPagination] = useSafeState({
    page: 0,
    totalNumber: 0,
  });
  const [newsList, setNewsList] = useSafeState<Array<newsItemType>>([]);
  const [affairList, setAffairList] = useSafeState<Array<AffairType>>([]);
  const [loading, setLoading] = useSafeState(false);
  const initTab = location.hash.substring(1);
  const [currentTab, setCurrentTab] = useSafeState(0);

  useEffect(() => {
    const initCurrent = tabList.findIndex((item) => item.value === initTab);
    setCurrentTab(initCurrent < 0 ? 0 : initCurrent);
    setPagination({
      page: 0,
      totalNumber: 0,
    });
    setLoading(true);
    getData();
  }, [initTab]);

  const getData = useSyncCallback(() => {
    let getDataApi: any = null;
    let setDataFunc: any = null;
    if (currentTab === 0) {
      setAffairList([]);
      getDataApi = apiBus.getNewsList;
      setDataFunc = setNewsList;
    } else {
      setNewsList([]);
      getDataApi = apiBus.getAffairList;
      setDataFunc = setAffairList;
    }

    getDataApi({
      pageNo: pagination.page + 1,
      pageSize,
    })
      .then((res: any) => {
        setPagination((prev) => ({
          ...prev,
          totalNumber: res.total,
        }));
        if (type === 'mobile') {
          setDataFunc((prev) => [...prev, ...res]);
        } else if (type === 'pc') {
          setDataFunc(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  });

  const handleClick = (id: number) => {
    navigate(`detail?id=${id}&isNews=${currentTab === 0}`);
  };

  const handleTab = (current: number) => {
    setCurrentTab(current);
    navigate(`#${tabList[current].value}`);
  };

  return {
    tabList,
    pageSize,
    pagination,
    setPagination,
    newsList,
    setNewsList,
    affairList,
    setAffairList,
    loading,
    setLoading,
    currentTab,
    setCurrentTab,
    handleClick,
    handleTab,
    getData,
  };
}
